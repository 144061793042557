import {calcCssClass, calcCssClasses, colToCss,cssInts,calcNumClasses,isObj} from '../../../modules';

export function calcSizeAndColors(box={}) {
  // Vars: b,e,f,hex,hsl,hsl1,sign,sign1,sm, sm1,sm2
  const size = box.size;
  const color = box.color;
  const lHov = color?.lHov ?? -10; // luminosity change of the checkbox bkg
  const lHovChecked = color?.lHovCheched ?? lHov; // luminosity change of the checkbox bkg in checked state
  const bkg = color?.bkg ?? "dodger blue";
  const bkgChecked = color?.bkgChecked ?? bkg;
  let out = " a-1 ";
  out += calcCssClasses((size?.box ?? {l:25,m:20,s:16}), "f");
  out += calcCssClasses(size?.tick ?? 2,"sm3");  // size of the tick should be 2-3
  out += colToCss("hex", color?.tick ?? "white");
  out += colToCss("hsl", bkg);
  out += colToCss("hsl1", bkgChecked);
  out += (lHov >= 0 ? " plus-sign " : " minus-sign ");
  out += calcCssClasses(Math.abs(lHov),"sm");
  out += (lHovChecked >= 0 ? " plus1-sign " : " minus1-sign ");
  out += calcCssClasses(Math.abs(lHovChecked),"sm1");
  out += calcCssClasses(size?.brd ?? 0,"sm2"); // checkbox border in checked state, usually to be used when bkg doesn't change when checked, so the border and the checked tick highlith the checked state
  return out;
}
