import React, {useState, useEffect,useRef,useContext,useMemo} from 'react';
import {isString,isObj,mediaVal,Tooltip,Shared,addPx,CalcNew,Style} from 'modules';
import "./formContainer.css"; import {Label, CoveringLabel, Required,OuterLabel} from './labels'; import Inputs from './Inputs'; import {calcForm} from './calcForm';


/*  <div className=""></div>  */


function FormContainer({style={},cl="",s,colors,error, setError, focus, setFocus,...props}) {

      const {media} = useContext(Shared);
      const [clicked, setClick]= useState(false);
      const {label,id} = useMemo(()=>init(props,media),[props.label,props.id,media]);
      const [labelComputedWidth, setLabelComputedWidth] = useState({}); /* ***Input Padding****    -> only when labelPosition is "left", we need the computer the label width and add it tot he left padding of the input because the label is absolutely positioned so we need to make space for it */
      const labelRef = useRef(null);
      const labelProps = {...label,id,clicked,setClick};
      const behaviour = useMemo(()=>calcBehavior(focus, error,label),[focus, error,label]);
      const [sClass,styleSheet] = useMemo(()=> s ? CalcNew.all(s) : ["",""],[s]);
      const [formClass,formSheet] = useMemo(()=>!colors ? ["",""] : calcForm(colors),[colors]);
      // Better to create also a label width prop in order to consider the case when the left  label width should be fixed to have sevral forms with the same sized of label and input

      useEffect(()=>{
        /* ***Input Padding****    -> only when labelPosition is "left" */
        if (label.position == "left" && !label.width) {  // if labelPosition is "left" we have to calculate the label width and use it as padding left for the input. We have to wait the element to render to compute it, put the value in state and than update the input.
            setLabelComputedWidth(labelRef.current ? {"--labelWd":labelRef.current.getBoundingClientRect().width} : {});
        }
      },[focus,label.width]);




    return (
            <div className={"form-outer-container x432"+behaviour+" "+ sClass+" "+formClass+" "+cl} style={{...{...style,...labelComputedWidth}}}>
                <OuterLabel {...labelProps} />
                <div className={"form-container flex-cen-cen p-rel z-1"}> {/* Class prefix form-bkg-container  */}
                      <Required  />
                      <CoveringLabel   {...{...labelProps,setFocus}}/>
                      <Label   {...labelProps} ref={labelRef}/>
                      <Inputs {...{...props,id,clicked, setClick,label,error, setError, focus, setFocus}}/>
                      <Style sheet={styleSheet + " "+formSheet}/>
                </div>
            </div>
            );
}


export default FormContainer;

function calcBehavior(focus, error,label) {
  let out = " ";
  if (focus) out += "form-focused ";
  if (error) out += "form-error ";
  if (label && label.position === "left" && !label.width && (label.maxWidth && !Number.isInteger(label.maxWidth))) {
    // When label.position is left, we use the label width to compute the left padding for the input,but when the label maxWidth is a percent of the container width,
    // if the width has a transition also the label width will  be trasitioning, so we will compute the label width at the beginning of the transition, making the input padding wrong.
    // To avoid this we have to disable the width transition on the container in this situation.
    out += "frm-no-trans ";
  } else {
    out += "tran-width tran04 ";
  }
  out += !label?.text ? " no-label " : `label-${label.position} `; // We add a class for the label position
  return out;
}

function init({label,id},media) {
  let out = {label:{},id:id ??  Math.round(Math.random() * 10000)};
  if (label) {
    out.label.style = {};
    out.label.position = ["top","left","out"].includes(label?.position) ? label.position : "top";
    out.label.text = isString(label) ? label : label?.text;
    out.label.covering = label?.covering;
    out.label.width = mediaVal(label?.width,media);
    out.label.maxWidth = mediaVal(label?.maxWidth,media);
    if (out.label.width) out.label.style.width = addPx(out.label.width);
    if (out.label.maxWidth) out.label.style.maxWidth = addPx(out.label.maxWidth);
  }
  return out;

}
