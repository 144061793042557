import React, {useState, useEffect,useRef} from 'react';
import "./radioCheck.css"; import {calcSizeAndColors} from './calc/calcs.js';import {Div,Calc,cssInts} from '../../modules';
/*  <div className=""></div>  */

function RadioCheck(props) {
  const {map,setMap,value,box,label} = props;
  const type = ["radio","checkbox"].includes(props.type) ? props.type : "checkbox";
  const ratio = (type === "radio" || props.mark === "bullet") ? " radioRatio " : " checkBoxRatio ";
  const mark = " radCh-" + (["check","bullet"].includes(props.mark) ? props.mark + (props.mark ==="bullet" ? " a-brd-rad-50 " : " a-brd-rad-10 ") : (type === "checkbox" ? "check a-brd-rad-10 " : "bullet a-brd-rad-50 "))+ratio;

  // For radio map contains the value of the selected radio buttons so we have to check if the value in map === value
  // For checkboxes map = {value1:bol,value2:bol, value3:bol ...} so with map[value] we get the bolean relative to this checkbox value
  const checked = (type === "radio") ? (map === value) : map[value];

  const handleChange = (e) => {
    if (type === "radio") {
      // for radios if we select the button we write the value of the radio button in map
      props.setMap(e.target.checked ? e.target.value : null);
    } else {
      // for checboxes if we select the box we access the property map[value] and set it to true or false if checked uncked
      map[value] = e.target.checked; setMap({...map});
    }
  }
  const checkPosition = box?.size?.position ?? "left";
  const margin = box?.size?.gap ?? 20;
  const checkMargin = checkPosition === "top" ? cssInts(margin,"d", "b") + " a-mrB " : (checkPosition === "bottom" ? cssInts(margin,"b", "t") + " a-mrT " : cssInts(margin,"c", "r") + " a-mrR ");
  const flex = ["top","bottom"].includes(checkPosition) ? " flex-cen-cen flex-col " : " flex-cen-start ";
  let labelOverflow;
  const truncateLabel = label?.truncate;
  if (typeof truncateLabel === "boolean") {
    labelOverflow = truncateLabel ? " truncate " : " ov-hid ";
  } else {
    labelOverflow = (["top","bottom"].includes(checkPosition)) ? " ov-hid " : " truncate " ;
  }
  const divStyle = props.divStyle ?? {};
  return (
    <Div {...divStyle} oClass={props.oClass} iClass={props.iClass}>
          <label className={"radCh-container no-select a-pWidth-100 ov-hid "+calcSizeAndColors(box)+flex}>
            <input type={type} name={props.name} value={value} checked={checked} onChange={handleChange}/>
            <div className={"p-rel radCh-checkmark no-shrink "+mark+checkMargin} style={{order:checkPosition ==="bottom"?1:0}}/>
            <div className={labelOverflow+Calc.fontSize(label?.size ?? 14)} >{props.title ?? props.value}</div>
          </label>
    </Div>
  )
}

export default RadioCheck;

/*
<RadioCheck />

*/
