export function  hexToRgb(hex) {
  const value = hex;
  let rgb = {};
  rgb["r"] = parseInt(value.charAt(1)+""+value.charAt(2), 16);
  rgb["g"] = parseInt(value.charAt(3)+""+value.charAt(4), 16);
  rgb["b"]= parseInt(value.charAt(5)+""+value.charAt(6), 16);
  return rgb;
}

export function rgbToHsl(rgb) {  // color = {r: int, g: int, b: int}
  const r = rgb.r/255;
  const g = rgb.g/255;
  const b = rgb.b/255;
  let hsl = {h:0,s:0,l:0};
  const cMax = Math.max(r,g,b);
  const cMin = Math.min(r,g,b);
  const diff = cMax - cMin;
  hsl.l = (cMax + cMin) / 2 ;
  hsl.s = !diff ? 0 : (diff / (1 - Math.abs(2 * hsl.l - 1)));
  hsl.l = Math.round(hsl.l * 100);
  hsl.s = Math.round(hsl.s * 100);
  if (diff == 0) {
    hsl.h = 0;
  } else if (cMax == r) {
    hsl.h = Math.round(60 * (((g - b)/diff) + (((g - b)/diff) < 0 ? 6 : 0) ));
  } else if (cMax == g) {
    hsl.h = Math.round(60 * (((b - r)/diff) + 2));
  } else if (cMax == b) {
    hsl.h = Math.round(60 * (((r - g)/diff) + 4));
  }
  return hsl;
}

export function hslToRgb(hsl) {
    let {h,s,l} = hsl;

    s /= 100;
    l /= 100;
    const k = n => (n + h / 30) % 12;
    const a = s * Math.min(l, 1 - l);
    const f = n =>
    l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
    return {r:Math.round(255 * f(0)),g: Math.round(255 * f(8)),b: Math.round(255 * f(4))};
}

export function intToEsa(num) {
  let out = (parseInt(num)).toString(16);
  if (out.length == 1) {
    out = 0 + out;
  }
  return out;
}
