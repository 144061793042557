

function Gradient({gradient}) {
  if (!gradient) {
    return null;
  } else {
    return (
        <div className={gradient?.cl+" p-abs-full z-2 "} style={gradient.style}/>
    );
  }
}

export default Gradient;
