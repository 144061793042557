import {calcCssClass, calcCssClasses, colToCss,cssInts,calcNumClasses,calcFloatClasses,Calc} from '../../../modules';
import {isObj} from '../../../helpers.js'; import {calcGradientDirection, calcComponent, calcColorStops, calcGradientClass} from './colorsHelper.js';

function calcColors(color) {
  // Vars:  hsl,hsl1,hex,hex1, sm   xs,sm1 (lineHe fWeight)
  const {bkg,a,text,textHov,lh,weight} = color;
  let out = "";
  const hasTwoColors = Array.isArray(bkg);
  const isGradient = hasTwoColors || color.var;
  out += Calc.opacity(a);
  if (isGradient) {
    out += calcGradientBkg(color,hasTwoColors);
  } else {
    out += calcBkg(color);
  }
  if (text) out += colToCss("hex",text) + " a-color ";
  if (textHov) out += colToCss("hex1",textHov) + " a-color1-h ";
  if (lh) out += calcFloatClasses(lh,20,10,"sm1") + " lHeight ";
  if (weight) out += calcNumClasses(weight,10,100,"xs") + " fWeight ";
  return out;
}

function calcBkg(color) {
  let out = "";
  const {bkg, lHov, bkgHov} = color;
  if (bkg && !lHov) {
    out += colToCss("hsl",bkg) + " a-hsl-bkg no-hover ";
  } else if (bkg && lHov) {
    out += colToCss("hsl",bkg) + " a-hsl-bkg ";
    out += ((lHov >=0) ? " plus-sign " : " minus-sign ");
    out += calcCssClasses(lHov,"sm");
  }
  if (bkgHov) out += colToCss("hsl1",bkgHov) + " a-hsl1-bkg-h ";
  return out;
}

function calcGradientBkg(color,hasTwoColors) {
  const blurVar = Array.isArray(color.var) && Array.isArray(color.var[0]) ? color.var[0] : color.var ; // To set an hover value we use an array [blur, hover], instead of just blur
  let out = calcGradientClass(color,hasTwoColors,blurVar);
  if (hasTwoColors) {
    out += colToCss("hsl",color.bkg[0]) + colToCss("hsl1",color.bkg[1]);
  } else {
    out += (["r","g","b"].includes(blurVar[0])) ? colToCss("rgb1",color.bkg) : colToCss("hsl",color.bkg);
    out += calcComponent(color,blurVar); // Vars: sm, sign,xs9, sign1,
  }
  out += calcColorStops(color);   // Vars: sm2,sm3,xs2,xs3
  out += calcGradientDirection(color); // Vars: xs10,11
  return out;
}

export default calcColors;
