import {isObj,isString,hasValue} from '../modules';

export function calcCss(style,className) {
  let out = "";
  if (style.d) {
    out += `.${className} {${style.d}} `;
  }
  if (style.xt) {
    out += `@media (max-width:1400px) { .${className} {${style.xt}} }`;
  }
  if (style.t) {
    out += `@media (max-width:1100px) { .${className} {${style.t}} }`;
  }
  if (style.ml) {
    out += `@media (max-width:970px) and (orientation:landscape) { .${className} {${style.ml}} }`;
  }
  if (style.mp) {
    out += `@media (max-width:450px) and (orientation:portrait) { .${className} {${style.mp}} }`;
  }

  if (style.xm) {
    out += `@media (max-width:350px) { .${className} {${style.xm}} }`;
  }
  return out;
}



export function addStyles(val,stylePref,out,className,notAVar,noPx) {
  if (!isObj(out.style) || !hasValue(val)) return;
  const varPref = notAVar ? "":"--";
  if (className) out.cl += ` ${className} `;
  if (isObj(val)) {
    for (let key in val) {
      out.style[key] += `${varPref+stylePref}: ${addPx(val[key],noPx)}; `;
    }
  } else if (val){
    out.style.d += `${varPref+stylePref}: ${addPx(val,noPx)}; `;
  }

}

export function style(val,stylePref,out,className,notAVar,defUnit) {
  const style =  (Array.isArray(out)) ? out[1] : "style";
  const o =  (Array.isArray(out)) ? out[0] : out;
  if (!isObj(o[style]) || !hasValue(val)) return;
  const varPref = notAVar ? "":"--";
  if (className) o.cl += ` ${className} `;
  if (isObj(val)) {
    for (let key in val) {
      o[style][key] += `${varPref+stylePref}: ${fixUnit(val[key],defUnit)}; `;
    }
  } else if (hasValue(val)){
    o[style].d += `${varPref+stylePref}: ${fixUnit(val,defUnit)}; `;
  }
}

export const styleV = (val,out,pref,defUnit,className) => style(val,pref,out,className,false,defUnit);
export const styleVClass = (val,out,pref,defUnit) => style(val,pref,out,pref,false,defUnit); // Same as styleV but here classname is the same as pref so we don't put the value but copy pref
export const styleP = (val,out,pref,defUnit,className) => style(val,pref,out,className,true,defUnit);






export const addManyStyles = (arr) => arr.forEach(({val,stylePref,out,className,notAVar,noPx}) => addStyles(val,stylePref,out,className,notAVar,noPx));

export function calcFont(val,out,name) {
  const styleName = name ?? "--fSize";
  if (isObj(val)) {
      for (let key in val) {
        out.style[key] += `${styleName}: ${fSizeRow(val[key])}; `;
      }
  } else if (val) {
      out.style.d += `${styleName}: ${fSizeRow(val)};  `;
  }
}


export const fSizeRow = (i) => {
  if (Array.isArray(i)){
    return i?.[2] ? `max(min(${addVw(i[0])}, ${addPx(i[2])}), ${addPx(i[1])})` : `max(${addVw(i[0])},${addPx(i[1])})`;
  } else {
    return addPx(i);
  }
}

export const addStylesProp = (val,pref,out,noPx) => addStyles(val,pref,out,null,true,noPx);

export const fixUnit = (val,defUnit) => (isString(val) || !defUnit) ? val : val+defUnit ;
export const addPx = (val,noPx) => (isString(val) || noPx) ? val : val+"px" ;
export const addVw = (val) => isString(val)  ? val : val+"vw" ;
export const addPc = (val) => isString(val)  ? val : val+"%" ;
export const addDeg = (val) => isString(val)  ? val : val+"deg" ;
