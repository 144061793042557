function settingsInizializer(button) {
  return {
    lBkg: button.bkg.lH >=0 ? true : false,
    lTxt: button.text.lT >=0 ? true : false,
    lIcon:button.icon?.lIc || !button.icon?.icCl ? true : false,
    lArr:button.arrow?.Arr || !button.arrow?.arCl ? true : false};
}

const settingsReducer = (settings,{row,value,init}) => {
  if (init) {
    return init;
  } else {
    settings[row] = value;
    return {...settings};
  }
}

export {settingsReducer,settingsInizializer};
