import {useContext,useState} from 'react';import {SectionButtons} from './SectionButtons/SectionButtons'; import {Title} from './Title';import {BtnContext} from 'Buttons/';
import './dropSection.css'; import './title.css';
// <div className=""></div>

function DropSection({expanded,expand,user,admin,buttons,id,title,icon, keepExpanded}) {
    const {dropContainers,dropMenu,userLogged,adminLogged} = useContext(BtnContext);
    // For section with keepExpanded = true by default it's expanded, we can collapse it and then expand locally clicking the drop arrow, local expand is independednt feom the propped expand, if the button has keepExpanded the local expand state will rule
    const [localExpand,setLocalExpand] = useState(true);
    const {expandable,rows,defaultIcon,multiCols,length} = dropMenu;
    const multiDrop = rows.length>1;
    const expanderPd = dropContainers.expanderPd;
    const isOpen = expandable && !keepExpanded ? (expanded === id) : localExpand; // If the dropdown is not expandable then the section is always open, if it's expandable ubstead it's open only if expanded === id
    const toggle = ()=> keepExpanded ? setLocalExpand(!localExpand) : expand(isOpen ? null : id);
    const shared = {isOpen,expandable,toggle,multiDrop};
    const expandedClass = isOpen && expandable ? " btn-drop-expanded " : "";
    if (!buttons || !Array.isArray(buttons) || !buttons.length)   {
      return null;
    } else if ((admin && !adminLogged) || ((user === true) && !userLogged ) || ((user === "no") && userLogged ))  { // if it's an admin button and the user is not an admin the nutton will be hidden
      return null;
    } else  {
      // removed multidrop with multiCols check if it's right
      return (
          <div className={"drop-section-cont p-rel z-1 a-block-i " +expandedClass+ (length > 1 ? " a-flex-col" : " ov-hid single-drop-cont ") + (dropContainers?.hr??"")}>
              <Title {...{title,icon,dropContainers} } {...shared}  />
              <SectionButtons  {...shared} {...{buttons,expanderPd}} />
              <Hr {...{multiDrop,dropContainers}} />
          </div>
      );
    }
}


const Hr = ({multiDrop,dropContainers}) => multiDrop ? <span className={"btn-drop-separator "+dropContainers?.hrSeparator??""}/> : null;

export default DropSection;
