import {useState,useRef,useEffect,useCallback,useContext,useMemo} from 'react';import "./css/button.css"; import './css/fillings.css';
import { calcColors,calcBtnContainer} from '../'; import ButtonDisplay from './Display/ButtonDisplay';import {BtnContext} from '../';
import {isObj,Link,mediaVal, Tooltip,Shared,Cal,Style} from 'modules'; import {setClickAndHref,calcbuttonContainer} from './calc/calcClickHrefandBtnContainers';


// <div className=""></div>

export function Button(props) {
    const {text,handler,setDropDownClick,dropDownClicked,icon,hasDropMenu,dropMenu,zIndex,btnType,isActive} = props;
    const {media,bot,adminLogged,userLogged} = useContext(Shared);
    const propppedContext = isObj(props.context) ? props.context : {};
    const sizeGeneratedClass = useMemo(()=> props.sizeGeneratedClass ?? "a"+Math.round(Math.random() * 100000),[props.sizeGeneratedClass]);
    const containers = useMemo(()=>({sizes:calcBtnContainer(props,sizeGeneratedClass),colors:calcColors(props)}),[props]);
    const clickTimer = useRef(0);
    const [btnClicked, setBtnClicked] = useState(false); // used in click animation, every time a button is clicked btnclicked becomes true until the animation ends than becomes false until nmext click
    const dropArrowRef = useRef(null);
    const posWdMr = useMemo(()=>Cal.allObj({pos:props.position,wd:props.width,mr:{L:props.mrL}},media),[props.position,props.width,props.mrL]);
    const startAnimation = () => { if (props.animate || containers?.colors?.c)  { setBtnClicked(true);if (clickTimer.current) clearTimeout(clickTimer.current);clickTimer.current = setTimeout(()=>setBtnClicked(false), props.animate?500:100);  }}
    const {click, href} = useMemo(()=>setClickAndHref(props,bot,startAnimation),[bot,props.isActive,props.meta,handler,props.href,props.click,props.animate,containers?.colors?.c]);
    const context = {media,...propppedContext,bot,sizeGeneratedClass,adminLogged,userLogged,handler};
    const [buttonContainer,colorsStyle] = calcbuttonContainer(btnClicked,props,containers,click,href,posWdMr,sizeGeneratedClass);
    const iconTextProps = {icon:props.icon, rightIcon:props.rightIcon && !hasDropMenu}; // When there is a dropMenu we ignore the right icon prop and positiuoin the icon to thge left
    const shared = {bot,media, handling:{adminLogged,userLogged,handler}};


    if ((!text && !icon) || mediaVal(props.noShow,media)) {
        return null;
    } else {
        const display = {containers,iconTextProps,text,noArrow:context.dropMenu?.noArrow,dropDownClicked,hasDropMenu,click,arrowAnimation:context.dropMenu?.arrowAnimation};
        return (
              <BtnContext.Provider value={context}>
                  <Style sheet={(containers?.sizes?.styleSheet??"")+(props.styleSheet??"")} />
                  <Link className={buttonContainer} type={btnType ?? "button"}href={href} noFollow={props.noFollow} noPointer={true} newWindow={props.newWindow}  style={{...posWdMr.style,...props.style,...(colorsStyle??{})}} click={click}>
                      <ButtonDisplay {...display} ref={dropArrowRef}/>
                      <Render render={props.render} a={{dropArrowRef}} />
                      <Tooltip {...props.tooltip} onHover={true}/>
                  </Link>
              </BtnContext.Provider>
        );
  }
}

const Render = ({render,a}) => !render ? null : render?.[0]??render({...(render?.[1]??{}),...a});
