import {calcCssClass, calcCssClasses, colToCss,cssInts,calcNumClasses} from '../../../modules';import {isObj} from '../../../helpers.js';

function calcHeight(divStyle) {
    // Vars b

  const {height,minHeight,maxHeight} = divStyle;
  let out = "";
  if (height) {
    out += cssInts(height,"b","height") + " a-height-with-var";
  } else if (maxHeight) {
    out += cssInts(maxHeight,"c","max-height") + " a-max-height ";
  } else if (minHeight) {
    out += cssInts(minHeight,"d","min-height") + " a-min-height ";
  }
  return out;
}

export default calcHeight;
