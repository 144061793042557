import {forwardRef,ref} from 'react'; import './buttonDisplay.css';
import DropArrow from './DropArrow/DropArrow';import ButtonIcon from './ButtonIcon'; import Text from './Text';

const ButtonDisplay = forwardRef(({containers,iconTextProps,text,noArrow,dropDownClicked,hasDropMenu,click,isActive,btnClicked,arrowAnimation},ref) => {
  return (
    <>
        <ButtonIcon  {...{...iconTextProps,containers,isActive,btnClicked}}  />
        <Text text={text} {...iconTextProps}  />
        <DropArrow  {...{noArrow,hasDropMenu,text,click,arrowAnimation}} colorsClass={containers?.colors?.arrow} clicked={dropDownClicked}  ref={ref} />
    </>
  );
});

export default ButtonDisplay;
