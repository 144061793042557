import React, {useMemo} from 'react'; import {Button,Calc,mediaVal} from '../modules';

const Arrows = (props) => (mediaVal(props.noArrows,props.media)) ? null : <ArrowsInner {...props} />

function ArrowsInner(props) {
  const {width,height} = props.computedSize ?? {};
  const {arrows} = props;
  const size = useMemo(()=>calcArrowSize(width,height,arrows?.size),[width,height,arrows?.size]);
  const {padding,leftPosition,rightPosition,container} = useMemo(()=>calcPositionAndAcontainer(props.computedSize,props.lastIndex,props.index,props.circle,props.arrows,size),[props.computedSize,props.lastIndex,props.index,props.circle,props.arrow,size]);
  let a = {};
  a.opacity = arrows?.a ?? 1;
  a.colors = props.liColors ?? arrows?.colors ?? {b:{text:"white",bkg:"dodger blue"},c:{lClick:-10},h:{lBkg:10}};

  if (!size) {
    return null;
  } else {
    const btnClass = padding ? "p-abs" : "";
    // container should not have overflow hidden because the arrows may be positioned between, the overflow should be idden only for the slider inner
    return (
      <div className={container}>
          <Button animate={true} position={leftPosition} size={size} {...a} className={btnClass} icon="arrow_back_ios_btn"  noShow={props.noPrev} click={props.prev}/>
          <Button animate={true} position={rightPosition} size={size}  {...a} className={btnClass}  icon="arrow_forward_ios_btn" noShow={props.noNext} click={props.next}/>
      </div>
  );
  }

}

function calcPositionAndAcontainer(computedSize,lastIndex,index,circle,arrows,size) {
  if (!size) return {};
  let padding = computedSize?.padding ?? null;
  if (([0,lastIndex].includes(index) && !circle)) padding *= 2
  /*
  When the arrow position is not set if the paddign is set than it will be positioned in the center of the padding, but the padding should be bigger than the button to avoid overflow
  If you are setting a small padding you you set the positions to avoid the automatic centering on the padding
  */
  const leftPosition = arrows?.left ?? (padding ? {l:padding/2,t:"50%",center:true} : ["in","l","30%"]);
  const rightPosition = arrows?.right ?? (padding ? {r:padding/2,t:"50%",center:true} : ["in","r","30%"]);

  //  Don't add a z-index to container otherwise it will cteate a new stacking context, insead we want the buttons to use the parent context, so the buttons only will be have priority, not the container which should go instead behind the text
  const container = "w100 " + (arrows?.containerHeight ? (Calc.height({height:arrows.containerHeight}) + " p-abs-tl ") : " p-abs-full ");
  return {padding,leftPosition,rightPosition,container};
}

export default Arrows;


function calcArrowSize(width,height,arrowSize) {
  if (arrowSize) {
    return arrowSize;
  } else {
    let size = "";
    if (!width || !height) {
      return null;
    } else {
      if (width > 1500 ) {
        size = height > 500 ? {font:24,heightEm:2.3,brdRad:50} : {font:18,heightEm:2.5,brdRad:50};
      } else if (width > 1000) {
        size = height > 350 ? {font:19,heightEm:2.3,brdRad:50} : {font:15,heightEm:2.2,brdRad:50};
      } else if (width > 700) {
        size =  height > 200 ? {font:17,heightEm:2.2,brdRad:50,} : {font:14,heightEm:2.2,brdRad:50};
      } else if (width > 450) {
        size =  height > 100 ? {font:15,heightEm:2.2,brdRad:50,} : {font:13,heightEm:2.2,brdRad:50};
      } else {
        size = {font:12,heightEm:1.8,brdRad:50};
      }
    }
    return size;
  }


}
