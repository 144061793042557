import InputContainer from '../InputContainer/InputContainer';import HsvRect from './HsvRect/HsvRect';

function EditSection({a,showHsv}) {
  if (a.editComponent) {
    return (
      <div className="w100 ">
      <InputContainer {...a.editComponent} {...a}/>
      </div>
    );
  } else if (showHsv) {
    return (
      <div className="w100 ">
      <HsvRect  {...a}/>
      </div>
    );
  } else {
    return (
      <div className="w100 ">
      <InputContainer type="r" {...a}/>
      <InputContainer type="g" {...a}/>
      <InputContainer type="b" {...a}/>
      <InputContainer type="h" {...a}/>
      <InputContainer type="s" {...a}/>
      <InputContainer type="l" {...a}/>
      <InputContainer type="a" {...a}/>
      </div>
    );
  }

}


export default EditSection;
