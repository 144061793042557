import {calcStyle} from '../helpers';
import {isObj,colToCss,mediaVal} from '../../../modules';

function calcBoxShadow(obj,out,media) {
  const blur = obj?.[0] ?? obj;
  const hov = obj?.[1];
  let s = mediaVal(blur?.s,media);
  let sHov = mediaVal(hov?.s,media);
  if (!s && !sHov) {
    return;
  } else {
    let a =  mediaVal(blur?.a,media);
    let aHov =  mediaVal(hov?.a,media);
    out.cl += colToCss("rgb1",blur?.c ?? hov?.c ?? "black");
    if (s) {

        a = [0,0.1,0.2,0.3,0.4,0.5,0.6,0.7,0.8,0.9,1].includes(a) ? a * 10 : 2;
        out.cl += ` shA-${a} `;
        if ([1,2,3,4,5].includes(s)) {
          out.cl += ` bShS-${s} bShad `;
        } else {
          calcStyle(s, out, "bShad",media,"bShS");
        }
    }
    if (sHov || aHov) {
        if (!sHov) sHov = s;
        if (!aHov) aHov = a;
        if ([1,2,3,4,5].includes(sHov)) {
          out.cl += ` bShSH-${sHov} bShadH `;
        } else {
          calcStyle(sHov, out, "bShadH",media,"bShSH");
        }
        aHov = [0,0.1,0.2,0.3,0.4,0.5,0.6,0.7,0.8,0.9,1].includes(aHov) ? aHov * 10 : (s ? a : 2);
        out.cl += ` shAH-${aHov} `;
    }

  }



}

export default calcBoxShadow;
