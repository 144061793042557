import {cssInts} from '../../../modules';
import {isObj} from '../../../helpers.js';

function calcPadding(pd) {
  // Vars:  g,m,n,t (Padding)
  let out = "";
  if (isObj(pd) && !("m" in pd)) {
    if (pd.s) {
      out += cssInts(pd.s,"g", "t") + ` a-pdS `;
    } else {
      if (pd.t) out += cssInts(pd.t,"g", "t") + " a-pdT ";
      if (pd.b) out += cssInts(pd.b,"n", "b") + " a-pdB ";
    }
    if (pd.c) {
      out += cssInts(pd.c,"t", "l") + ` a-pdC `;
    } else {
      if (pd.r) out +=  cssInts(pd.r,"m", "r") + " a-pdR ";
      if (pd.l) out +=  cssInts(pd.l,"t", "l") + " a-pdL ";
    }
  } else if (Array.isArray(pd)) {
    out += " a-pd-4 ";
    if (pd.length === 4) {
      out += cssInts(pd[0],"g", "t");
      out += cssInts(pd[1],"m", "r");
      out += cssInts(pd[2],"m", "b");
      out += cssInts(pd[3],"t", "l");
    } else if (pd.length === 2) {
      out += cssInts(pd[0],"g", "t");
      out += cssInts(pd[1],"m", "r");
      out += cssInts(pd[0],"n", "b");
      out += cssInts(pd[1],"t", "l");
    }
  } else if (Number.isInteger(pd) || (typeof pd === "string") || (isObj(pd) && ("l" in pd))) {
    out +=  cssInts(pd,"g", "t") + " a-pd-1 ";
  }
  return out;
}

export default calcPadding;
