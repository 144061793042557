import {styleV,styleP,isObj,space} from 'modules';

export function calcForm(colors) {
  if (!colors || !isObj(colors)) return ["",""];
  let o = "";
  const classOut = "a" + Math.round(Math.random() * 100000) ;
  const out = {cl:"",blur:"",hover:"",focus:"",error:"",errorHov:""};
  calcInput(out,colors.input);
  calcColor(out,colors.label,"--lbCl");
  calcColor(out,colors.covLabel,"--lbCovCl");
  calcColor(out,colors.brd,"--brdCl");
  calcColor(out,colors.bar,"--barCl");
  calcColor(out,colors.icon,"--iconCl");
  calcColor(out,colors.arrow,"--arrowCl");
  if (out.blur) o += `.${classOut} {${out.blur}} `;
  if (out.hover) o += `@media (hover:hover) { .${classOut}:hover,.${classOut}.hover {${out.hover}} }`;
  if (out.focus) o += `.${classOut}.form-focused {${out.focus}} `;
  if (out.error) o += `.${classOut}.form-error {${out.error}} `;
  if (out.errorHov) o += `@media (hover:hover) { .${classOut}:hover.form-error,.${classOut}.hover.form-error {${out.errorHov}} }`;
  return [classOut,o];
}

/*
  colors = {
  input: {blur:{cl,bkg,brd,bar},hover:{cl,lBkg,brd,bar},focus:{cl,bkg,brd,bar},error:{cl,bkg,brd,bar},errorHov:{}},
  label: {},
  covLabel: {}
}
*/

function calcInput(out,input) {
  if (!isObj(input)) return;
  const {blur,focus,hover,error,errorHov} = input;
  if (blur) {
    if (blur.cl) out.blur += ` --inpCl:${blur.cl.hex};`;
    if (blur.bkg) out.blur += ` --inpBkg:${blur.bkg.hsl.h},${blur.bkg.hsl.s}%,var(--lBkg,${blur.bkg.hsl.l}%);`;
    if (blur.brd) out.blur += ` --brdCl:${blur.brd.hex};`;
    if (blur.bar) out.blur += ` --barCl:${blur.bar.hex};`;
  }
  if (hover) {
    if (hover.cl) out.hover += ` --inpCl:${hover.cl.hex};`;
    if (hover.lBkg) out.hover += ` --lBkg:${hover.lBkg}%;`;
    if (hover.brd) out.hover += ` --brdCl:${hover.brd.hex};`;
    if (hover.bar) out.hover += ` --barCl:${hover.bar.hex};`;
  }
  if (focus) {
    if (focus.cl) out.focus+= ` --inpCl:${focus.cl.hex};`;
    if (focus.bkg) out.focus += ` --inpBkg:${focus.bkg.hsl.h},${focus.bkg.hsl.s}%,${focus.bkg.hsl.l}%;`;
    if (focus.brd) out.focus += ` --brdCl:${focus.brd.hex};`;
    if (focus.bar) out.focus += ` --barCl:${focus.bar.hex};`;
  }
  if (error) {
    if (error.cl) out.error+= ` --inpCl:${error.cl.hex};`;
    if (error.bkg) out.error += ` --inpBkg:${error.bkg.hsl.h},${error.bkg.hsl.s}%,var(--lBkgErr,${error.bkg.hsl.l}%);`;
    if (error.brd) out.error += ` --brdCl:${error.brd.hex};`;
    if (error.bar) out.error += ` --barCl:${error.bar.hex};`;
  }
  if (errorHov) {
    if (errorHov.cl) out.errorHov += ` --inpCl:${errorHov.cl.hex};`;
    if (errorHov.lBkg) out.errorHov += ` --lBkgErr:${errorHov.lBkg}%;`;
    if (errorHov.brd) out.errorHov += ` --brdCl:${errorHov.brd.hex};`;
    if (errorHov.bar) out.errorHov += ` --barCl:${errorHov.bar.hex};`;
  }
}

function calcColor(out,obj,varName) {
  if (!isObj(obj)) return;
  const a = ["blur","focus","hover","error","errorHov"];
  a.forEach((i) => {
    if (obj[i]?.cl) out[i] += ` ${varName}:${obj[i].cl.hex};`;
  });
}
