export function calcSV(el,x,y,hsv,cursorSize) {
  const rect = el.getBoundingClientRect();


  if (x > rect.right) {x = rect.right;} else if (x < rect.left) {x = rect.left;}; // if the cursor is out of border but the s value is not set to (0,100) we poistion it at the rect border
  if (y > rect.bottom) {y = rect.bottom;} else if (y < rect.top) {y = rect.top;}; // if the cursor is out of range but the v value is not set to (0,100) we poistion it at the rect border
  const yAdj =  (y - rect.top) * -cursorSize / (rect.height);// y-y1 = (x-x1) (y2-y1)/(x2-x1) x1=rect.top x2=height y1=0 y2=-20
  const xAdj =  (x - rect.left) *  -cursorSize / (rect.width);// y-y1 = (x-x1) (y2-y1)/(x2-x1) x1=rect.left x2=width  y1=0 y2=-20
  // the cursor has a width and height of 20px, so we have to set the center of the cursor at the position(x,y) so traslating the position (-10,-10) .
  // To avoid the cursor to fall outside the graph's borders instead of traslating -10 we translate from 0(border left and top) to -20 (border right border bottom). We calculate the equation below, in the center of the graph the translationwill be (-10,-10)

  /* Bug in the algoritm if x = 0 the conversion goes in error, add here 0.1 to avoid x to go to 0 */
  const s = Math.round((x - rect.left) / rect.width * 100);
  const v =  Math.round((rect.bottom - y) / rect.height * 100) ;
  return {s,v};
}
