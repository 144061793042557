import {useState,useEffect} from 'react';
import {FlexCols,D} from 'modules'; import SelectMenu from './SelectMenu'; import FormContainer from '../FormContainer';

function Select({add,view,colors,index,setView,setIndex,dispatchColor,deleteElements,deleteElement}) {
  const [editList,setEditList] = useState(null);
  useEffect(()=>{if (view !== "select" || !colors.length) setEditList(null);},[view,colors]);
  if (view !== "select" || !colors) {
    return null;
  } else {
    const length = colors.length;
    const toggleEdit = () => setEditList(editList ? null: []);
    const toggleBtn = (i,id) =>  {
      if (!id) {
        deleteElement(i);
      } else {
        const index = editList.indexOf(id);
        (index) >= 0 ? editList.splice(index,1) : editList.push(id);
        setEditList([...editList]);
      }
    }
    const deleteSelected = ()=> {deleteElements(editList);toggleEdit();};
    const handleSelect = (i) => {setView("edit");setIndex(i);};
    const handleRow = (i,id) => !editList ? handleSelect(i) : toggleBtn(i,id);
    return (
      <D a={{pd:50,colors:{bkg:"azure"}}} >
        <SelectMenu {...{setView,add,editList,toggleEdit,deleteSelected}}/>
        <FlexCols s={{wd:"80%",mr:{C:"auto",S:20}}} cols={7} length={colors.length} mrR={10}>
          {colors.map((color,i) => <TestRow {...{color,i,index,handleRow,editList}} key={color.id}/>)}
        </FlexCols>
      </D>
    );
  }
}

function TestRow({color,i,index,handleRow,editList}) {
  const isSelected = editList && color.id ? editList.includes(color.id) : false;
  const a = {value:!isSelected?"Test":"Selected",focus:false,error:false,setFocus: ()=>null, setError: ()=> null,handleChange: ()=> null};
  return <div className="a-flex-col" onClick={()=>{handleRow(i,color.id);}}><FormContainer {...a} s={{fSize:20}} label = "prova" colors={color}/></div>;
}

export default Select;
