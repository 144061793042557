import React, {useState, useEffect,useRef,forwardRef} from 'react';
import {calcCssClass, calcCssClasses,calcNumClasses,Calc,isObj,def} from  '../../modules';
import {focusCalc, calcTextColor,calcCoveringLabel} from "./calc/calculators.js";

/*  <div className=""></div>  */

function Required(props) {
  if (props.show && !props.value) {
    return (
      <div className="w3-display-topright required w3-red maxW100 w3-padding-2 w3-padding-right w3-text-white w3-small w3-container-8"> Required </div>
    );
  } else {
    return null;
  }
}

function OuterLabel(props) {
  const {label} = props;
  if ((props.labelPosition !== "out") || !label || !props.labelContainer) {
    return null;
  } else {
    const labelContainer = props.labelContainer.shared + (props.focus ? props.labelContainer.focus : props.labelContainer.blur);
    return <label className={labelContainer}><div className="w100 truncate">{props.label}</div></label>;
  }
}

function CoveringLabel(props) {
    const [labelContainer, setLabelContainer] = useState(null);
    useEffect(()=>{ if (props.show) setLabelContainer(calcCoveringLabel(props));},[]);

    if (!isObj(props.size) || (props.labelPosition === "out") || !props.show || !labelContainer) {
      return null;
    }  else {
      return (
        <div className={labelContainer} onClick={props.handleClick}> {props.covLabel} </div>
      );
    }
}

const Label = forwardRef((props,ref) => {
  // Vars: --f ->labelMaxWidth
  if ((props.labelPosition === "out") || !props.labelContainer || !props.label || !(props.value || props.clicked || (props.value===0))) {
    return null;
  } else {
    // Font size is calculated in outer container in a var , section paddings are set in frm-label using  variables defined in form container, calculations are made there not here
    const labelContainer = props.labelContainer.shared + (props.focus ? props.labelContainer.focus : props.labelContainer.blur);
    return <label className={labelContainer} ref={ref}><div className="w100 truncate">{props.label}</div></label>;
  }
});



export {Label, CoveringLabel, Required, OuterLabel};
