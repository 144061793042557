import { focusCalc} from "./calculators" ;
import {calcCssClass, calcCssClasses,cssInts, calcNumClasses,Calc,isObj,isString, def, defObj,mediaVal} from '../../../modules';

const calcOuterContainer = (size, label,focus, className,labelPosition,labelMaxWidth,brd={}) => {
    // Vars -b,--c,--d,--e,--f,--g,--m
    // sm,sm1
    // Free vars that can be used m,n,t
    const {width,flex,pFlex} = size;
    let out = {shared:"form-outer-container p-rel label-"+labelPosition+" "+(label?"":" no-label "), focus:" form-focused ",blur: ""};
    if (width || flex || pFlex) { // Var f
      if (width) {
        if (Array.isArray(width)) {
          out.blur += Calc.width(width[0]);
          out.focus += Calc.width(width[1]);
        } else {
          out.shared += Calc.width(width); // var f;
        }
      } else if (flex) {
        out.shared += Calc.widths({flex: flex});
      } else if (pFlex) {
        if (Array.isArray(width)) {
          out.blur += Calc.widths({pFlex: pFlex[0]});
          out.focus += Calc.widths({pFlex: pFlex[1]});
        } else {
          out.shared += Calc.widths({pFlex: pFlex});
        }

      }
      if  (labelPosition === "left") {
        out.shared += " frm-no-trans "; // No transition when label position is left  and label maxwidth is set as a % because the input left padding is calculated according to the label width, when is a % of the width since the width is % also the label width will be transitioning, but the label width will be computed before the trasition ends and so the input will have the wrong left padding. To compute the right input padding at every focus change the label with cannot have transitions!
      }  else {
        out.shared += " tran-width tran04 ";// We add transition to width changes from focus to blur only when width defined in px, because it's a mess the transition of the label not knowing the final width in px
      }
    }

    if (Array.isArray(brd.s)) {
      out.blur += calcNumClasses(brd.s[0] ?? 0,20,1,"sm1");
      out.focus += calcNumClasses(brd.s[1] ?? 0,20,1,"sm1");
    } else {
      out.shared += calcNumClasses(brd.s ?? 0,20,1,"sm1");
    }
    if (brd.bar && isObj(brd.bar) && ["l","r","t","b"].includes(brd.bar?.p) && brd.bar?.s) {
      if (Array.isArray(brd.bar?.s)) {
        out.blur += calcNumClasses(brd.bar.s[0],20,1,"sm");
        out.focus += calcNumClasses(brd.bar.s[1],20,1,"sm");
      } else {
        out.shared += calcNumClasses(brd.bar?.s,20,1,"sm");
      }
    }
    out.shared += (brd.bar && brd.bar?.p === "l" && brd.bar?.s) ? " outer-label-bar-mr ": " outer-label-brd-mr ";
    out.shared += ` ${def(className," ")} `;
    calcLabelSizeAndSection(size, label,labelPosition,out); // Vars: b,c,d,e,g,m
    return out;
}


const calcLabelSizeAndSection = (size, label,labelPosition,out) => {
  // vars: --b,--c,--d,--e,--g ->label

  // section can be a single value or an array [top,bot]. if is not an array for normal internal label bottomPad = 0, for outer label botPad=topPad = section, because for internal label tge bottom pad is not needed since the input has padding, so it has to be set explicity otherwise it's 0!
  const outerLabel = labelPosition === "out"; const topLabel = (labelPosition === "top") && label; const leftLabel = labelPosition === "left";
  if (label) calcLabelFontSize(size.font,out);
  calcInputBottomPadding(size.pd?.i,out,topLabel);
  if (label) calcLabelPadding(size.pd?.l,out,leftLabel,outerLabel)
}

function calcLabelFontSize(fontSize,out) {
  // vars b,c,d -> fontsize creates a var, which is catched byt the outercss contianer and renamed to labelSize toa void being modified in childs call of calcFonbtSize: --label-fSize:var(--fSize);

  if (Array.isArray(fontSize)) {
    out.blur += Calc.fontSize(isObj(fontSize[0]) ? fontSize[0].l ?? fontSize[0].i : fontSize[0]);
    out.focus += Calc.fontSize(isObj(fontSize[1]) ? fontSize[1].l ?? fontSize[1].i : fontSize[1]);
  } else {
    out.shared += Calc.fontSize(isObj(fontSize) ? fontSize.l ?? fontSize.i : fontSize);
  }

}

function calcInputBottomPadding(inputBotPadding,out,topLabel) {
  if (!topLabel) {
    out.shared += calcNumClasses(0,100,1,"g");
  } else {
    if (Array.isArray(inputBotPadding)) {
      out.blur += calcNumClasses(inputBotPadding[0],100,1,"g");
      out.focus += calcNumClasses(inputBotPadding[1],100,1,"g");
    } else {
      out.shared += calcNumClasses(inputBotPadding,100,1,"g");
    }
  }
}

function calcLabelPadding(labelPadding,out,leftLabel,outerLabel) {
  // added same bottom padding as top padding for one value padding, before it was added only for outer padding check if it works. Neing absolutely positioned the botom padding should not interfere, but it can we useful when you add a background to the label
  if (leftLabel) {
    out.shared += calcNumClasses(0,100,1,"e") + calcNumClasses(0,100,1,"m");
  } else if (Array.isArray(labelPadding)) {
    out.blur += calcNumClasses(labelPadding[0]?.t ?? labelPadding[0] ?? 0,100,1,"e") + calcNumClasses(labelPadding[0]?.b ?? labelPadding ?? 0,100,1,"m");
    out.focus += calcNumClasses(labelPadding[1]?.t ?? labelPadding[1] ?? 0,100,1,"e") + calcNumClasses(labelPadding[1]?.b ?? labelPadding[1] ?? 0 ,100,1,"m");
  } else {
    out.shared += calcNumClasses(labelPadding?.t ?? labelPadding ??0,100,1,"e") + calcNumClasses(labelPadding?.b ?? labelPadding ?? 0,100,1,"m");
  }
}

export default calcOuterContainer;

/*
if (Array.isArray()) {
  out.blur +=
  out.focus +=
} else {
  out.shared +=
}
*/
