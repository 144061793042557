import {addStyles} from 'modules';

export function calcMultiDropHeader(header,out) {
  if (!header) {
    return;
  } else {
    const {pdC,height,weight,font,icon,expMrB,lh,gap,expander} = header;
    addStyles(pdC,"headerPdC",out);
    addStyles(expander?.mrB,"expMrB",out);
    addStyles(expander?.arrow?.size,"expArrowSize",out);
    addStyles(expander?.arrow?.mrL,"expArrowMrL",out);
    addStyles(gap,"headGap",out);
    addStyles(height,"headHe",out,null,null);
    addStyles(icon,"headIcon",out,null,null);
    addStyles(font,"headFsize",out,null,null);
    addStyles(weight,"headWeight",out,null,null,true);
    addStyles(lh,"headLh",out,null,null,true);
  }
}
