import { focusCalc} from "./calculators" ;
import {calcCssClass, calcCssClasses,cssInts,Calc,isObj,mediaVal, def, defObj} from '../../../modules';

/*
const calculateInputWidth = (outerRef,id, isSet) => {
    const width = outerRef.getBoundingClientRect().width;
    const sheet = document.styleSheets[0];
    if (!isSet) {
      sheet.insertRule("."+id+ ` {--inputWidth: ${width}px;}`, sheet.cssRules.length);
    } else {
      const rule = [...sheet.cssRules].find((r) => r.selectorText === "."+id);
      if (rule) {
        rule.style.setProperty('--inputWidth', width+"px");
      }
    }
}
*/
const calcInputPadding = (labelRef) => {
  // Vars: None
    if (labelRef) {
      return labelRef.current.getBoundingClientRect().width;
    } else {
      return null;
    }

}

function calcInputContainer(size) {
  // Vars: c,e
  let out = {shared:"frm-input-container ", focus:"",blur: ""};
  let inputContainer = size.pd?.c;
  if (Array.isArray(size.pd?.c)) {
    out.blur += calcPad(size.pd?.c[0],); // var g;
    out.focus += calcPad(size.pd?.c[1]??size.pd?.c[0]); // var g;
  } else {
    out.shared += calcPad(size.pd?.c); // var g;
  }
  return out;
}

function calcPad(input) {
  let inputContainer = input?.i ?? input;
  const leftPd = inputContainer?.r >=0 ? inputContainer.l : inputContainer;
  const rightPd = inputContainer?.r ?? inputContainer;
  return cssInts(leftPd,"m", "l") + cssInts(rightPd,"t", "r");
}

export {calcInputContainer, calcInputPadding};
