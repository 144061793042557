import {forwardRef,useMemo,useContext} from 'react';
import {isObj,mediaVal,Shared,addStyle,Cal,Style,CalcNew} from 'modules';import './flexCols.css';

export const FlexCols = forwardRef((props,ref) => {
    const {media} = useContext(Shared);
    const {cl,style,styleSheet} = useMemo(()=>calcFlexContainer(props,media),[props,media]);
      return (
        <div className={cl} dataType="flex-cols-wrapper" ref={ref} style={style}>
            {props.children}
            <Style sheet={styleSheet}/>
        </div>
      );
});

function calcFlexContainer({cols,length,flex,style,s,mr,mrB,mrR,cl},media) {
      let out = {cl:"",style:style??{}};
      out.cl += (cl ?? "") + (flex ? ` ${flex} ` : " flex-stretch-start wrap "); // Default flex class is strecth-start, use the flex pro to change that
      const nCols = mediaVal(cols,media);
      out.cl += ` nCols-${nCols} `;
      const res = length % nCols;
      const mrB_ = mrB ?? mr ?? 0;
      const mrR_ = mrR ?? mr ?? 0;
      out.cl += ` no-last-mrB-${res} `;
      out.cl += ` no-mrR-${nCols} `;
      addStyle([[mrB_,"fxMrB"],[mrR_,"fxMrR"]],out.style,media);
      if (s) {
      const  [sClass,styleSheet] = CalcNew.all(s);
      out.cl += ` ${sClass} `;
      out.styleSheet = styleSheet;
      }
      return out;

}
