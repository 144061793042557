import {useEffect,useState,useCallback} from 'react';

export function useViewport(isEnabled,initial,ref,mr) {
  const [inViewPort,setViewport] = useState(initial ?? false);

  useEffect(()=>{
    const scroll = ()=> {setViewport(isInViewport(ref.current,mr));};
    if (isEnabled) {
      window.addEventListener("scroll", scroll);
      return ()=> window.removeEventListener("scroll", scroll);
    }
  },[isEnabled,ref,mr]);
  return inViewPort;
}

  const isInViewport = (elem,mr=0) => {
      console.log(document.documentElement.scrollTop)
      if (!elem) {
        return null;
      } else {
        var rect = elem.getBoundingClientRect();
        return (rect.top >=(0 - mr))  && (rect.bottom <= (rect.height + mr));
      }
    };


// we havew to call autoliot( very time invoiewport is changed) so it can be enabled and diaabled
//
