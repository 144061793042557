import {useEffect, useState, useRef,forwardRef,useMemo,useContext} from 'react'; import {mediaVal,isObj} from 'helpers';
import {addPx} from 'modules';  import DropInner from './DropInner/DropInner';
import './dropDown.css'; import {fixOverFlow,setTipPosition} from './helpers';
import {BtnContext} from '../../';



export function DropDown({dropArrowRef}) {
  const [tipPos, setTipPos] = useState(0);
  const [xAdj,setXadj] = useState(0);
  const {dropContainers,dropDownClicked,closeDropDown,sizeGeneratedClass,media,bot,dropMenu} = useContext(BtnContext);
  const {rows,width,multiCols,noArrow,maxHeight,minWidth,multiDrop} = dropMenu;
  //const containers = dropContainers,clicked=dropDownClicked,setClick=setDropDownClick;
  const innerRef = useRef(null);
  const dropDownRef = useRef(null);

    // Compute the position of the tooltip arrow tip so that it's placed just under the button's dropdown arrow
  useEffect(()=>fixOverFlow(dropDownClicked,dropDownRef,xAdj,setXadj,false),[dropDownClicked]); // Don't remove arrowSize, tipSize, even if they are not supposed to change they trigegr a dependency error if missing
  useEffect(()=>setTipPosition(dropDownRef,dropArrowRef,innerRef,setTipPos,noArrow),[dropDownClicked,xAdj]);
  const direction = ((mediaVal(width,media) === "full") ? "full" : "center")  /*+(props.leftPos ? " left-drop " : "")*/;
  const dropMenuOpening = bot ? " a-btn-drop-menu-hover " : (dropDownClicked ? " a-btn-drop-menu-clicked " : ""); // drop down open by hover only for bots to avoid costy calculations and effects on drop down not visible
  const dropContainer = "a-btn-drop-menu a-btn-drop-menu-colors " + " drop-"+ direction + (multiDrop ? " multi-drop-btn ": " ") + dropMenuOpening+(dropContainers.dropContainer??"")
        + (xAdj ? " drop-overflow " : "");
  let style = {};
  if (width !== "full") style.width=width?addPx(mediaVal(width,media)):((120*(multiCols??1))+"%");
  if (maxHeight) style["--maxHeight"] = addPx(mediaVal(maxHeight,media));
  if (minWidth) style.minWidth = addPx(mediaVal(minWidth,media));
  if (xAdj) style["--overFlowAdj"] = xAdj+"px";
  if (dropContainers.colorsStyle) style = {...style,...dropContainers.colorsStyle}

  return (
      <>
        <div className={dropContainer} ref={dropDownRef} onClick={(e)=>e.stopPropagation()} style={style}>  {/*Cal width, colors, brdRad and dropRow variables*/}
            <DropInner tipPos={tipPos}  ref={innerRef}/>
        </div>
        <Overlay show={dropDownClicked} handleClose={closeDropDown}/>
      </>
  );
}

const Overlay = ({show,handleClose}) => (show ? <div className="a-btn-drop-overlay" onClick={(e)=>{e.stopPropagation();handleClose();}} onTouchStart={(e)=>{e.stopPropagation();setTimeout(handleClose,500);}}/> : null);
