import React, {useState, useEffect,useRef} from 'react';
import {isString,calcCssClass, calcCssClasses,isObj,mediaVal,Tooltip,useMediaQuery,def,cssInts} from '../../modules';
import "./formContainer.css"; import '../form.css'; import {Label, CoveringLabel, Required,OuterLabel} from './labels';
import calcOuterContainer from './calc/outerContainer.js';
import {defColors, defSizes} from "./defs.js";import Inputs from './Inputs'; import calcBkgContainer from './calc/bkgContainer';
import {calcTextColor, focusCalc,calcLabel} from "./calc/calculators.js"; import {calcFormCont} from './calc/formContainer.js';
import {calcInputContainer, calcInputPadding, calculateInputWidth} from "./calc/calcInputSize.js";


/*  <div className=""></div>  */

function FormContainer(props) {
      /******* Media Queries ****/
        let media = {};
        media.isPortrait =  useMediaQuery({ query: '(orientation: portrait)' });
        media.isLandscape =  useMediaQuery({ query: '(orientation: landscape)' });
        media.isMobile = useMediaQuery({ maxWidth: 734 });
        media.isTablet = useMediaQuery({ maxWidth: 1250, minWidth: 751 });
        media.max1420 = useMediaQuery({ maxWidth: 1420, minWidth: 751 });
      /******* Media Queries ****/

      const [clicked, setClick]= useState(false);
      const [formContainer, setFormContainer] = useState(null);
      const [outerContainer, setOuterContainer] = useState(null);
      const [bkgContainer, setBkgContainer] = useState(null);
      const [labelContainer, setLabelContainer] = useState(null);
      const outerRef = useRef(null);
      const {label, value,brd,size,color} = props;
      const labelPosition = ["top","left","out"].includes(props.labelPosition) ? props.labelPosition : "top";
      const {error, setError, focus, setFocus} = props.state;
      const [labelComputedWidth, setLabelComputedWidth] = useState(""); /* ***Input Padding****    -> only when labelPosition is "left", we need the computer the label width and add it tot he left padding of the input because the label is absolutely positioned so we need to make space for it */
      const state = {clicked, setClick, ...props.state};
      const labelRef = useRef(null);


      // Better to create also a label width prop in order to consider the case when the left  label width should be fixed to have sevral forms with the same sized of label and input

      useEffect(()=>{  /* ***Input Padding****    -> only when labelPosition is "left" */
        if (labelPosition == "left" && !props.labelWidth && formContainer && bkgContainer && outerContainer && labelContainer) {  // if labelPosition is "left" we have to calculate the label width and use it as padding left for the input. We have to wait the element to render to compute it, put the value in state and than update the input.
            setLabelComputedWidth(cssInts(calcInputPadding(labelRef), "f","width")+" frm-label-computed-width ");
        }
      },[focus,bkgContainer,formContainer,outerContainer,labelContainer]);   //Since the Width can change on focus (if a different width has been set in props in focus state) so we have to recalculate the label with at every focus change

      useEffect(()=>{
        setFormContainer(calcFormCont(brd,labelPosition,label,props.noPointer,size.pd,props.formType,props.labelWidth,color));
        setOuterContainer(calcOuterContainer(size, label,focus, props.className, labelPosition,mediaVal(props.labelMaxWidth),props.brd));
        setBkgContainer(calcBkgContainer(size, media, color, label, brd,props.formType));
        if ((props.labelPosition !== "out") && props.label) setLabelContainer(calcLabel(props));
      },[props]);

    const setRedBackground = ()=> setError(true);
    const handleLabelClick = (e)=> {e.stopPropagation();setClick(true); setFocus(true);}
    const covLabel = props.covLabel ? props.covLabel : label;
    const showCovLabel = (!clicked && (!value && (value !== 0)) && label && (covLabel !== "noLabel"));
    const share = {labelPosition, focus, size, color,label,labelContainer};
    const form_outer_container = !outerContainer ? "" : (outerContainer.shared + (focus ? outerContainer.focus : outerContainer.blur));
    // only for box shadow we select one between focus error and blur value, for all the others error=blur because lengths change only on focus and colors changes in error are taken care by css. For box shadow we differenciate colors in js instead. Beware on error+focus state we chose the error box shadow state,
    const boxShadow = !bkgContainer?.shadow ? "" : (bkgContainer.shadow.shared + (error ? bkgContainer.shadow.error : (focus ? bkgContainer.shadow.focus : bkgContainer.shadow.blur)));
    const form_bkg_container =  !bkgContainer ? "" : bkgContainer.shared +  " w100 " + bkgContainer.shadow.shared + (focus ? bkgContainer.focus : bkgContainer.blur) + boxShadow;
    const form_container = !formContainer ? "" : formContainer.shared + (focus ? formContainer.focus : formContainer.blur) + (error ? formContainer.error : "") + labelComputedWidth;


    return (
            <div className={form_outer_container} ref={outerRef}> {/* Class prefix form-outer-container */}
                <OuterLabel {...share} />
                <Tooltip {...props.tooltip}/>
                <div className={form_bkg_container}> {/* Class prefix form-bkg-container  */}
                    <div className={form_container}> {/* Class prefix form-container  */}
                        <Required show={props.required} value={value} />
                        <CoveringLabel  {...share} covLabel={covLabel} handleClick={handleLabelClick} show={showCovLabel}/>
                        <Label  clicked={clicked} value={value}  labelMaxWidth={props.labelMaxWidth} labelWidth={props.labelWidth}  {...share} ref={labelRef} />
                        <Inputs {...props} showInner={!showCovLabel}  state={state} {...share} media={media}/>
                    </div>
                </div>
            </div>
            );
}



export default FormContainer;
