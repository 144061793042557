import React, {forwardRef,useState,useEffect} from 'react';
import {Calc,SvgIcon} from '../../../modules';

const Arrow = forwardRef((props,ref)=> {
  const [margin,setMargin] = useState("");
  useEffect(()=>setMargin(Calc.margin({l:props.arrow?.mrL ?? 4})),[]);
  if (props.arrow === false) {
    return null;
  } else {
    return (
      <div className={margin} ref={ref}>
          <SvgIcon icon={{name:"arrow_drop_down",padding:{t:props.arrow?.pdT ?? 8}}}  size={props.arrow?.size ?? 24} colorClass="input-drop-arrow" />
      </div>
    );
  }
}
);

export const Display = forwardRef((props,ref)=>
  (
    <div className={props.inputContainer + " w3-animate-opacity "} onClick={props.handleDropOpening}  key={props.valueName}>
        <SvgIcon icon={props.selectedIcon} sizeClass="input-icon-size" colorClass="input-icon-color"/>
        <div className="grow-1 truncate">{props.valueName}</div>
        <Arrow arrow={props.arrow} ref={ref} />
        <input type="hidden" name={props.name} value={props.value} />
    </div>
  )
);

export const Overlay = (props) => (props.show ? <div className="frm-overlay" onClick={(e)=>{e.stopPropagation();props.clickHandler();}} onTouchStart={(e)=>{e.stopPropagation();setTimeout(props.close,500);}}/> : null);
