import {useRef} from 'react'; import {D,FormInput,Button} from 'modules';

function NewLists({lists,setLists,saveList}) {
  const keysCounter = useRef(1);
  const addList = () => {lists.push({name:"",key:keysCounter.current});keysCounter.current++; setLists([...lists])};

    return (
      <D >
        <D cl="flex-cen-btw">
          <D >New lists</D>
          <Button icon="close" click={()=>setLists(null)}/>
        </D>
        {lists.map((row,index) => <ListInput {...{...row,setLists,lists,index}} key={row.key} />)}
        <D cl="flex-cen-btw"><Button icon="add" click={addList}/><Button icon="save" click={saveList}/></D>
      </D>
    );
}

export default NewLists;




function ListInput({name,setLists,index,lists}) {
  const handleChange = (val) => {lists[index].name = val; setLists([...lists])};
  const handleDelete = () => {
    let newLists;
    const length = lists.length;
    if (length > 1) {
      const arr1 = lists.slice(0,index);
      const arr2 = lists.slice(index+1,length);
      newLists = [...arr1,...arr2];
    } else {
      newLists = [];
    }
    setLists([...newLists])
  };
  return (
    <div className="flex-cen-btw">
      <FormInput value={name} handleChange={handleChange} size={{font:13,height:30,pd:{c:12,i:0},width:250}} color={{bkg:{b:"dodger blue",f:"green",e:"red"},input:{}}}/>
      <Button icon="delete" click={handleDelete}/>
    </div>
  );
}
