import React, {useState,useEffect, useRef,useMemo} from 'react'; import Inner from './Inner'; import './slider.css';
import {mediaVal,useViewport,D,isNumber} from '../modules'; import Arrows from './Arrows';



function  SliderInner(props) {
  const {rows,auto,circle,cols, endIndex,lastIndex,lastAinmationCols,lastcss,media,lastIterRatio,startIndex,pd} = props;
  const [index,setIndex] = useState(!startIndex ? 0 : (circle ? startIndex : (startIndex - (startIndex % cols)))); // when we go beckward when circle is not enable we always do index - cols, because we supposed when started from 0 or 0+n*cols, so our index needs to be a multiple of cols to be able to keep 0 as the first index when going backward with -cols
  const ref = useRef(null);
  const [prevIndex,setPrevIndex] = useState(0);
  const [direction,setDirection] = useState("");
  const [animate, setAnimation] = useState(null);
  const [action, setAction] = useState("");
  //useEffect(()=>{if (action === "prev") {handlePrev();} else if (action === "next") {handleNext();}},[count]);
  const inViewPort = useViewport(props.viewPortCheck && auto,true,ref,100);
  const [touchX, setTouchX] = useState(null);
  const [move, setMoveX] = useState(0);
  const [computedSize,setComputedSize] = useState(null);
  const length = rows.length;
  useEffect(inizialize,[props]);
  useEffect(computeSize,[media,pd]);
  useEffect(removeCompletedAnimation,[index]); // at Every index change we set animate to next or prev and we start a timer to remove the animation after it's complete
  useEffect(autoPilot,[animate,inViewPort]); //every time the animation has completed and is set to "" we start the autopilot timeout if enabled to schedule the next handleNext. We return null when animate is next or prev becuasx we wait the next animate update when the animation ends
  const autoPilotTimer = useRef(null);
  const endAnimationTimer = useRef(null);
  const innerRef = useRef(null);
  const animationDuration = useMemo(calcAnimDuration,[media,props.animationTime,index,animate,lastIndex,cols]);   // Thethe animation time for handleNext and handlePrev

  function calcAnimDuration() {
    const animDuratAdj = (index === lastIndex && ["next","next-2"].includes(animate) && !circle) || (index === lastIndex-cols && ["prev","prev-2"].includes(animate) && !circle) ? lastcss/cols : 1;
    const moveAdj = move ? (1-(Math.abs(move)/media.width)):1;
    return Math.round((mediaVal(props.animationTime,media) ?? 0.5) * 1000 ) * animDuratAdj * moveAdj;
  }

  function inizialize() {
    if (auto) autoPilot();
    return ()=> {
      if (autoPilotTimer.current) clearTimeout(autoPilotTimer.current)
      if (endAnimationTimer.current) clearTimeout(endAnimationTimer.current);
    };
  }

  function computeSize() {
    // padding adkustment has been removed to avoid a re-rendering this mean this code commented below needs to be added where padding is used
    // if (!([0,lastIndex].includes(index) && !circle)) padding /= 2;
    const [width,height] = [ref.current.offsetWidth, ref.current.offsetHeight];
    const innerWidth = innerRef?.current?.offsetWidth ?? 0;
    let padding =  innerWidth ? (width-innerWidth)/2 : 0;
    console.log(padding)
    setComputedSize({width,height,padding,innerWidth});
  }

  function removeCompletedAnimation() {
    // we need to remove the animation after it has completed expecially because the touch move gets disabled during animations
    if (animate) {
      if (endAnimationTimer.current) clearTimeout(endAnimationTimer.current);
      endAnimationTimer.current = setTimeout(() => {setAnimation("");setMoveX(0);}, animationDuration); // We add a 100 ms margin after the animation has completed to avoid glitches
    }
  }


  function autoPilot() {
      if (autoPilotTimer.current) clearTimeout(autoPilotTimer.current);
      if (auto && (endIndex > 0) && inViewPort && !animate) {
        const autoPilotDuration = (isNumber(auto) ? (auto * 1000) : 8000)  ; // The time to wait to call the next handleNext  // cahnged !isNaN to isnumber
        autoPilotTimer.current = setTimeout(() => handleNext(), autoPilotDuration);
      }

  }



    function handleNext() {

      if (autoPilotTimer.current) clearTimeout(autoPilotTimer.current);
      if (cols === rows.length) return; // cols cannot be bigger than length, it's value has been adjusted, so we check only if ===
      const currentIndex = index;
      if (((index + cols) > endIndex) && props.circle) { // if we reached the last element and circle is on we set the enxt index to 0 to start over from beginning
        setIndex(index + cols - endIndex - 1);
        // We need to switch between two identical animation because in case we call next before the animation has comppleted nad its class removed, we would have to first remove the aniam,tion class, then render and then finally calling next, we need an extra render for nothing, instead we swithc betwenn two classes when we want to restart the same animation
        if (!animate || animate==="next-2") {setAnimation("next")} else {setAnimation("next-2")}
      setDirection("next");
      } else if ((index + cols) > endIndex) { // last element but circle is false so we do nothing and return null because we cannot go next
        return null;
      } else { // index gets incremented by 1 and animate set to next
        setIndex(index + cols);setDirection("next");
        if (!animate || animate==="next-2") {setAnimation("next")} else {setAnimation("next-2")}
      }
    }

    function handlePrev() {
      if (autoPilotTimer.current) clearTimeout(autoPilotTimer.current);
      if (cols === rows.length) return; // cols cannot be bigger than length, it's value has been adjusted, so we check only if ===
      const currentIndex = index;
      if ((index - cols < 0) && props.circle) { // auto is on and index is 0 so we set index back to the last element endIndex
        setIndex(endIndex + 1 + index - cols);setPrevIndex(currentIndex);setDirection("prev");
      if (!animate || animate==="prev-2") {setAnimation("prev")} else {setAnimation("prev-2")}
      } else if (index - cols < 0) { // return null because cannot prev than 0 if auto is off
        return null;
      } else {
        setIndex(index-cols); setDirection("prev"); // index+=-1 and animations et to prev
        if (!animate || animate==="prev-2") {setAnimation("prev")} else {setAnimation("prev-2")}
      }

    }

    function handleSelection(index) {
        setMoveX(0);
        if (autoPilotTimer.current) clearTimeout(autoPilotTimer.current);
        if (cols > 1 || (cols === rows.length)) return;
        let nextIndex = index;  setIndex(index);  setAnimation("skip");setDirection("skip");
    }

    const touchStart = (e)=> setTouchX(e.changedTouches[0].clientX);

    const touchEnd = (e)=> {
      if (touchX === null) {
        return;
      } else {
        const x = e.changedTouches[0].clientX;
        if ((index === 0 && !circle && x >= touchX)||(index === lastIndex && !circle && x <= touchX)) {
          return;
        } else {
          const delta = Math.abs(x - touchX);
          if (delta > computedSize.innerWidth/4) {
            if (x > touchX) {
              const max = Math.round((index === lastIndex-cols) && !circle ? computedSize.innerWidth * lastIterRatio  : computedSize.innerWidth );
              setMoveX(Math.min(Math.round(Math.abs(x - touchX)),500,max));
              handlePrev();
            } else {
              const max = Math.round((index === lastIndex) && !circle ? computedSize.innerWidth * lastIterRatio  : computedSize.innerWidth );
              setMoveX(-1 * Math.min(Math.round(Math.abs(x - touchX)),500,max));
              handleNext();
            }
          } else {
            setMoveX(0);
          }
          setTouchX(null);
        }
    }
  }

  const touchMove = (e)=> {
      if (animate || touchX === null) {
        return;
      } else {
        const x = e.changedTouches[0].clientX;
        if ((index === 0 && !circle && x >= touchX)||(index === lastIndex && !circle && x <= touchX)) {
          return;
        }  else {
          const max = Math.round(index === lastIndex || ((index === lastIndex-cols) && !circle && (x < touchX)) ? computedSize.innerWidth * lastIterRatio  : computedSize.innerWidth );
          const delta = Math.min(Math.round(Math.abs(x - touchX)),500, max);
          const sign = x >= touchX ? 1 : -1 ;
          setMoveX(delta*sign);
        }
      }
  }


  const touchCancel = (e) => setMoveX(0);
  const animationEnded = ()=>null;/* {
    setAnimation("");
    if (endAnimationTimer.current) clearTimeout(endAnimationTimer.current);
  };
*/

  function calcClasses() {
    const noArrow = {noPrev: (!circle && ((index - cols) < 0)) , noNext:(!circle && ((index + cols) > endIndex))};
    let outerClass = "uld-sld-outer ";
    if  (!circle) {
      if (index === lastIndex && ["next","next-2"].includes(animate)) {
        outerClass += " uld-last-iter ";
      } else if (index === lastIndex-cols && ["prev","prev-2"].includes(animate)) {
        outerClass += " uld-last-iter ";
      }
    }
    const liColors = cols > 1 ? null : rows[index]?.arrowColors;  // Only when cols == 1 we allow to specify a color for each slide using arrowColors in the row element
    const paddingAdj = (index === 0 && !circle) ? " first-uld-index " : (((index+cols)>=length) && !circle) ? " last-uld-index " : "";
    outerClass += paddingAdj + (circle ? " ": " no-circle ");
    return {noArrow,outerClass,liColors,paddingAdj};
  }

  const pr = {prevIndex,index,cols,animate,animationDuration,inViewPort,lastAinmationCols,lastIndex,media,animationEnded,direction,move};
  const style = {"--movX":move+"px","--cols":cols,"--last":lastcss,"--pd":pd};
  let {noArrow,outerClass,liColors,paddingAdj} = useMemo(calcClasses,[index,circle,animate,cols,length]);
  const touchHandlers = {onTouchCancel:touchCancel,onTouchMove:touchMove,onTouchStart:touchStart,onTouchEnd:touchEnd};

  return (
    <div className={outerClass} {...touchHandlers} ref={ref} style={style}>
        <Inner {...props} next={handleNext} prev={handlePrev} {...pr} ref={innerRef} />
        <Arrows prev={handlePrev} next={handleNext}  arrows={props.arrows} noArrows={props.noArrows} {...noArrow}  {...{liColors,paddingAdj,computedSize,media,lastIndex,index,circle}}/>
    </div>

  );

}


export default SliderInner;
