import {isObj,addStyles,calcFont,calcCss} from 'modules';

export function calcDropArrowTipAndTrans(size,cssClass,out) {
  if (!isObj(size)) {
    return null;
  } else {
    const {arrow,yTrans,tip} = size;
    let out1 = {cl:"", style:{d:"",xt:"",t:"",mp:"",ml:"",xm:""}};
    /* Drop Arrow */
    calcFont(arrow?.size,out1,"--arrowSize");
    addStyles(arrow?.mrL,"arrowMrL",out1);
    addStyles(arrow?.mrT,"arrowMrT",out1);
    /* Drop Arrow */

    calcDropTranslation(yTrans,out1)
    addStyles(tip,"tipSize",out1); // drop menu tip size
    out.style += calcCss(out1.style,cssClass+".a-btn");
    out.buttonContainer += out1.cl;
  }


}



function calcDropTranslation(yTrans,out) {

  if (yTrans?.endOutward) {
   addStyles(yTrans.endOutward,"tEnd",out,"outward-drop");
  } else if (yTrans?.endRatio) {
    addStyles(yTrans?.endRatio,"endRatio",out,null,null,true);
  }
  if (yTrans?.start) addStyles(yTrans.start,"tStart",out);
}
