import {calcStyle} from '../helpers';
import {isObj} from '../../../modules';

function calcMargin(mr,out,media) {
  // Vars:  g,m,n,t (Padding)
  if (isObj(mr) && !("d" in mr)) {
    if (mr.S) {
    calcStyle(mr.S,out,"mrS",media);
    } else {
      if (mr.T) calcStyle(mr.T,out,"mrT",media);
      if (mr.B) calcStyle(mr.B,out,"mrB",media);
    }
    if (mr.C) {
      calcStyle(mr.C,out,"mrC",media);
    } else {
      if (mr.R) calcStyle(mr.R,out,"mrR",media);
      if (mr.L) calcStyle(mr.L,out,"mrL",media);
    }
  } else {
    calcStyle(mr,out,"mr",media);
  }
}

export default calcMargin;
