export function setClickAndHref(active,bot,startAnimation,handler,meta,props) {
      let click, href;
       if (active) { // if button is active it should not been clicable or linkabeble
          click = false; href = null;
        } else if ((meta && handler && !bot) || (meta && handler && !props.href))  {
          click = ()=> {
            startAnimation();
            props.handler(props.meta);
          }
        } else if ((props.click && !bot) || (props.click && bot && !props.href)) {
        click = ()=> {
          startAnimation();
          props.click();
        };
      } else if ((props.href && !props.click) || (props.href && props.click && bot)) {
        href = props.href;
      }
    return {click,href};
}

export function calcbuttonContainer(btnClicked,props,click,href,active,icon,text,hasDropMenu) {
  let buttonContainer  = (btnClicked && props.animate)  ? " a-btn-clicked-anim  " : " ";    // Start the trasnlateY animation if the button has been clicked and the animate prop has been set true
  buttonContainer += (active || btnClicked ? " no-hover " : " pointer ");
   // We need to increase the z-index when the dropdown or the fixed menu is open because even if the child have a very hight z-index , if the parent has a low z-index they will go behind, because their z-index puts them before the parent but not before the elements the parent was behind
  buttonContainer += props.zIndex ?? " z-5 ";
  if (btnClicked) buttonContainer += " btn-clicked ";
  buttonContainer += (!click && !href && !active) ? " no-hover a-btn-opac " : " ";



  buttonContainer += (props.forceHover ? " force-hover " : "");                          // simulate hover color via prop to test the color on not hoverable devices
  buttonContainer += (props.noBlurBkg ? " no-blur-bkg ": "");                        // if topbar has the same background has the button you can disable the button bkg via props in case alpha is set otherwaise the y will sum up
  if (icon && !text && !props.width)  buttonContainer += " icon-only ";  // If it's an icon only button and with is not set, set the width equal to the height and the pdC to zero since the icon is centered and the width will account for the container padding
  if (hasDropMenu) buttonContainer += " a-btn-drop ";
  if (props.tooltip) buttonContainer += " tooltip-parent ";  // when there is a tooltip
  if (active) buttonContainer += " btn-active ";
  buttonContainer += props.position  ? "" : " p-rel ";
  return buttonContainer;
}
