import {isObj,isString,mediaVal} from '../../../modules.js';

export default function calcFontSize(fSize,out,media,int) {
  // type = null -> var fSiz, 1 f1Siz, 2 -> f2Siz, "var"-> var fsiz + font class
   if (!fSize || !media || !out) {
     return null;
   } else {
     const size = mediaVal(fSize,media);
     if (size === null) return;
     const n = [1,2,3,4,5].includes(int) ? int : "";
     const fontClass = [0,1,2,3,4,5].includes(int)  ? "" : " fs-cl ";

     if (size?.[0]) {
       out.cl += `f${n}-siz-range` + fontClass;
       out.style[`--f${n}Siz`] = isNaN(size[0]) ? size[0] : size[0] + "vw";
       out.style[`--f${n}Max`] = isNaN(size[2]) ? size[2] : size[2] + "px";
       out.style[`--f${n}Min`] = isNaN(size[0]) ? size[1] : size[1] + "px";
     } else {
       out.cl += ` f${n}-siz ` + fontClass;
       out.style[`--f${n}Siz`] = isNaN(size) ? size : size + "px";
     }
   }

}
