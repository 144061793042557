import {styleVClass,isObj} from 'modules';


function calcPadding(pd,out) {
  const pdStyle = (side) => styleVClass(pd[side],out,"pd"+side,"px");
  if (isObj(pd) && !("d" in pd)) {
    if (pd.S) {
    pdStyle("S");
    } else {
      if (pd.T) pdStyle("T");
      if (pd.B) pdStyle("B");
    }
    if (pd.C) {
      pdStyle("C");
    } else {
      if (pd.R) pdStyle("R");
      if (pd.L) pdStyle("L");
    }
  } else {
    styleVClass(pd,out,"pd","px");
  }
}

export default calcPadding;
