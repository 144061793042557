const adjBrdSize = (i) => [0,1,2,3,4,5,6,7,8,9,10].includes(i) ? i : 0;

const int50 = (i) => i>50 ? 50 : i;
const int100 = (i) => i>100 ? 100 : i;
// shift(i,m) i=value, m=divider  Example if values are 0,10,20,30..90  m=10 so if i=62=>result = 60, if i=66=>70 we approximate to the nearest value
const shift = (i,m) =>{const b = i%m; return (b ? (b>m/2?(i+m-b):(i-b)):i)};

const cD = (i) => i>1000 ? 1000 : (i>400 ? shift(i,50) : (i >200 ? shift(i,20) : shift(i,10)));
const sm = (i)=> i>20 ? 20 : i;
const xs = (i)=> i>10 ? 10 : i;

const adjustHeight = (height)=> {
  if (height > 30 && height<= 60) {
    if (height % 2 !== 0) height--;
  } else if (height > 60 && height< 100){
    const rem = height % 5;
    if (rem !== 0) {
      height += (5 - rem);
    }
  }
  return height;
}

const adjustCont = (value)=>{
  let i = value;
  if (i<200 && i>70 ) {
    const rem = i%10;
    if (rem !== 0) {
      i+= (10 - rem);
    }
  } else if (i<=70 && i>40) {
    const rem = i%5;
    if (rem !== 0) {
      i+= (5 - rem);
    }
  }
  return i;
}

const adjustWidth = (value)=>{
  let i = value;
  if (i > 300 ) {
    const rem = i%50;
    if (rem !== 0) {
      i+= (50 - rem);
    }
  } else {
    const rem = i%10;
    if (rem !== 0) {
      i+= (10 - rem);
    }
  }
  return i;
}

const adjBrdRad = (value)=>{
  let i = value;
  if (i >= 50) {
    return 50;
  } else if (i <50) {
    const rem = i%5;
    if (rem !== 0) {
      i+= (5 - rem);
    }
    return i;
  } else {
    return 0;
  }
}

export {adjustHeight, adjustWidth, adjBrdRad, adjBrdSize, adjustCont, shift, cD, int50, int100,sm,xs};
