import {useState, useEffect} from 'react';import './cursor.css';

function Cursor({dragging,hsv,current,color,setDragging,touchHandlers,cursorSize}) {
  const [position,setPosition] = useState({});
  useEffect(()=>setPosition(cursorPosition(current,hsv,cursorSize)),[hsv,current]);

  return (
    <div className="col-color-rect-bl-wh-cursor "  style={{...position,"--cursor":cursorSize+"px"}} {...touchHandlers}  onMouseDown={(e)=>{e.stopPropagation();e.preventDefault();setDragging(true)}}   >
        <div className={"col-color-rect-bl-wh-cursor-el pointer "+(dragging ? "a-bkg-orange":"")} style={{borderColor: color.contrast}}></div>
    </div>
  );
}

export default Cursor;


const cursorPosition = (current,hsv,cursorSize) => {
      const el = current;
      if (el) {
        const rect = el.getBoundingClientRect();
        const x = hsv.s * (rect.right-rect.left) / 100;
        const y =  rect.height - hsv.v * (rect.bottom-rect.top) / 100; // rect.height - value instead of value because we need the distance from the top, while value is calculated from the bottom
        // the cursor has a width and height of 20px, so we have to set the center of the cursor at the position(x,y) so traslating the position (-10,-10) .
        // To avoid the cursor to fall outside the graph's borders instead of traslating -10 we translate from 0(border left and top) to -20 (border right border bottom). We calculate the equation below, in the center of the graph the translationwill be (-10,-10)
        const yAdj =  y * -cursorSize / (rect.height); // y-y1 = (x-x1) (y2-y1)/(x2-x1) x1=0 x2=height y1=0 y2=-20
        const xAdj =  x * -cursorSize / (rect.width);  // y-y1 = (x-x1) (y2-y1)/(x2-x1) x1=0 x2=width  y1=0 y2=-20
        const position = {x:x + xAdj, y:y + yAdj};
        return {top: position.y + "px", left: position.x + "px"};
      }
    };
