import {isObj,colToCss,addPc} from 'modules';


export function calcTextColor(colors,out) {
  // Vars  hex,hex1,hex2,hex3,hsl2
  textColor(colors.b,colors.h,out,"b");
  if (colors.c) {
    const clickColor = {text:colors.c?.text ?? colors.b?.text, brd:colors.c?.brd};
    textColor(clickColor,null,out,"c");
  }
  if (colors.a)  textColor(colors.a,null,out,"a");
}

function textColor(color,hover,out,type) {
  if (hover?.lTxt) {
    out[type] +=  colToCss("hsl2",color.text) + " a-btn-lTxt ";
    out.style[type]["--lTxt"] = addPc(hover.lTxt);
  } else {
    out[type] +=  colToCss("hex",color.text);
    if (hover?.text) out[type] +=  colToCss("hex3",hover.text) + " a-btn-txt-hover ";
  }
  // Border color by default is the same as text color, unless brd prop is set (border size must be set, otherways it will not be displayed default is 0)
  if (color.brd) out[type] +=  colToCss("hex1",color.brd) + " a-btn-brd-color ";
  // brdHover color defaults to brdColor when lTxt tou avoid border following lTxt when not set -> when brd color is set here it should not follow text color also on hover
  if (hover?.brd || (color.lTxt && color.brd)) out[type] +=  colToCss("hex2",hover.brd ?? color.brd) + " a-btn-brd-hover-color ";
}
