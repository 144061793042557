import {useMemo} from 'react';

function Li(props) {
    const {slideIndex,lazyRange,li,bot} = props;
    const show = useMemo(lazyCalc,[lazyRange,slideIndex,bot]); // for bots we set it to true and never change since we disable the use effect


    function lazyCalc() {
        if (bot) { // for bots we leave show to true, lazyrange is ignored
          return true;
        } else if (Array.isArray(lazyRange) && li) {
          if (li.rendered) {
            return (true);
          } else if ((slideIndex >= lazyRange[0]) && (slideIndex <= lazyRange[1])) {
            li.rendered = true;
            return (true);
          } else {
            return (false);
          }
        } else {
          return true;
        }
    }

    const active = false;
    const RowFunction = props.rowFunction?.[0] ?? props.rowFunction;
    const rowFunctionProps = props.rowFunction?.[1] ?? {};
    if (show) {
      return (
          <li id={slideIndex}>
            <RowFunction {...{...li,...props,show,...rowFunctionProps}} />
          </li>

      );
    } else {
      return null;
    }



}
// when lightLaziness prop is true we show all the li element even when show is false, but we pass the show prop to thr row function which will decide what to hide, like images, videos and heavy stuff u til show becomes true. If lightLaziness is false instead when show is false the li element will be empty and no rowfuction will be present at all.


export default Li;
