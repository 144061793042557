import {isObj,colToCss,calcNumClasses,addPc,isNumber,addDeg} from 'modules';

const calcGradientClass = (color,hasTwoColors) => " a-btn-grad-" + (color?.isRadial ? "rad" :"lin") + (!hasTwoColors ? "-"+color.var[0]+"h " :" ");

export function calcGradientBkg(colors,isGradient,hasTwoColors,out) {
  if (isGradient.b) calcGradient(colors.b, hasTwoColors.b, colors.h,out,"b");

  if (isGradient.a) calcGradient(colors.a, hasTwoColors.a,null,out,"a");
  if (isGradient.c) {
    // When color.c is a gradient, if we don't set the c.bkg then it mean we are just altering some parameters of the blur gradient, so we copy the blur bkg and the gradient parameters not set in colors.c, if we insetad set c.bkg then we are setting a whole new gradient so no parameters will be copied from the blur gradient if not defined on click
    const clickColor = colors.c.bkg ? colors.c : {bkg:colors.b.bkg, var:colors.c.var??colors.b.var, direction:colors?.c?.direction??colors.b.direction, stop1:colors?.c?.stop1??colors.b.stop1, stop2:colors?.c.stop2??colors.b.stop2, isRadial:colors?.c.isRadial??colors.b.isRadial};
    calcGradient(clickColor, hasTwoColors.c,null,out,"c");
  }
}

function calcGradient(color,hasTwoColors,hover,out,type) {
  out[type] += calcGradientClass(color,hasTwoColors);
  if (hasTwoColors) {
    out[type] += colToCss("hsl",color.bkg[0]) + colToCss("hsl1",color.bkg[1]);
  } else {
    out[type] += (["r","g","b"].includes(color.var[0])) ? colToCss("rgb1",color.bkg) : colToCss("hsl",color.bkg);
    calcComponent(color,hover,out,type); // Vars: sm, sign,xs9, sign1,
  }
  calcColorStops(color,hover,out,type);   // Vars: sm2,sm3,xs2,xs3
  calcGradientDirection(color,hover,out,type); // Vars: xs10,11
}

function calcComponent(color,hover,out,type) {
  // Vars: sm, sign,xs9, sign1,
  // for saturation and luminosity we allow as variation 0-20, for r,g,b,h 0-160 su fort he latter we use a divident 8 in order to use a var with maximum value of 20
  out.style[type]["--grVar"] = addPc(color.var[1] ?? 0);
  if (isNumber(hover?.var)) {
    out.style[type]["--grVarHov"] = addPc(hover.var);
    out[type] += ["l","s"].includes(color.var[0]) ? " a-ls-gradient " : " a-rgbh-gradient ";
  }
  // If var has a third element and value === true it means the gradient has 3 colos intead of  two so we had a proper css class
  if (color.var.length === 3 && !isNaN(color.var[2])) out[type] += ` grad-${color.var[2]}-col `;

}

function calcColorStops(color,hover,out,type) {
  // Vars: sm2,sm3,xs2,xs3
  out.style[type]["--stop1"] = addPc(color.stop1 ??0);
  out.style[type]["--stop2"] = addPc(color.stop2 ??100);
  if (isNumber(hover?.stop1)) {
    out[type] += " a-btn-stop1-hover ";
    out.style[type]["--grSt1H"] = addPc(hover.stop1);
  }
  if (isNumber(hover?.stop2)) {
    out[type] += " a-btn-stop2-hover ";
    out.style[type]["--grSt2H"] = addPc(hover.stop2);
  }
}

function calcGradientDirection(color,hover,out,type) {
  // Vars: xs10,11
  if (!color.isRadial) {  // Calculate the direction if the gradient is linear
    out.style[type]["--grDir"] = addDeg(color.direction);
    if (hover?.direction) {
      out.style[type]["--grDirH"] = addDeg(hover?.direction);
      out[type] +=  " grad-hover-direction ";
    }
  }
}
