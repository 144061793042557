import {Color} from 'modules';
export const adaptColor = ({row,value},dispatchColor,button) => {
  // adaptColor is called at every settings change, row is the setting that changed and value is its new value;
  // AdaptColor is called to trasnform the button color representation when we change the settings, trying to preserve the color as possible
  if (row === "lBkg") {
    const {b,lH,lC,bkgH} = button.bkg;
    if (!value) { // It means we turned off lBkg and we have to set bkgH
      if (lH) {
        // When we transtion from lH to bkgHov we can convert the same color in the bkgHov representation
        button.bkg = {b,lC,bkgH:Color.hslToHex({h:b[0],s:b[1],l:lH})};
      }
    } else { // It means we turned on lBkg
      // When we transition from bkgHov to LH the color will change unless bkgHov hav the same h and s value of the bkg blur
      const {h,s,l} = (new Color({hex:bkgH})).hsl;
      if (h === b[0] && s === b[1]) {
        button.bkg = {b,lC,lH:l};
      } else {
        button.bkg = {b,lC,lH:b[2]>50?b[2] -10 : b[2] + 10};
      }
    }

  } else if (row === "lTxt") {
    const bkgColor = new Color({hsl:{h:button.bkg.b[0],s:button.bkg.b[1],l:button.bkg.b[2]}});
    const contrast = bkgColor.contrast;
    if (value) {
      const textColor = new Color({hex:button.text.cl});
      const lT = textColor.hsl.l;
      button.text = {lT,ltHov:lT,ltCl:lT};
      button.bkg.b = [textColor.hsl.h,textColor.hsl.s,lT >=50 ? lT-45 : lT+45]
    } else {
      button.text = {cl:contrast,clH:contrast,clC:contrast};
    }
  } else if (row === "lIcon") {
    const bkgColor = new Color({hsl:{h:button.bkg.b[0],s:button.bkg.b[1],l:button.bkg.b[2]}});
    const contrast = bkgColor.contrast;
    if (value) {
      const lIc = contrast === "#000000" ? 100 : 5;
      button.icon = {lIc,lIcH:lIc};
    } else {
      button.icon = {icCl:contrast,icClH:contrast};
    }
  } else if (row === "lArr") {
    const bkgColor = new Color({hsl:{h:button.bkg.b[0],s:button.bkg.b[1],l:button.bkg.b[2]}});
    const contrast = bkgColor.contrast;
    if (value) {
      const lArr = contrast === "#000000" ? 100 : 5;
      button.arrow = {lArr,lArrH:lArr};
    } else {
      button.arrow = {arrCl:contrast,arrClH:contrast};
    }
  }
  dispatchColor({value:"update"});
};
