import {FlexCols,FormInput} from 'modules';

function ComponentAndCssNames({dispatchColor,button}) {
  const sd = {
    size: {font:{i:14,l:12},pd:{c:12}},
    color:{bkg:{b:"dodger blue",f:"green",e:"red,a:1"},
            lBkg:10,
            input: {b:"white"}
          },
    className:"a-flex-col"
  };
  if (!button) {
    return null;
  } else {
    return (
      <FlexCols cols={3} mrR={20} width={600}>
          <FormInput value={button.name} handleChange={(value)=>dispatchColor({row:"name",value})} {...sd}/>
          <FormInput value={button.componentName ?? ""} handleChange={(value)=>dispatchColor({row:"componentName",value})} {...sd}/>
          <FormInput value={button.cssClass ?? ""} handleChange={(value)=>dispatchColor({row:"cssClass",value})} {...sd}/>
      </FlexCols>
    );
  }

}

export default ComponentAndCssNames;
