import {useState, useEffect} from 'react';import InputInner from './InputInner';import {validateError} from "../forms";

function Inputs({handleChange,error, setError,setClick,setFocus,validate,...props}) {



    const handleInput = (e)=> {
        const value = e.target.value;
        if (validate && value && (validate != "password") && (validate != "email")) {
        // We validate the email and passport on Blur and not on cange because the check has to be done when the full input has been enetered
          const valError = validateError(validate, value);
          if (valError) {
              setError(true);
          } else {
              handleChange(value);
              if (error) setError(false);
          }
        } else {
           handleChange(value);
           if (error) setError(false);
      }
    }

      // on Blur we do the validation that is supposed to be done on blur, instead on handleInput we do the validation that should be done every time a single character is typed
    const handleBlur = (e) => {
        setClick(false); setFocus(false);
        if (props.onBlur) props.onBlur(e);
        if (validate && e.target.value && ((validate == "password") || (validate == "email"))) {
            const error = validateError(validate, e.target.value);
            if (error) {
                setError(true);
                if (props.resetChange) props.resetChange(e);
            }
        }
    }

    const handleFocus = (e) => {
       setFocus(true);
       if (props.onFocus) props.onFocus(e);
    }




      return <InputInner {...props}  {...{handleInput,handleFocus,handleBlur}} />;  // we fist call the local handler, if no errors we pass the value to the calling component handler


}

export default Inputs;
