import {DropButton,Button,Div} from '../../modules';

function Row(props) {
  const className = (props.className ?? "") + (props.center ? " no-phone " : "");
  if ((props.admin && !props.adminLogged) || ((props.user === true) && !props.userLogged ) || ((props.user === "no") && props.userLogged )) {
    return null;
  } else if (props.dropMenu) {
    return <DropButton {...props} className={className}/>;
  } else {
    return <Button {...props} className={className}/>;
  }

}

export default Row;
