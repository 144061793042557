import {useState,useEffect,useMemo} from 'react';
import {D,FlexCols,DivSelect,Pickers,Color,isObj} from 'modules';

function FormPickers({colors,dispatchColor}) {
  const [row,setRow] = useState("input");
  const [formState,setFormState] = useState("blur");
  const [col,setCol] = useState("bkg");
  const [cols,setCols] = useState(["bkg","cl"]);
  useEffect(()=>recalcCols(row,formState,setCols,setCol),[row,formState]);

  const sd = {
    size: {font:{i:14,l:12},pd:{c:12}},
    color:{bkg:{b:"dodger blue",f:"green",e:"red,a:1"},
            lBkg:10,
            input: {b:"white"}
          },
    className:"a-flex-col"
  };
  const pick = useMemo(()=>calcPickerSettlers(row,formState,col,colors,dispatchColor),[col,colors,cols]);

  return (
    <D>
        <FlexCols cols={3} mrR={15}>
            <DivSelect options={[["Input","input"],["Label","label"],["Cov. Label","covLabel"],["Brd","brd"],["Bar","bar"],["Icon","icon"],["Arrow","arrow"]]} value={row} {...sd} handleChange={setRow}/>
            <DivSelect options={["blur","hover","focus","error","errorHov"]} value={formState} {...sd} handleChange={setFormState}/>
            <DivSelect options={cols} value={col} {...sd} handleChange={setCol}/>
        </FlexCols >
        <Pickers {...pick} position="relative"/>
    </D>

  );
}

export default FormPickers;

function recalcCols(row,formState,setCols,setCol,col) {
  if (row === "input") {
    switch (formState) {
      case "blur": setCols(["bkg","cl"]); setCol("bkg"); break;
      case "hover": setCols(["lBkg","cl"]);setCol("cl"); break;
      case "focus": setCols(["bkg","cl"]);setCol("bkg"); break;
      case "error": setCols(["bkg","cl"]);setCol("bkg"); break;
      case "errorHov": setCols(["lBkg","cl"]);setCol("cl"); break;
    }
  } else {
    setCols(["cl"]);setCol("cl");
  }
}

function calcPickerSettlers(row,state,col,colors,dispatchColor) {
  if (!isObj(colors)) return {};
  let out = {};
  if (col !== "lBkg") {
    out.color = colors[row][state][col];
    if (!(out.color instanceof Color)) out.color = new Color(out.color);
    out.setColor = (value) => dispatchColor({col,row,state,value});
  } else {
    const {h,s,l} = colors[row][(state === "hover") ? "blur" : "error"].bkg.hsl;
    console.log(h+"-"+s)
    out.editComponent = {type:"l",lRef:l,header:"lBkg"};
    out.color = new Color({hsl:{h,s,l:colors[row][state][col]}});
    out.setColor = (color) => dispatchColor({col,row,state,value:color.hsl.l});
  }
  return out;
}
