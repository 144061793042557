import React, {useContext} from 'react';
import {Div,Shared} from '../modules'; import './topBar.css';import {Side} from './modules/Side';
import Center from './modules/Center';

export function TopBar(props) {
  const {media,currentPage,topBar} = useContext(Shared);

  const {shared,leftButtons,rightButtons,centerButtons} = topBar;
  if (!leftButtons) {
    return null;
  } else {
    const {isLandscape,isMobile} = media;
    const position = (props.position === "fixed" && !(isLandscape && isMobile)) ?  "p-fixed " : ((props.position === "absolute"  && !(isLandscape && isMobile)) ? "p-abs " : "p-rel ");
    const topPadding = (["absolute","fixed"].includes(props.position) && !(isLandscape && isMobile) && !props.noPadding)  ? "top-bar-padding " : "";
    const zIndex = " z-"+ (["absolute","fixed"].includes(props.position) ? (props.z ?? 100) : 10);
    return (
      <div className={zIndex}>
          <div className={topPadding}/>
          <Div colors={{bkg:props.bkg,a:props.a}}  iClass="flex-cen-cen "   oClass={shared.btnSize?.topBar + " top-bar "+position +zIndex}>
            <Side left={true} shared={shared} rows={leftButtons} key="left"/>
            <Center shared={shared} rows={centerButtons}/>
            <Side shared={shared}  rows={rightButtons} key="right"/>
          </Div>
      </div>
    );
  }

}
