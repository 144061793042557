import {Pickers,Color} from 'modules';

function BkgPickers({activePicker,dispatchColor,button,settings,lRef}) {
  const isBkgHover = (activePicker === "Hover" && !settings.lBkg);
  if (["Active","Blur"].includes(activePicker) || isBkgHover) {
    const {color,setColor} = bkgSettlers(button,activePicker,dispatchColor);
    return  <Pickers {...{color,setColor,position:"relative",smallHexMenu:true}}/>
  } else if (activePicker === "Hover" && settings.lBkg) {
    const color = new Color({hsl:{h:button.bkg.b[0],s:button.bkg.b[1],l:button.bkg.lH}});
    const setColor = (color) => dispatchColor({row:"bkg",col:"lH",value:color.hsl.l});
    return <Pickers {...{color,setColor,position:"relative",editComponent:{type:"l",lRef,header:"lH"}}}/>;
  } else if (activePicker === "Click") {
    const color = new Color({hsl:{h:button.bkg.b[0],s:button.bkg.b[1],l:button.bkg.lC}});
    const setColor = (color) => dispatchColor({row:"bkg",col:"lC",value:color.hsl.l});
    return <Pickers {...{color,setColor,position:"relative",editComponent:{type:"l",lRef,header:"lC"}}}/>;
  } else {
    return null;
  }
}



function bkgSettlers(button,activePicker,dispatchColor) {
  let color,setColor;
  const {b} = button.bkg;
  if (activePicker === "Blur" ) {
    color = new Color({hsl:{h:b[0],s:b[1],l:b[2]}});
    setColor = (color) => dispatchColor({row:"bkg",col:"b",value:[color.hsl.h,color.hsl.s,color.hsl.l]});
  } else if (activePicker === "Hover") {
    color = new Color({hex:button.bkg.bkgH});
    setColor = (color) => dispatchColor({row:"bkg",col:"bkgH",value:color.hex});
  } else if (activePicker === "Active") {
    color = button.bkg.a ? new Color({hex:button.bkg.a}) : new Color({hsl:{h:b[0],s:b[1],l:b[2]}});
    setColor = (color) => dispatchColor({row:"bkg",col:"a",value:color.hex});
  }
  return {color,setColor};
}

export default BkgPickers;
