import {useContext} from 'react'; import {D,Shared} from 'modules';

function Hex({color,editColor,setEditMode,editComponent,smallHexMenu,showHsv,setShowHsv}) {
  const {media} = useContext(Shared);
  const hex = color.hexToCss;
  if (editComponent)  {
    return null;
  } else if (smallHexMenu && media.isMobile) {
    return (
      <D a={{he:30}} cl=" w100 p-sticky z-30 w-600 a-bkg-white top-0"  > {/* White background to avoid opacity < 1 resulting in showing content behind */}
          <D cl="w100 h100 flex-cen-btw" a={{pd:10,fSize:16}} style={{backgroundColor:hex,color:color.contrast,"--tx":"10px","--ty":"10px"}}>
              <div>{hex}</div>
              <div className="a-font-14 pointer " onClick={()=>setShowHsv(!showHsv)}>{showHsv ? "RGB - HSL" : "HSV"}</div>
              <div className="a-font-14 pointer " onClick={()=>setEditMode(!editColor)}>{editColor ? "Pick a color" : "Edit color"}</div>
          </D>
      </D>

    );
  } else {
    return (
      <D a={{he:100}} cl=" w100 p-sticky z-30 w-600 a-bkg-white top-0"  > {/* White background to avoid opacity < 1 resulting in showing content behind */}
          <D cl="w100 h100 flex-cen-cen" a={{pd:20,fSize:20}} style={{backgroundColor:hex,color:color.contrast,"--tx":"10px","--ty":"10px"}}>
              {hex}
              <div className="p-in-corner-1 p-abs a-font-13 ">{color.rgbToCss}</div>
              <div className="p-in-corner-2 p-abs a-font-13">{color.hslToCss}</div>
              <div className="p-in-corner-4 p-abs a-font-14">{color.hsvToCss}</div>
              <div className="p-in-corner-3 p-abs a-font-14 pointer " onClick={()=>setEditMode(!editColor)}>{editColor ? "Pick a color" : "Edit color"}</div>
          </D>
      </D>

    );
  }

}

export default Hex;
