import { useMediaQuery } from 'react-responsive'; import './tooltip.css';
import {mediaVal} from '../../helpers.js'; import {calcPaddingCont,calcMarginCont} from '../Calc/calcDiv.js';

/*  <div className=""></div>  */

function Tooltip(props) {
  const {bkg} = props;
  /******* Media Queries ****/
  let media = {};
  media.isPortrait =  useMediaQuery({ query: '(orientation: portrait)' });
  media.isLandscape =  useMediaQuery({ query: '(orientation: landscape)' });
  media.isMobile = useMediaQuery({ maxWidth: 734 });
  media.isTablet = useMediaQuery({ maxWidth: 1200, minWidth: 735 });
  media.max1420 = useMediaQuery({ maxWidth: 1420, minWidth: 736 });
  /******* Media Queries ****/

  if (!props.text) return null;

  const onHover = mediaVal(props.onHover, media);
  const onFocus = mediaVal(props.onFocus, media);
  if ((!onHover && !onFocus) ) { // show only if the tooltip has been set to be shown onHover or onFocus
    return null
  } else {
    let {position,margin,colors = {}, pd,height,minHeight,maxHeight,iClass,width,maxWidth,brd = {},shadow} = props;
    const pdCont = {iClass};
    const mrCont = {height,minHeight,maxHeight,brd};
    pdCont.colors = colors?.bkg ? colors : {...colors,bkg:"black",text:"white"};
    pdCont.pd = pd ?? {s:{l:6,s:4},c:{l:14,s:10}};
    mrCont.width = width ?? "90%";
    mrCont.maxWidth = maxWidth ?? 200;
    mrCont.shadow = shadow ?? true;
    mrCont.position = ["over",position ?? "top",margin ?? 6,null,true];
    mrCont.oClass = (onFocus ? " tooltip-focus " : "" ) + (onHover ?  " tooltip-hover " : "") + " w3-animate-opacity " + (props.zI ?? "zI-100 ");
    mrCont.fontSize = props.fontSize ?? {l:11,m:11,s:11};
    if (!mrCont.brd.rad) mrCont.brd.rad = 10;

    return (
      <div className={calcMarginCont(mrCont)}> {/*tooltip-hover/focus css classes show hide the tooltip not JS*/}
          <div className={calcPaddingCont(pdCont)}>
              {props.text}
          </div>
      </div>
    );
  }

}

/*
<Tooltip 
  ***  Specific props,  different than calcDiv props
  text = toString
  onHover or onFocus -> bol set only one of tbhis to true to set if it should open on the parent hover or on focus (only for forms)
  position = string -> "t||top,r||right,b||bottom,l||left
  margin -> intVal -> only one margin value, according to the position the right margin will be used, for example with pos top we set the margin bottom
    /** same props as calcDiv
  colors -> default bkg black and color white
  pd default {s:{l:6,s:4},c:{l:14,s:10}}
  width default 90%
  maxWidth default 200
  shadow -> default true
  fontSize -> default 11
  brd -> if not set brdRad will be set to 10
  zI -> zIndex default 100  not all values can be set, check the css of the zI class
  height,minHeight,maxHeight
/>
*/

export default Tooltip;
