import {FlexCols} from 'modules';import FormContainer from '../../FormContainer';

function Test({a,colors,edit}) {
const noSettler = {setFocus:()=>null,setError: ()=>null,handleChange:()=>null,cl:"a-flex-col bar-left",error:false,focus:false,colors}
const settlers = {};
settlers.hover = {value:"Hover", ...noSettler};
settlers.focus = {value:"Focus",...noSettler,focus:true,};
settlers.error = {value:"Focus",...noSettler,error:true,};
settlers.errorHov = {value:"Focus",...noSettler,error:true,cl:"a-flex-col hover bar-left"};
  return (
    <FlexCols s={{wd:"57%"}} cols={5} mrR={10}>
        <FormContainer {...a} s={{fSize:20}} label = "prova" colors={colors} key="blur"/>
        <FormContainer  {...settlers.hover} s={{fSize:20}} label = "prova"  cl=" a-flex-col hover bar-left " key="hover"/>
        <FormContainer {...settlers.focus} s={{fSize:20}} label = "prova" key="focus"/>
        <FormContainer  {...settlers.error} s={{fSize:20}} label = "Error"  key="error"/>
        <FormContainer  {...settlers.errorHov}  s={{fSize:20}} label = "Error Hover" key="error_hover"/>
    </FlexCols>
  );

}

export default Test;
