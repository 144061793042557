import {calcDropSizeCss} from './dropContainerHelpers/dropSizeCss/dropSizeCss'; import {calcDropDownColors} from './dropContainerHelpers/dropColors/dropColors';
import {calcDropArrowTipAndTrans} from './dropContainerHelpers/calcDropArrowTipAndTrans';

 export function calcDropContainers(dropSize,dropColors,className) {
    const out = {style:"",dropContainer:"",multiDropContainer:"",buttonContainer:"",rowContainer:"",rowIconContainer:"", colorsStyle:{}}
     calcDropSizeCss(dropSize,className,out);
     calcDropArrowTipAndTrans(dropSize,className,out);
     calcDropDownColors(dropColors,out);
     return out;
   }
