import {calcCss,isObj,styleP,styleV,calcFont} from 'modules';
import calcPd from './pd';import calcMr from './mr'; import calcBrd from './brd'; import calcPosition from './position';

export class Calc {
  static pd = (val,out) => calcPd(val,out);
  static mr = (val,out) => calcMr(val,out);
  static wd = (val,out,propertyName) => styleP(val,out,propertyName ?? "width","px");
  static maxW = (val,out) => styleP(val,out,"max-width","px");
  static minW = (val,out) => styleP(val,out,"min-width","px");
  static he = (val,out,propertyName) => styleP(val,out,propertyName ?? "height","px");
  static maxH = (val,out) => styleP(val,out,"max-height","px");
  static minH = (val,out) => styleP(val,out,"min-height","px");
  static fSize = (val,out,propertyName) => calcFont(val,out,propertyName ?? "font-size");
  // For Line Height and Font Weight we calculate the class if we have a single value, if we have instead an object for multi display we add the values to the style sheet
  static we = (val,out) => isObj(val) ? styleP(val,out,"font-weight") : calcWeight(val,out);
  static lh = (val,out) => (isObj(val) || ![1.1,1.2,1.3,1.4,1.5,1.6,1.7,1.8].includes(val)) ? styleP(val,out,"line-height") : calcLineHeight(val,out);
  static brd = (val,out) => calcBrd(val,out);
  static pos = (val,out) => calcPosition(val,out);
  static all = (val,cssCLassIn) => {
    const out = {cl:"", style:{d:"",xt:"",t:"",mp:"",ml:"",xm:""}};
      if (isObj(val)) {
        for (let key in val) {
            Calc[key](val[key],out);
        }
      }
      const cssClass = cssCLassIn ??  ("a"+Math.round(Math.random() * 10000000));
      return [out.cl+" "+cssClass,calcCss(out.style,cssClass)];
  }
}


const calcLineHeight = (lh,out) => out.cl += ` lh-${lh.toString().replace(".","")} `;
const calcWeight = (weight,out) => out.cl += ((weight && [100,200,300,400,500,600,700].includes(weight)) ? ` w-${weight} ` : " ");
