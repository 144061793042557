import React, {useEffect} from 'react';
import {validateError} from "../forms";import {isObj,def} from "../../modules";


/* <form action="/CI/log/in" method="post" onSubmit={this.handleSubmit}>
<FormInput name="email" type=text_Default value={this.state.email} label="E-Mail" centerLabel=false_default labelColor=blue_dedault
handleChange={this.handleChange} resetChange={this.resetChange} color="w3-light-gray" validate="email" alertHandler={this.alertHandler}
maxWidth=notComp grid=notComp ref={this.emailRef}/>
*/
function InputInner(props) {
    const {error, setError, focus, setFocus, clicked, setClick} = props.state;
    const {validate,name, value, inputContainer} = props;
    const myRef = React.createRef();

    // If proppped focus is true activate focus on the input using attached ref
    useEffect(()=>{if (focus && myRef.current) myRef.current.focus();},[focus]);

    const handleBlur = (e) => {
        setClick(false); setFocus(false);
        if (props.onBlur) props.blur(e);
        if (validate && e.target.value && ((validate == "password") || (validate == "email"))) {
            const error = validateError(validate, e.target.value);
            if (error) {
                setError(true);
                //this.props.alertHandler(error, this.props.name);
                if (props.resetChange) props.resetChange(e);
            }
        }

    }

    const handleFocus = (e) => {
       setFocus(true);
       if (props.onFocus) props.onFocus(e);
    }

    const type = props.type ? props.type : "text";

    return (
            <input type={type} name={name} value={value} onChange={props.handleChange}  onBlur={handleBlur} onFocus={handleFocus}
            className={"form-input-inner "+inputContainer}  ref={myRef}  />
    );



}

export default InputInner;



/*
<FormInput name="" value="" {...options} {...formContOptions} handleChange={handleBlur}/>

const formContOptions = { // All these options have defs so are notcompulsory
    defs = "form-defs"; // CSS vars definiting size and style
    defColors = "form-def-col"; CSS vars definiting the colors
    label = ""; // if not set no label is displayed and the top padding is not added to the input
    covLabel = ""; if not set takes the value from label if set otwerwise is null
    noPointer = false; // if set to true cursor is not set as noPointer
    className = ""; // classname of the outermost container can be used to set the grid
    margin = " w3-section "; // vertical margins. Put the margin here and not in className to ovveride def margin w3-section
    maxWidth = ""; // value in px of the inner input, doesn't include padding added with className because it's external
    required = false; if set to true there will be a required sticker until the user starts digit
};

const options = {
  type: "text"; // default
  validta:
}


*/
