import React, {useState, useEffect} from 'react';
import SelectInner from '../DivSelect/SelectInner'; import InputInner from '../Inners/InputInner';import DateInner from '../Inners/DateInner';
import TextAreaInner from '../Inners/TextAreaInner';
import {validateError} from "../forms";import {calcTextColor} from "./calc/calculators.js";
import {calcInputContainer} from "./calc/calcInputSize.js";

function Inputs(props) {
    const [input, setInput] = useState(null);
    const [textColor, setTextColor] = useState("");

    const {state, validate,labelPosition, size, color, label,inputPadding,media} = props;
    const {error, setError, focus, setFocus, clicked, setClick} = state;
    useEffect(()=> setTextColor(calcTextColor(color.input)),[]);
    useEffect(()=> setInput(calcInputContainer(size)),[inputPadding]);
    if (!props.showInner) return null;
    let a = {};
    // Input Container Vars: hex,hex1,hex2,hex4, b,e
    a.inputContainer = "frm-input input-color flex-cen-start grow-1 ";
    if (props.formType !== "text") a.inputContainer += " truncate ";
    a.btnContainer =  !input ? "" : input.shared + (focus ? input.focus : input.blur)  // (Vars: m,t)  this is the container of the Input button but it's passed also to the drop down (for a div select) to be used as a dropRow container as the default value if no value has been set in props
    a.inputContainer += a.btnContainer;  // no vars added
    a.inputContainer += textColor; // Vars: hex,hex1,hex2,hex4


    const handleChange = (e)=> {
        const value = e.target.value;
        //this.setState({color: "frm-focus-color", clicked: true});
        if (validate && value && (validate != "password") && (validate != "email")) {
        // We validate the email and passport on Blur and not on cange because the check has to be done when the full input has been enetered
            const valError = validateError(validate, value);
            if (valError) {
                setError(true);
            } else {
                props.handleChange(value);
                if (error) setError(false);
            }
        } else {
             props.handleChange(value);
             if (error) setError(false);
        }
    }

    switch (props.formType) {
        case "select": return <SelectInner {...props}  state={state} {...a} />;  // No need for local handler since it's a options select we pass the result to the calling component handler
          break;
        case "input": return <InputInner {...props}  state={state} {...a} handleChange={handleChange} />;  // we fist call the local handler, if no errors we pass the value to the calling component handler
            break;
        case "date": return <DateInner {...props}  state={state} {...a} handleChange={handleChange} />;
            break;
        case "textArea": return <TextAreaInner {...props}  state={state} {...a} handleChange={handleChange} />;
          break;
        default: return null;

    }

}

export default Inputs;
