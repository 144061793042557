import {colToCss,isObj} from '../modules';


function calcColorClass(props) {
    const {colors,color,colorClass,colorsClass} = props;
    if (color) { // color is an inline style so we don't add any class
      return null;
    } else if (colorClass) { // color is added through a propped class so we just add the propped class and not the color which should have been already set in that class
      return colorClass;
    } else if (isObj(colors)){
      let out = {};
      if (colors.clicked) {
        out.clicked = " an-icon-color " + colToCss("hex",colors.clicked); // when clicked we change the color to the clicked value and remove the hover class because we don't want hover color to replace the click color
      }
      out.blur =  " an-icon-color " + colToCss("hex",colors.blur);
      if (colors.hover) out.blur += " an-icon-hover-color " + colToCss("hex1", colors.hover);
      return out;
    } else {
      return null;
    }
}

const calcSvgColors = (props) => calcColorClass("colors" in props ? props : {colors:props});

export {calcColorClass,calcSvgColors}
