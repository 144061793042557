import DropLi from './DropLi/DropLi';import {SvgIcon} from 'modules'; import './sectionButtons.css';

export function SectionButtons({expandable,isOpen,buttons,expanderPd,multiDrop}) {
  // Removed id="drop-section-buttons" from outer most div, I think it's a mistake, there is not need
    let sectionContainer = {};
    if (expandable) {
      sectionContainer.className = "drop-section-buttons ";
      sectionContainer.className += isOpen ? "" : " drop-section-buttons-closed ";
      sectionContainer.className +=  expanderPd ?? "";
    }
    if (buttons.length <= 15) {
      sectionContainer.className += " drop-btns-"+buttons.length +" ";
    } else {
      sectionContainer.style = {"--nBtns":buttons.length};
    }


    return (
      <div className="p-rel z-1 w100 drop-buttons-outer">
        <SvgIcon icon="drop_up_tip"  sizeClass="a-multi-drop-tip"  noShow={!isOpen} colorClass="a-multi-drop-tip-color" />
        <div dataType="drop-buttons" {...sectionContainer} >
            {buttons.map((li, index) => <DropLi {...li} multiDrop={multiDrop} key={index} />)}
        </div>
      </div>
    );
}
