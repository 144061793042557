import React, {useState,useEffect,useRef,forwardRef,useMemo} from 'react';import {calcSlides} from './calc/slides'; import Li from './Li';
import {calcNumClasses,isObj,mediaVal} from '../modules';

const Inner = forwardRef((props,ref) =>  {
    const {index,cols,rows,animate,bot,animationEnded,direction,media,mr} = props;
    // Problem, we have to remove the animate dependency because we are recalculating slides when the animation is removed, we have to separate the animation from the direction next -prev

    const slides = useMemo(()=>calcSlides(index, props.circle, rows, cols,direction,props.lastAinmationCols,props.lastIndex,props.lazy),[index, props.circle, rows, cols,direction,props.lastAinmationCols,props.lastIndex,props.lazy]);


    const animation = animate ? "ul-sld-anim-"+animate : "";
    const [margin,setMargin] = useState(0);
    useEffect(calcMargin,[mr,media]); // we need an effect because the ref need a first render


    function calcMargin() {
      if (isObj(mr) && (mr.px)) {
        setMargin(mediaVal(mr,media));
      } else if (mr) {
        const mar = Math.round(ref.current.offsetWidth * mediaVal(mr,media) / 100);
        setMargin(!(isNaN(mar)) ? mar : 0);
      }
    }

    const animationDuration = props.animationDuration ? {animationDuration: props.animationDuration +"ms"} : {};
    const slidesProps = {slides,animation,animationDuration,bot,animationEnded};
    const style = {"--liMr":margin+"px","--fPos":slides?.translation,"--length":slides?.len};
    const innerClass = "ul-sld-cont p-rel z-2 " + (props.outerContainer ?? "") + (!props.showOverflow ? " ov-hid" : "");


    return (
      <div className={innerClass} style={style}>
              <div className="w100" ref={ref}> </div>
              <Slides {...slidesProps}  lightLaziness={props.lightLaziness} inViewPort={props.inViewPort} rowFunction={props.rowFunction} index={props.index}/>
      </div>
    );

});

function Slides(props) {
    const {slides,animation,animationDuration,lazyRanges} = props;

    return (
      <ul className={"ul-sld-ul ul-clear-inline " + animation} style={animationDuration} onAnimationEnd={props.animationEnded}>
          {slides.array.map((li, key) => <Li li={li} key={li.id} slideIndex={key} bot={props.bot} lazyRange={slides.lazyRange} lightLaziness={props.lightLaziness} inViewPort={props.inViewPort} rowFunction={props.rowFunction} index={props.index}/>)}
      </ul>
    );

}

export default Inner;
