import {useState,useRef,useEffect,useCallback,useContext,useMemo} from 'react';import "./css/button.css"; import './css/fillings.css';
 import {calcColors} from './calc/colors'; import ButtonDisplay from './Display/ButtonDisplay';import {BtnContext} from '../';
import {isObj,Link,mediaVal, Tooltip,Shared,Cal,Style} from 'modules'; import {setClickAndHref,calcbuttonContainer} from './calc/calcClickHrefandBtnContainers';


// <div className=""></div>

export function BtnInner({text,handler,setDropDownClick,dropDownClicked,icon,hasDropMenu,dropMenu,btnType,active,meta,...props}) {

    const {media,bot,adminLogged,userLogged} = useContext(Shared);
    const propppedContext = isObj(props.context) ? props.context : {};
    const clickTimer = useRef(0);
    const [btnClicked, setBtnClicked] = useState(false); // used in click animation, every time a button is clicked btnclicked becomes true until the animation ends than becomes false until nmext click
    const dropArrowRef = useRef(null);
    const startAnimation = () => { if (true)  { setBtnClicked(true);if (clickTimer.current) clearTimeout(clickTimer.current);clickTimer.current = setTimeout(()=>setBtnClicked(false), props.animate?500:200);  }}
    const {click, href} = useMemo(()=>setClickAndHref(active,bot,startAnimation,handler,meta,props),[active,bot,startAnimation,handler,meta,props]);
    const context = {media,...propppedContext,bot,adminLogged,userLogged,handler};
    const buttonContainer = useMemo(()=>calcbuttonContainer(btnClicked,props,click,href,active,icon,text,hasDropMenu),[btnClicked,props,click,href,active,icon,text,hasDropMenu]);
    const iconTextProps = {icon,rightIcon:props.rightIcon && !hasDropMenu}; // When there is a dropMenu we ignore the right icon prop and positiuoin the icon to thge left
    const shared = {bot,media, handling:{adminLogged,userLogged,handler}};


    if ((!text && !icon) || mediaVal(props.noShow,media)) {
        return null;
    } else {
        const display = {iconTextProps,text,noArrow:context.dropMenu?.noArrow,dropDownClicked,hasDropMenu,click,arrowAnimation:context.dropMenu?.arrowAnimation};
        return (
              <BtnContext.Provider value={context}>
                  <Link className={` a-btn btn-color noselect flex-cen-cen   ${props.className ?? ""} ${buttonContainer} `} type={btnType ?? "button"}href={href} noFollow={props.noFollow} noPointer={true} newWindow={props.newWindow}  style={props.style} click={click}>
                      <Style sheet={props.styleSheet} />
                      <ButtonDisplay {...display} ref={dropArrowRef}/>
                      <Render render={props.render} a={{dropArrowRef}} />
                      <Tooltip {...props.tooltip} onHover={true}/>
                  </Link>
              </BtnContext.Provider>
        );
  }
}

const Render = ({render,a}) => !render ? null : render?.[0]??render({...(render?.[1]??{}),...a});
