import {Color,inProduction} from 'modules';



function colorsReducer(colors,{row,state,col,value,index,add,fetched}) {
  if (fetched) {
    return fetched;
  } else if (col && row && state) {
    colors[index][row][state][col] = value;
    colors[index] = {...colors[index]};
  } else if (add) {
    colors.push(JSON.parse(JSON.stringify(demoColor)));
  }
  return [...colors];
}

const demoColor = {
  input:{
  blur:{bkg:new Color({hsl:{h:67,s:70,l:30}}),cl:new Color({hex:"#DC143C"})},
  hover:{lBkg:35,cl:new Color()},
  focus:{bkg:new Color({hsl:{h:97,s:70,l:30}}),cl:new Color({hex:"#ffffff"})},
  error: {bkg:new Color({hsl:{h:27,s:70,l:30}}),cl:new Color({hex:"#DC143C"})},
  errorHov:{lBkg:50,cl:new Color()}
  },
  label: {
    blur:{cl:new Color({hex:"#ffffff"})},
    hover:{cl:new Color({hex:"#444444"})},
    focus:{cl:new Color({hex:"#bbbbbb"})},
    error:{cl:new Color({hex:"#bbbbbb"})},
    errorHov:{cl:new Color({hex:"#bbbbbb"})},
  },brd: {
    blur:{cl:new Color({hex:"#ffffff"})},
    hover:{cl:new Color({hex:"#444444"})},
    focus:{cl:new Color({hex:"#bbbbbb"})},
    error:{cl:new Color({hex:"#bbbbbb"})},
    errorHov:{cl:new Color({hex:"#bbbbbb"})},
  },bar: {
    blur:{cl:new Color({hex:"#ffffff"})},
    hover:{cl:new Color({hex:"#444444"})},
    focus:{cl:new Color({hex:"#bbbbbb"})},
    error:{cl:new Color({hex:"#bbbbbb"})},
    errorHov:{cl:new Color({hex:"#bbbbbb"})},
  },icon: {
    blur:{cl:new Color({hex:"#ffffff"})},
    hover:{cl:new Color({hex:"#444444"})},
    focus:{cl:new Color({hex:"#bbbbbb"})},
    error:{cl:new Color({hex:"#bbbbbb"})},
    errorHov:{cl:new Color({hex:"#bbbbbb"})},
  },arrow: {
    blur:{cl:new Color({hex:"#ffffff"})},
    hover:{cl:new Color({hex:"#444444"})},
    focus:{cl:new Color({hex:"#bbbbbb"})},
    error:{cl:new Color({hex:"#bbbbbb"})},
    errorHov:{cl:new Color({hex:"#bbbbbb"})},
  }
}

export {colorsReducer};
