import calcPd from './functions/pd';import calcMr from './functions/mr'; import calcFontSize from './functions/fontSize';import {calcStyle} from './helpers';
import calcBrd from './functions/brd'; import calcBoxShadow from './functions/boxShadow'; import calcPosition from './functions/position'; import calcColors from './functions/colors';
import {isObj,mediaVal} from '../../modules';

class Cal {
  static a = (opacity,media,int="") => { // use int for additional a variables int=1 a1-, int=2 a2-... int should be null for the standard a-
      const a = mediaVal(opacity,media);
      return (Array.isArray(a) ?  (` a-${(a[0] ? a[0].toString().replace(".",""):"1")} ` + (a[1] ? "a-h-" + a[1].toString().replace(".","") : "") ) :  (` a${int}-${(a>=0 ? a.toString().replace(".",""):"1")} `)
    )};
  static pd = (pd,out,media) => calcPd(pd,out,media);
  static mr = (mr,out,media) => calcMr(mr,out,media);
  static wd = (width,out,media) => calcStyle(width,out,"wd",media);
  static maxWd = (width,out,media) => calcStyle(width,out,"maWd",media);
  static minWd = (width,out,media) => calcStyle(width,out,"miWd",media);
  static he = (height,out,media) => calcStyle(height,out,"he",media);
  static maxHe = (height,out,media) => calcStyle(height,out,"maHe",media);
  static minHei = (height,out,media) => calcStyle(height,out,"miHe",media);
  static fSize = (fSize,out,media,int) => calcFontSize(fSize,out,media,int);
  static brd = (val,out,media) => calcBrd(val,out,media);
  static sha = (val,out,media) => calcBoxShadow(val,out,media);
  static pos = (val,out,media) => calcPosition(val,out,media);
  static colors = (val,out,media) => calcColors(val,out,media);
  static lh = (lh,media) =>  ` lh-${mediaVal(lh,media).toString().replace(".","")} `;
  static we = (weight,media) => (weight && [100,200,300,400,500,600,700].includes(mediaVal(weight,media))) ? ` w-${mediaVal(weight,media)} ` : " ";
  static all = (val,out,media) => {
      if (isObj(val)) {
        for (let key in val) {
          Cal[key](val[key],out,media);
        }
      }
  }
  static allCss = (val) => {
    const out = {cl:"",style:{}};
    const media = ["d","xt","t","mp","ml","xm"];
      if (isObj(val)) {
        for (let key in val) {
          for (let i=0;i<media.length;i++) {
            Cal[key](val[key],out,media[i]);
          }

        }
      }
  }
  static allObj = (val,media) => {
    const out = {cl:"",style:{}};
    if (isObj(val)) {
      for (let key in val) {
         Cal[key](val[key],out,media);
      }
    }
    return out;
  }
}

export {Cal};

// val = {pd,mr,wd,minWd,maxWd,he,maxHe,minHe,fSize,brd,sha,pos,colors}
