
import {SvgIcon} from 'modules';import DropArrow from 'Buttons/Button/Display/DropArrow/DropArrow';

export const Title = ({icon,title,multiDrop,dropContainers,expandable,toggle,isOpen}) => {
  if (!(title && multiDrop)) {
    return null;
  } else {
    return (

      <h3 className={"multi-drop-header flex-cen-start "+(expandable ? " multi-drop-expandable ": "")} onClick={expandable?toggle:null}>
          <SvgIcon icon={icon} sizeClass="multi-drop-header-icon-size" colorClass="multi-drop-header-icon-color no-shrink"/>
          <div className={" grow-1 multi-drop-header-title "+(!icon ?" m-drop-henoIc ":"")} >
              <div className="w100 truncate">{title}</div>
          </div>
          <DropArrow  container={dropContainers.expArrowContainer + " no-shrink"} expArrow={true}  sizeClass={dropContainers?.expArrowSizeClass} clicked={isOpen}  noShow={!dropContainers?.expArrowSizeClass || !expandable}/>
      </h3>

    );
  }
}
