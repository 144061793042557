import {calcNumClasses,isObj,mediaVal} from '../../../modules';
import {calcStyle} from '../helpers';
export default function calcPosition(pos,out,media) {
    if (pos?.t || pos?.b) {
      out.cl += " p-";
      if ("t" in pos) {
        out.cl += "top";
        calcStyle(pos.t,out,"",media,"pTo");
      } else {
        out.cl += "bottom";
        calcStyle(pos.b,out,"",media,"pBo");
      }
      if ("l" in pos) {
        out.cl +=  "-left";
        calcStyle(pos.l,out,"",media,"pLe");
      } else {
        out.cl +=  "-right";
        calcStyle(pos.r,out,"",media,"pRi");
      }
      out.cl += (pos.type === "fixed"  ? " p-fixed  " : (pos.type === "sticky" ? " p-sticky ":" p-abs "));
      // When we set the t,b,r,l position we can then add the 50% self adjustment trasnlation by adding center = true, the tranformation will be done according to the position we set
      if (pos.center) {
        if (("t" in pos) && ("l" in pos)) {  // if we positioned the eleent o top and left we need to self center with -50%,-50%
          out.cl += " center-tl ";
        } else if (("t" in pos) && ("r" in pos)) { // in this case since we defined the right position instead of the left we will add a translation 50%,-50%
          out.cl += " center-tr ";
        } else if (("b" in pos) && ("l" in pos)) {
          out.cl += " center-bl ";
        } else if (("b" in pos) && ("r" in pos)) {
          out.cl += " center-br ";
        }
      }
      return ;
    } else if (Array.isArray(pos)) {
      var [translation,position,margin,type] = pos;
      out.cl += " p-";
    } else if (isObj(pos)) {
      var {translation,position,margin,type} = pos;
      out.cl += " p-";
    } else {
      out.cl += " ";
      return ;
    }

    if (!["over","in","btw"].includes(translation)) translation="in";
    out.cl += translation +"-";
    if (!["t","b","l","r","c1","c2","c3","c4","center"].includes(position)) {
        position = "top";
    } else if (["t","b","l","r","c1","c2","c3","c4"].includes(position)){ // if position is "center" we don't need to fix the name and we leave like it is
      const a = {"t":"top","b":"bottom","l":"left","r":"right","c1":"corner-1","c2":"corner-2","c3":"corner-3","c4":"corner-4"}
      position = a[position];
    }
    out.cl += position;
    // If the element in poiitioned inside and near the corners we may need to set both the x and y position from the corner, in that case margin is an array[x,y], if x == y margin=value  Values must be positive ints!
    if (["corner-1","corner-2","corner-3","corner-4"].includes(position)) {
       if (Array.isArray(margin)) {
         out.cl += " p-2-vars ";
         if (margin[0]) calcStyle(margin[0],out,null,media,"pTrX");
         calcStyle(margin[1]??0,out,"",media,"pTrY");
         if (translation === "over")  out.cl +=  " p-over-in"+(margin[0]?" ":"-0 "); // we add a class only for margin[0] = 0 since it's the value that will be msotly used when not to 50% with a single margin
       } else {
         if (margin) {
           calcStyle(margin,out,"p-1-var",media,"pTr");
         } else {
           out.cl +=  " p-no-mr ";
         }
         if (translation === "over")  out.cl += "p-over-avg ";
       }
    } else {
      if (margin) {
        calcStyle(margin,out,"",media,"pTr");
      } else {
        out.cl +=  " p-no-mr ";
      }

    }
    out.cl += (type === "fixed"  ? " p-fixed  " : (type === "sticky" ? " p-sticky ":" p-abs "));
    return;
}
