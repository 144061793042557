// import {} from 'react';
import {Slider,D} from '../modules'; import Hero from './Hero';


export function HeroRotator(props) {

    return (
      <D style={{height:window.innerHeight}} cl="p-rel" >
        <Slider auto={0} circle={true}   cols={1}  noArrows={false} animationTime={{d:0.3,mp:0.1} }
            rows={[
                    {
                    id:0,
                    bkg:{img:{l:"1.jpg"},posL:"0% 30%",posP:"16% 0%",sizeL:"100% auto",sizeP:"auto 110%",},
                    gradient:{stop:"40%",a:1,},
                    meta:["div"],
                    header:{h1:"der",
                            position:["end","cen"],textAlign:"cen",pdS:60,pdC:20,gap:20,wd:"100%",font:{s1:30,s3:25,cHov:"yellow",c:"white"}}
                    },
                    {
                      id:1,
                    bkg:{img:{l:"2.jpg"},posL:"0% 30%",posP:"16% 0%",sizeL:"100% auto",sizeP:"auto 110%",},
                    gradient:{stop:"40%",a:1,},
                    meta:["div"],
                    header:{h1:"Come va oggi la festa xxx1xxxx quindi a che ora andiamo a che ora è la fine del mondo",
                            position:["end","cen"],textAlign:"cen",pdS:60,pdC:20,gap:20,wd:"100%",font:{s1:30,s3:25,cHov:"yellow"}}
                    },
                    {
                    id:2,
                    bkg:{img:{l:"3.jpg"},posL:"0% 30%",posP:"16% 0%",sizeL:"100% auto",sizeP:"auto 110%",},
                    gradient:{stop:"40%",a:1,},
                    meta:["div"],
                    header:{h1:"Come va oggi la festa xxx1xxxx quindi a che ora andiamo a che ora è la fine del mondo",
                            position:["end","cen"],textAlign:"cen",pdS:60,pdC:20,gap:20,wd:"100%",font:{s1:30,s3:25,cHov:"yellow"}}
                    },
                    {
                    id:3,
                    bkg:{img:{l:"1.jpg"},posL:"0% 30%",posP:"16% 0%",sizeL:"100% auto",sizeP:"auto 110%",},
                    gradient:{stop:"40%",a:1,},
                    meta:["div"],
                    header:{h1:"ss",
                            position:["end","cen"],textAlign:"cen",pdS:60,pdC:20,gap:20,wd:"100%",font:{s1:30,s3:25,cHov:"yellow"}}
                    },
                    {
                      id:4,
                    bkg:{img:{l:"2.jpg"},posL:"0% 30%",posP:"16% 0%",sizeL:"100% auto",sizeP:"auto 110%",},
                    gradient:{stop:"40%",a:1,},
                    meta:["div"],
                    header:{h1:"Come va oggi la festa xxx1xxxx quindi a che ora andiamo a che ora è la fine del mondo",
                            position:["end","cen"],textAlign:"cen",pdS:60,pdC:20,gap:20,wd:"100%",font:{s1:30,s3:25,cHov:"yellow"}}
                    },
                    {
                      id:5,
                    bkg:{img:{l:"3.jpg"},posL:"0% 30%",posP:"16% 0%",sizeL:"100% auto",sizeP:"auto 110%",},
                    gradient:{stop:"40%",a:1,},
                    meta:["div"],
                    header:{h1:"Come va oggi la festa xxx1xxxx quindi a che ora andiamo a che ora è la fine del mondo",
                            position:["end","cen"],textAlign:"cen",pdS:60,pdC:20,gap:20,wd:"100%",font:{s1:30,s3:25,cHov:"yellow"}}
                    },
                    {
                      id:6,
                    bkg:{img:{l:"1.jpg"},posL:"0% 30%",posP:"16% 0%",sizeL:"100% auto",sizeP:"auto 110%",},
                    gradient:{stop:"40%",a:1,},
                    meta:["div"],
                    header:{h1:"h1",
                            position:["end","cen"],textAlign:"cen",pdS:60,pdC:20,gap:20,wd:"100%",font:{s1:30,s3:25,cHov:"yellow"}}
                    },
                    {
                      id:7,
                    bkg:{img:{l:"2.jpg"},posL:"0% 30%",posP:"16% 0%",sizeL:"100% auto",sizeP:"auto 110%",},
                    gradient:{stop:"40%",a:1,},
                    meta:["div"],
                    header:{h1:"Come va oggi la festa xxx1xxxx quindi a che ora andiamo a che ora è la fine del mondo",
                            position:["end","cen"],textAlign:"cen",pdS:60,pdC:20,gap:20,wd:"100%",font:{s1:30,s3:25,cHov:"yellow"}}
                    },
                    {
                      id:8,
                    bkg:{img:{l:"3.jpg"},posL:"0% 30%",posP:"16% 0%",sizeL:"100% auto",sizeP:"auto 110%",},
                    gradient:{stop:"40%",a:1,},
                    meta:["div"],
                    header:{h1:"Come va oggi la festa xxx1xxxx quindi a che ora andiamo a che ora è la fine del mondo",
                            position:["end","cen"],textAlign:"cen",pdS:60,pdC:20,gap:20,wd:"100%",font:{s1:30,s3:25,cHov:"yellow"}}
                    },




              ]}
        rowFunction={Hero} />
      </D>
    );
}
