export function rgbToHsv(rgb) {
 const {r,g,b} = rgb;
  var h;
  var s;
  var v;

  var maxColor = Math.max(r, g, b);
  var minColor = Math.min(r, g, b);
  var delta = maxColor - minColor;

  // Calculate hue
  // To simplify the formula, we use 0-6 range.
  if(delta == 0) {
    h = 0;
  }
  else if(r == maxColor) {
    h = (6 + (g - b) / delta) % 6;
  }
  else if(g == maxColor) {
    h = 2 + (b - r) / delta;
  }
  else if(b == maxColor) {
    h = 4 + (r - g) / delta;
  }
  else {
    h = 0;
  }
  // Then adjust the range to be 0-1
  h = h/6;

  // Calculate saturation
  if(maxColor != 0) {
    s = delta / maxColor;
  }
  else {
    s = 0;
  }

  // Calculate value
  v = maxColor / 255;

  return { h: Math.round(h*360), s: Math.round(s*100), v: Math.round(v*100)};
 };
