import {calcNumClasses,isObj} from '../../../modules';

export default function calcPosition(pos) {
  // translation: "over","in","btw"   position:"t||top,r||right,b||bottom,l||left,c1 || corner 1,c2,c3,c4, center" margin:intval, unit = "px" (default) || "%""
  // Vars g,m
    let out = " p-";let out2 = " p-";
    if (isObj(pos) && (("top" in pos) || ("bottom" in pos))) {
      let vars = "";
      if ("top" in pos) {
        out2 += "top";
        vars += calcNumClasses(pos.top,100,1,"m");
      } else {
        out2 += "bottom";
        vars += calcNumClasses(pos.bottom,100,1,"m");
      }
      if ("left" in pos) {
        out2 +=  "-left";
        vars += calcNumClasses(pos.left,100,1,"g");
      } else {
        out2 +=  "-right";
        vars += calcNumClasses(pos.right,100,1,"g");
      }
      out2 += vars;
      out2 += (pos.type === "fixed"  ? " p-fixed  " : (pos.type === "sticky" ? " p-sticky ":" p-abs "));
      out2 += pos.unit === "%" ? " abs-pc " : (pos.unit === "vw" ? " abs-vw " : (pos.unit === "vh" ? " abs-vh " :" abs-px "));
      return out2;
    } else if (Array.isArray(pos)) {
      var [translation,position,margin,unit,isFixed] = pos;
    } else if (isObj(pos)) {
      var {translation,position,margin,unit,isFixed} = pos;
    } else {
      return "";
    }

    if (!["over","in","btw"].includes(translation)) translation="in";
    out += translation +"-";
    if (!["t","b","l","r","c1","c2","c3","c4","center"].includes(position)) {
        position = "top";
    } else if (["t","b","l","r","c1","c2","c3","c4"].includes(position)){ // if position is "center" we don't need to fix the name and we leave like it is
      const a = {"t":"top","b":"bottom","l":"left","r":"right","c1":"corner-1","c2":"corner-2","c3":"corner-3","c4":"corner-4"}
      position = a[position];
    }
    out += position;
    // If the element in poiitioned inside and near the corners we may need to set both the x and y position from the corner, in that case margin is an array[x,y], if x == y margin=value  Values must be positive ints!
    if (["corner-1","corner-2","corner-3","corner-4"].includes(position)) {
       if (Array.isArray(margin)) {
         out += " p-2-vars " + calcNumClasses(margin[0],100,1,"g") + calcNumClasses(margin[1],100,1,"m");
         if (translation === "over")  out +=  " p-over-in ";
       } else {
         out += " p-1-var " + calcNumClasses(margin ?? 0,100,1,"m");
         if (translation === "over")  out += "p-over-avg ";
       }
    } else out += calcNumClasses(margin ?? 0,100,1,"m");
    out += unit === "%" ? " abs-pc " : (unit === "vw" ? " abs-vw " : (unit === "vh" ? " abs-vh " :" abs-px "));
    out += (isFixed ? " p-fixed " : " p-abs ");
    return out;
}
