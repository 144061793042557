import {useState,useContext,useReducer} from 'react'; import {Color,D,TopBar,Shared,FlexCols} from 'modules';
import Menu from './Menu'; import FormPickers from './FormPickers'; import Test from './Test';

function Edit({view,setView,saveElement,deleteElement,...props}) {

  if (view !== "edit") {
    return null;
  }  else {
    return (
      <D a={{pd:50,colors:{bkg:"azure"}}} >
        <Menu {...{setView,saveElement,deleteElement}}/>
        <EditSection {...props} />
      </D>
    );
  }
}

function EditSection({color,dispatchColor,index}) {
  let a = {cl:"a-flex-col bar-left"};
  [a.value,a.handleChange] = useState("Test Value");
  [a.focus,a.setFocus] = useState(false);
  [a.error,a.setError] = useState(false);
  if (!color) {
    return null;
  } else {
    return (
      <div className="flex-start-btw">
        <Test {...{a,colors:color}}/>
        <D a={{wd:"30%",colors:{bkg:"blue"}}}>
          <FormPickers {...{colors:color,dispatchColor:(obj)=>dispatchColor({...obj,index})}}/>
        </D>
      </div>
    );
  }
}



export default Edit;
