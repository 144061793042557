import {useMemo} from 'react';

function Bkg({img,posL,posP,sizeL,sizeP}) {
  const [style,visibility,portraitStyle] = useMemo(()=>calcBkg(img,posL,posP,sizeL,sizeP),[img,posL,posP,sizeL,sizeP]);
  return (
    <div className="hero-bkg-cont p-abs-tl" >
        <div className={" p-abs-tl "+visibility} style={style}/>
        <Portrait style={portraitStyle}/>
    </div>
  );
}

export default Bkg;

function Portrait({portraitStyle}) {
  if (!portraitStyle) {
    return null;
  } else {
    return  <div className=" p-abs-tl hero-port-img" style={portraitStyle}/>;
  }
}


function calcBkg(img,posL,posP,sizeL,sizeP) {
  let style = {}; let visibility = "";let portraitStyle;
  style.backgroundImage=`url(${img?.l ?? img})`;
  style["--bkgPosL"] = posL ?? "center";
  style["--bkgSizeL"] = sizeL ?? "100% auto";
  if (!img?.p) {
    style["--bkgPosP"] = posP ?? "center";
    style["--bkgSizeP"] = sizeP ?? "auto 100%";
  } else {
    visibility = " hero-land-img ";
  }
  if (img?.p) portraitStyle = {backgroundImage:`url(${img.p})`};
  //console.log("bkg calc")
  return [style,visibility,portraitStyle];
}
