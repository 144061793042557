import {calcCssClass, calcCssClasses, colToCss,cssInts,calcNumClasses} from '../../../modules';
import {isObj} from '../../../helpers.js';

const calcBoxShadow = (shadow,focus, error, divBkg) => {
  // Focus, error are only used when called from a form to set different value when the form is in blur,error or focus state
  //  Vars: xs4,xs5,xs6,xs7,a1,rgb1
  let out = " ";


  if (shadow === true) {
    out = " a-shadow-fb ";
  } else if (isObj(shadow)){
    const size = shadow.s;
    if (!size) return out;
    let blur = size;
    if (shadow.bm) {
      blur = shadow.bm;
      out += " shadow-blur-multiply ";
    } else if (shadow.ba) {
      blur = shadow.ba;
      out += " shadow-blur-add ";
    }
    out += calcNumClasses(size,10,1,"xs4");
    out += calcNumClasses(blur,10,1,"xs5");
    out += (" a1-"+(shadow.a ?? 0.5).toString().replace(".","")+" ");
    const shaColor = shadow.c ?? (Array.isArray(divBkg) ? divBkg[0] : divBkg) ?? "black";
    out += colToCss("rgb1",shaColor);
    const hover = shadow.hover;
    if (hover) {
      if (hover.only) {
        out += " a-shadow-hover-rgb ";
      } else {
        out += " a-shadow-rgb ";
        if (hover.add) out += (" a-shadow-hover-inc " + calcNumClasses(hover.add,10,1,"xs6"));
      }
      if (hover.trans) {
        out += calcNumClasses(Math.abs(hover.trans),10,2,"xs7") + (hover.trans > 0 ? " press-down " : " press-up ");
      }
    } else {
      out += " a-shadow-rgb ";
    }
  }
  return out;
}


export default calcBoxShadow;
