import Home from './Home/Home';import {Div,TopBar,PickersPage} from './modules'; import BtnTest from './BtnTest'; import FormTest from './forms/FormContainer_/FormTest/FormTest';

function Router({currentPage})  {
  const routes = {home: Home,prova: Prova,btn:BtnTest,pickers:PickersPage, formTest:FormTest};
  const Component = routes[currentPage[0]];
  const props = currentPage[1] ?? {};
  return <Component {...props} />
}

export default Router;

const Prova = (props) => {
  return (
    <Div  width="100%" height={800} colors={{bkg:"yellow"}} style={{"--top-bar-height":"65px"}}><TopBar  bkg="blue" a={1} position="" /></Div>
  )
} ;
