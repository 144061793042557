import {isObj,styleV,styleP} from 'modules';


export default function calcPosition(pos,out) {
    if (pos?.t || pos?.b) {
      out.cl += " p-";
      if ("t" in pos) {
        out.cl += "top";
        styleV(pos.t,out,"pTo","%");
      } else {
        out.cl += "bottom";
        styleV(pos.b,out,"pBo","%");
      }
      if ("l" in pos) {
        out.cl +=  "-left";
        styleV(pos.l,out,"pLe","%");
      } else {
        out.cl +=  "-right";
        styleV(pos.r,out,"pRi","%");
      }
      const type = pos.type;
      if (isObj(type)) {
        styleP(type,out,"position");
      } else {
        out.cl += (type === "fixed"  ? " p-fixed  " : (type === "sticky" ? " p-sticky ":" p-abs "));
      }
      // When we set the t,b,r,l position we can then add the 50% self adjustment trasnlation by adding center = true, the tranformation will be done according to the position we set
      if (pos.center) {
        if (("t" in pos) && ("l" in pos)) {  // if we positioned the eleent o top and left we need to self center with -50%,-50%
          out.cl += " center-tl ";
        } else if (("t" in pos) && ("r" in pos)) { // in this case since we defined the right position instead of the left we will add a translation 50%,-50%
          out.cl += " center-tr ";
        } else if (("b" in pos) && ("l" in pos)) {
          out.cl += " center-bl ";
        } else if (("b" in pos) && ("r" in pos)) {
          out.cl += " center-br ";
        }
      }

    } else if (Array.isArray(pos)) {
      var [translation,position,margin,type,zIndex] = pos;
      out.cl += " p-";
    } else if (isObj(pos)) {
      var {translation,position,margin,type,zIndex} = pos;
      out.cl += " p-";
    } else {
      out.cl += " ";
      return ;
    }

    if (!["over","in","btw"].includes(translation)) translation="in";
    out.cl += translation +"-";
    if (!["t","b","l","r","c1","c2","c3","c4","center"].includes(position)) {
        position = "top";
    } else if (["t","b","l","r","c1","c2","c3","c4"].includes(position)){ // if position is "center" we don't need to fix the name and we leave like it is
      const a = {"t":"top","b":"bottom","l":"left","r":"right","c1":"corner-1","c2":"corner-2","c3":"corner-3","c4":"corner-4"}
      position = a[position];
    }
    out.cl += position;
    // If the element in poiitioned inside and near the corners we may need to set both the x and y position from the corner, in that case margin is an array[x,y], if x == y margin=value  Values must be positive ints!
    if (["corner-1","corner-2","corner-3","corner-4"].includes(position)) {
       if (Array.isArray(margin)) {
         out.cl += " p-2-vars ";
         if (margin[0]) styleV(margin[0],out,"pTrX","%");  // for margin[0] == 0 we add the value as a class below
         styleV(margin[1]??0,out,"pTrY","%");
         if (translation === "over")  out.cl +=  " p-over-in"+(margin[0]?" ":"-0 "); // we add a class only for margin[0] = 0 since it's the value that will be msotly used when not to 50% with a single margin
       } else {
         if (margin) {
           styleV(margin,out,"pTr","%","p-1-var");
         } else {
           out.cl +=  " p-no-mr p-1-var ";
         }
         if (translation === "over")  out.cl += "p-over-avg ";
       }
    } else {
      if (margin) {
        styleV(margin,out,"pTr","%");
      } else {
        out.cl +=  " p-no-mr ";
      }

    }
    if (isObj(type)) {
      styleP(type,out,"position");
    } else {
      out.cl += (type === "fixed"  ? " p-fixed  " : (type === "sticky" ? " p-sticky ":" p-abs "));
    }

    if (zIndex) {
      if (Number.isInteger(zIndex) && (zIndex < 20 || (zIndex < 101 && zIndex%10===0) || (zIndex <= 1000 && zIndex>100 && zIndex%100===0) || (zIndex <= 10000 && zIndex>1000 && zIndex%1000===0))) {
        out.cl += ` z-${zIndex} `;
      } else {
        styleP(zIndex,out,"z-index");
      }
    }
    return;
}
