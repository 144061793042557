import {useState,useRef,useEffect,useContext,useMemo} from 'react';import {Shared,DRef} from '../../modules';
import Gradient from './Gradient';import H1 from './H1';import H3 from './H3';import './front.css';import {calcHeader} from './calc';

function Front({meta,header,...props}) {
  const {media,bot,handler} = useContext(Shared);
  const {h1,h3} = header;
  const show = h1 || h3;
  const {style,cl,h1Cl,h3Cl,align,textAlignCl,flexPosition,gradient} = useMemo(()=>calcHeader(header,media,show,props.gradient),[header,media,show,props.gradient]);
  let container = "p-rel w100 h100 z-5 a-font-white" + flexPosition;
  if (!show) {
    return null;
  } else {
    const click = meta ? ()=>handler(meta) : null;
    return (
      <header className={container}>
          <div className={"heroRot-title-header  p-rel z-8  pointer lh-13 w500 nowrap flex-col lh-13 "+cl+textAlignCl}  style={style} onClick={click}>

              <H1  {...{h1Cl,h1 }} index={props.index} />
              <H3 {...{h3Cl,h3 }} />
          </div>
          <Gradient {...{gradient}}/>
      </header>
    );
  }
}

export default Front;
