import {useState} from 'react'; import {Button}from 'modules';
import ListRow from './ListRow/ListRow'; import NewLists from './NewLists';

function ColorsLists({savedColors,dispatchColors,color,dispatchColor}) {
  const [lists, setLists] = useState(null); // This is used to create new lists, when null the create view is closed, when is open contains an array.
  const [saveView, setSaveView] = useState(false);
  const openNewListsView = ()=> setLists([{name:"",key:0}])
  if (!savedColors) {
    return null;
  } else {
    const deleteList = (listId) => {
      if (!listId) {
        alert("no list id");
      } else {
        const data = {method: 'POST', headers: {'Content-Type': 'application/json', },body: null,};
        fetch("/colors/deleteList/"+listId, data).then((res) => res.json()).then((result)=>{if (result?.[0] !== "error") dispatchColors({fetched:result}) }, (error) =>  console.log(error));
      }
    }

  const dispatchLists = (result) => {
      if (result?.[0] !== "error") {
        dispatchColors({fetched:result});
        setLists(null);
      }
  }
  const saveList = () => { // For both editing a list and saving a list (the server will decide the right operation based on the structer of the lists variable)
    const data = {method: 'POST', headers: {'Content-Type': 'application/json', },body: JSON.stringify(lists)};
    fetch("/colors/saveList", data).then((res) => res.json()).then(dispatchLists, (error) =>  console.log(error));
  }


  if (lists) {
    return <NewLists {...{lists,setLists,saveList}}/>
  }  else {
    return (
      <div>
          {savedColors.map((row) => <ListRow {...{...row,dispatchColor,deleteList,dispatchColors}} key={row.name}/>)}
          <Button style={{marginTop:"16px"}} text="New List" click={openNewListsView} size={{font:{d:"19px"},height:40}}  colors={{b:{text:"white",bkg:"dodger blue"},c:{bkg:"blue"},h:{lBkg:10}}}/>

      </div>
    );
  }

}

}

export default ColorsLists;
