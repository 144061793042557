import {isObj,colToCss,mediaVal} from '../../../modules';
import {calcStyle} from '../helpers';

const calcGradientClass = (color,hasTwoColors,blurVar,media) =>
      " a-btn-grad-" + (mediaVal(color.radial,media) ? "rad" :"lin") + (blurVar && !hasTwoColors ? ("-"+blurVar[0]+"h ") :" ");

function calcComponent(color,blurVar,out,clVar) {
  const unit = ["l","s"].includes(blurVar[0]) ? "%":"";
  const hoverVar = Array.isArray(clVar) && Array.isArray(clVar[0]) ? clVar[1] : null ;  // If it's not an array it means there is no hover value
  calcStyle(blurVar[1]+unit,out,"",null,"grVar");
  if ((hoverVar || hoverVar === 0) && !isNaN(hoverVar)) { // hoverVar is not an array, it contains only tchange what you set the number set in the second element of the blurVar, you cannot change the types
    calcStyle(hoverVar+unit,out,"",null,"grVarHov");
    out.cl += ["l","s"].includes(blurVar[0]) ? " a-ls-gradient " : " a-rgbh-gradient ";
  }
  // If var has a third element and value === true it means the gradient has 3 colors intead of  two so we had a proper css class
  if (blurVar.length === 3 && !isNaN(blurVar[2])) out.cl += ` grad-${blurVar[2]}-col `;
}

function calcColorStops(color,out,media) {
  let stop1 = mediaVal(color.stop1 ?? color.s1,media);
  let stop2 = mediaVal(color.stop2 ?? color.s2,media);
  let stop1Hov, stop2Hov;
  if (stop1?.[0]) {
     stop1Hov = stop1?.[1];stop1 = stop1[0];
  }
  if (stop2?.[0]) {
     stop2Hov = stop2?.[1];stop2 = stop2[0];
  }
  if(!stop1) {
    out.cl += " grSt1-0 ";
  } else  {
    calcStyle(stop1+"%",out,"",null,"stop1");
  }
  if (isNaN(stop2)  || stop2 >= 100 || stop2 > stop1) {
    out.cl += " grSt2-100 ";
  } else {
    calcStyle(stop2+"%",out,"",null,"stop2");
  }
  if (!isNaN(stop1Hov)) calcStyle(stop1Hov+"%",out,"a-btn-stop1-hover",null,"grSt1H");
  if (!isNaN(stop2Hov)) calcStyle(stop2Hov+"%",out,"a-btn-stop2-hover",null,"grSt2H");
}

function calcGradientDirection(color,out,media) {
  let direction = mediaVal(color.dir,media);
  let directionHov;
  if (!isNaN(direction?.[0])) {
    directionHov = direction?.[1];
    direction = direction[0];

  }
  if (!mediaVal(color.radial,media)) {  // Calculate the direction if the gradient is linear
    if (!isNaN(direction)) {
      ((direction % 10) === 0) ? out.cl +=` grDir-${direction} ` : calcStyle(direction+"deg",out,"",null,"grDir");

    } else {
      out.cl += " grDir-0 "
    }
    if (!isNaN(directionHov)) calcStyle(directionHov+"deg",out,"grad-hover-direction",null,"grDirH");
  }
}

export {calcGradientDirection, calcComponent, calcColorStops, calcGradientClass};
