import React, {useEffect,useRef} from 'react'; import './inputs.css';
import {validateError} from "../forms";import {isObj,def} from "../../modules";


  const InputInner = ({type,name,rows,value,id,focus,handleInput,handleFocus,handleBlur}) => {
    const ref = useRef(null);
    const inputProps = {name,value,ref,id,onChange:handleInput,onFocus:handleFocus,onBlur:handleBlur};
    useEffect(()=>{
      if (typeof focus === "bolean") {
        if (focus && (ref.current !== document.activeElement)) {
          ref.current.focus();
        } else if (!focus && (ref.current === document.activeElement)) {
          ref.current.blur();
        }
      }

    },[focus]);

    if (type === "textarea") {
      return <textarea className="form-input" rows={rows ?? 4} {...inputProps}  />;
    } else {
      return <input type={type ?? "text"} className="form-input" {...inputProps}  />;
    }

  };

export default InputInner;





/* <form action="/CI/log/in" method="post" onSubmit={this.handleSubmit}>
<FormInput name="email" type=text_Default value={this.state.email} label="E-Mail" centerLabel=false_default labelColor=blue_dedault
handleChange={this.handleChange} resetChange={this.resetChange} color="w3-light-gray" validate="email" alertHandler={this.alertHandler}
maxWidth=notComp grid=notComp ref={this.emailRef}/>
*/
