import {calcStyle} from '../helpers';
import {isObj} from '../../../modules';

function calcPadding(pd,out,media) {

  if (isObj(pd) && !("d" in pd)) {
    if (pd.S) {
    calcStyle(pd.S,out,"pdS",media);
    } else {
      if (pd.T) calcStyle(pd.T,out,"pdT",media);
      if (pd.B) calcStyle(pd.B,out,"pdB",media);
    }
    if (pd.C) {
      calcStyle(pd.C,out,"pdC",media);
    } else {
      if (pd.R) calcStyle(pd.R,out,"pdR",media);
      if (pd.L) calcStyle(pd.L,out,"pdL",media);
    }
  } else {
    calcStyle(pd,out,"pd",media);
  }
}

export default calcPadding;
