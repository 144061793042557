import {useMemo} from 'react'; import {Color} from 'modules';import IconPickers from './Pickers/IconPickers';import ArrowPickers from './Pickers/ArrowPickers';
import BrdPickers from './Pickers/BrdPickers';import BkgPickers from './Pickers/BkgPickers';import TextPickers from './Pickers/TextPickers';

function Router({showSettings,activeGroup,...other}) {
  // other  = activePicker,dispatchColor,button,settings,
  //const lRef = button.bkg.b[2];
  if (showSettings) {
    return null;
  } else {
    other.lRef = other.button.bkg.b[2];
    switch (activeGroup) {
      case "Bkg": return <BkgPickers {...other} />;break;
      case "Text": return <TextPickers {...other}/>;break;
      case "Brd": return <BrdPickers {...other}/>;break;
      case "Icon": return <IconPickers {...other}/>;break;
      case "Arrow": return <ArrowPickers {...other}/>;break;
      default: return null;
    }
  }
}

export default Router;
