import {Pickers,Color} from 'modules';

function ArrowPickers({activePicker,dispatchColor,button,settings,lRef}) {
  const a = {position:"relative"};
  if (activePicker === "Click") return null;
  if (!settings.lArr || (activePicker === "Active")) {
    a.smallHexMenu = true;
    [a.color,a.setColor] = settlers(button,activePicker,dispatchColor);
  } else {
    a.editComponent = {type:"l",lRef,header:"Luminosity"};
    [a.color,a.setColor] = lSettlers(button,activePicker,dispatchColor);
  }
  return  <Pickers {...a}/>
  }

  function settlers(button,activePicker,dispatchColor) {
    let color,setColor;
    if (activePicker === "Blur") {
      color = new Color({hex:button.arrow.arCl ?? "#ffffff"});
      setColor = (color) => dispatchColor({row:"arrow",col:"arCl",value:color.hex});
    } else if (activePicker === "Hover") {
      color = new Color({hex:button.arrow.arClH ?? "#ffffff"});
      setColor = (color) => dispatchColor({row:"arrow",col:"arClH",value:color.hex});
    } else if (activePicker === "Active") {
      color = button.arrow.arClA ? new Color({hex:button.arrow.arClA}) : new Color({hex:"#ffffff"});
      setColor = (color) => dispatchColor({row:"arrow",col:"arClA",value:color.hex});
    }
    return [color,setColor];
  }

  function lSettlers(button,activePicker,dispatchColor) {
  let color,setColor;
  if (activePicker === "Blur") {
    color = new Color({hsl:{h:button.bkg.b[0],s:button.bkg.b[1],l:button.arrow.lArr ?? button.bkg.b[2]}});
    setColor = (color) => dispatchColor({row:"arrow",col:"lArr",value:color.hsl.l});
  } else if (activePicker === "Hover") {
    color = new Color({hsl:{h:button.bkg.b[0],s:button.bkg.b[1],l:button.arrow.lArrH ?? button.bkg.b[2]}});
    setColor = (color) => dispatchColor({row:"arrow",col:"lArrH",value:color.hsl.l});
  }
  return [color,setColor];
  }



export default ArrowPickers;
