export function setClickAndHref(props,bot,startAnimation) {
      let click, href;
       if (props.isActive) { // if button is active it should not been clicable or linkabeble
          click = false; href = null;
        } else if ((props.meta && props.handler && !bot) || (props.meta && props.handler && !props.href))  {
          click = ()=> {
            startAnimation();
            props.handler(props.meta);
          }
        } else if ((props.click && !bot) || (props.click && bot && !props.href)) {
        click = ()=> {
          startAnimation();
          props.click();
        };
      } else if ((props.href && !props.click) || (props.href && props.click && bot)) {
        href = props.href;
      }
    return {click,href};
}

export function calcbuttonContainer(btnClicked,props,containers,click,href,posWdMr,sizeGeneratedClass) {
  const colors = containers?.colors ?? {b:""};
  const clickAnimation = (btnClicked && props.animate)  ? " a-btn-clicked-anim  " : " ";    // Start the trasnlateY animation if the button has been clicked and the animate prop has been set true
  const colorsContainer =  colors.shared + (colors.a && props.isActive ? colors.a : ((btnClicked  && colors.c) ? colors.c : colors.b));
  let buttonContainer = (props.isActive || btnClicked ? " no-hover " : " pointer ") +(containers.sizes.button??"") + colorsContainer + clickAnimation;
  let colorsStyle = {};
  if (colors.style) {
    const activeStyle = (props.isActive && colors.a) ? colors.style.a : ((btnClicked  && colors.c) ? colors.style.c : colors.style.b);
    colorsStyle = {...activeStyle,...colors.style.shared};
  }
   // We need to increase the z-index when the dropdown or the fixed menu is open because even if the child have a very hight z-index , if the parent has a low z-index they will go behind, because their z-index puts them before the parent but not before the elements the parent was behind
  buttonContainer += props.zIndex ?? " z-5 ";
  buttonContainer += !click && !href ? " no-hover a-btn-opac " : " ";
  buttonContainer += " "+(posWdMr.cl??"");
  if (props.buttonContainer) buttonContainer += props.buttonContainer;
  if (containers.sizes.inlineStyle?.[1]) buttonContainer += ` ${sizeGeneratedClass} `;
  if (containers.sizes.inlineStyle?.[0]) buttonContainer += containers.sizes.inlineStyle?.[0];
  return [buttonContainer,colorsStyle];
}
