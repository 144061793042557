import {isObj,isString} from '../../../helpers.js';

export default function calcFontSize(fSize,isVar) {
  // Vars: b,c,d
   let out = isVar ? "" : " a-font-class ";
   if (!fSize || fSize === "") {
     return "";
   } else if (Number.isInteger(fSize)) {
     out += ` a-font b-${Math.min(fSize,99)} `;
   } else if (isObj(fSize) && ("l" in fSize)){
     out += calcFontClasses(fSize.l, "l");
     if (fSize.m) out += calcFontClasses(fSize.m, "t");
     if (fSize.xm) out += calcFontClasses(fSize.xm, "xm");
     if (fSize.s) out += calcFontClasses(fSize.s, "s");
     if (fSize.xs) out += calcFontClasses(fSize.xs, "xs");
   } else if (isString(fSize)) {  // we we have many identical elements instead of recalculatiung the fontSize we can store the output to a const and then pass this const to fSize of the next element, so it will not calculate it again and just return the passed string.
     out += ` ${fSize} `;
   }
   return out;
}

function calcFontClasses(val, screen) {
  // We use different classes for every screen instead of reling on the vars media query because on some screen size we may use a fixed size while in another a vw-range so we have to chnage the formula and the number of vars to be calculated
  let out = "";
  const suffix = screen === "l" ? "" : "-"+screen;
  if (Array.isArray(val) && (val[1] === "vw")) {
    out += (val.length === 4) ?
      ` a-font-vw-range${suffix} b-${Math.min(Math.round([val[0]*10]),99)+suffix} c-${val[2]+suffix} d-${val[3]+suffix} ` :
       ` a-font-vw${suffix} b-${Math.min(Math.round([val[0]*10]),99)+suffix} `;
  } else {
    out += ` a-font${suffix} b-${Math.min(val,99)+suffix} `;
  }
  return out;
}
