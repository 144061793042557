import {colToCss,addPc} from 'modules';


export function calcIconColors(colors = {},out) {
  if (!colors.h?.icon && colors.h?.lTxt && colors.b?.icon) {
    out.rowIconContainer += colToCss("hsl2",colors.b?.icon) + " drop-icon-lTxt ";
    if (Array.isArray(colors.h?.lTxt)) {
      out.rowIconContainer +=  " custom-lTxt ";
      out.colorsStyle["--icon-lTxt"] = addPc(colors.h.lTxt[1]);
    }
  } else if (colors.b?.icon) {
    out.rowIconContainer += colToCss("hex2",colors.b?.icon) + " drop-icon-blur ";
  }
  if (colors.h?.icon) out.rowIconContainer += colToCss("hex3",colors.h.icon ) + " drop-icon-hover ";
}
