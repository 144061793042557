import {cssInts,calcNumClasses} from '../../../modules';


function calcWidth(divStyle) {
    //   f,g-> width
  const {width,maxWidth, minWidth, pFlex, flex} = divStyle;
  let out = "";
  if (pFlex) {
    out += calcNumClasses(pFlex,100,1,"f") + " a-pFlex-col ";
  } else if (flex) {
    out += " a-flex-col ";
  } else {
      if (width) {
        out += cssInts(width,"f","width") + " a-width ";
      } else if (minWidth) {
        out += cssInts(minWidth,"f","minWidth") + " a-min-width ";
      }
      if (maxWidth) {
        out += cssInts(maxWidth,"e","maxWidth") + " a-max-width ";
      }
  }
  return out;
}

export default calcWidth;
