import React, {useState,cloneElement,forwardRef,useContext} from 'react';import {SvgIcon} from 'modules';import './dropInner.css';
import FlexWrapper from './modules/FlexWrapper';import DropSection from './modules/DropSections/DropSection';import {BtnContext} from 'Buttons/';

const DropInner = forwardRef(({tipPos},ref) => {
  const {dropContainers,closeDropDown,handler,dropMenu,fixed} = useContext(BtnContext);
  const [expanded,expand] = useState(null);
  const {rows,component,multiCols,multiDrop,length} = dropMenu;
  const dropTipProps = {closeDropDown,tipPos,fixed,shadow:dropContainers?.dropTipContainer};
  if (rows) {
    return (
      <div className={"a-btn-drop-inner w100 no-scr-bar "+(dropContainers.inner??"")} ref={ref}>
         <DropDownTip {...dropTipProps}  /> {/*Attention the tip should be the first child, because we are not forwarding the ref to it but using first child in fix tip position function, if we change location we have to also change the fucntion*/}
         <FlexWrapper {...{multiCols,multiDrop,dropContainers,length}} >
            {rows.map((row, index) => <DropSection   {...row} {...{expanded,expand}} id={index}  key={index}/>)}
         </FlexWrapper>
      </div>
    );

  } else if (component){
    return (
      <div data-name="inner-drop-cont" className={dropContainers.inner??""} ref={ref}>
         <DropDownTip {...dropTipProps} />
         {component[0]({...component[1],closeDropDown,handler})}
      </div>
    );
  } else {
    return null;
  }


});

export default DropInner;


const DropDownTip = (props) =>  props.fixed ? null : <SvgIcon icon="drop_up_tip"  sizeClass="btn-drop-down-tip"  style={{left:props.tipPos}} noShow={false} colorClass="btn-drop-down-tip-color" click={props.closeDropDown}/>;

//const DropDownTip = (props) => <div className="a-btn-drop-tip" style={{left:props.tipPos}} onClick={()=>props.setClick(!props.clicked)}></div>;
