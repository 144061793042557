//import {adjustHeight, adjustWidth, adjBrdRad, adjBrdSize,adjustCont, bEf, cD, int50, int100,sm,xs} from './adjFuncs.js';
import {isObj, isString} from '../../helpers.js';
import {int99ToBm, IntToBm, parseIntUnitStr} from './calc_helpers.js';

export const colToCss = (prefiss, name, className="") => ` a-${prefiss}-${name.toLowerCase().replace(" ","-").replace(" ","-").replace(" ","-")} ${className} `;

// Creates classes for number up to 5000 representing numbers as yy * y where the class for yy has prefix pref while class fpr y has class pref+"x"
// value = int || {l,m,s:value} value can be a int (considered px) or a string with the unit (px,%,vw,vh) (example: "3%") in that case a unit class will be added with prefiss unitPref

export function cssInts(value,pref,unitPref) {
  let cont = " ";

  if (isObj(value)) {
    const lVal = parseIntUnitStr(value.l);
    const l = IntToBm(lVal.val);
    cont += ` ${pref}-${l.b} ${pref}x-${l.m} `;
    if (lVal.unit && unitPref)  cont += ` ${unitPref+"-"+lVal.unit} `;
    if (value.m || (value.m === 0)) {
      const mVal = parseIntUnitStr(value.m);
      const m = IntToBm(mVal.val);
      cont += ` ${pref}-${m.b}-t ${pref}x-${m.m}-t `;
      if (mVal.unit && unitPref) cont += ` ${unitPref+"-"+mVal.unit}-t `;
    }
    if (value.s || (value.s === 0)) {
      const sVal = parseIntUnitStr(value.s);
      const s = IntToBm(sVal.val)
      cont +=  `${pref}-${s.b}-s ${pref}x-${s.m}-s `;
      if (sVal.unit && unitPref) cont += ` ${unitPref+"-"+sVal.unit}-s `;
    }
  } else if (value || (value === 0)){
    const val = parseIntUnitStr(value);
    const i = IntToBm(val.val);
    cont += ` ${pref}-${i.b} ${pref}x-${i.m} `;
    if (val.unit && unitPref) cont += ` ${unitPref+"-"+val.unit} `;
  }
  return cont;
}



// given a nuumber capped to 99 , divides the number in two digits and create a class for each digit with pref0 for the rightmost digit add pref1 for the first digit (*10)
export function cssInt99(value,pref0,pref1) {
  let cont = " ";
  if (isObj(value)) {
    const l = int99ToBm(value.l);
    cont += ` ${pref0}-${l.b0} ${pref1}-${l.b1} `;
    if (value.m || value.m === 0) {
      const m = int99ToBm(value.m);
      cont += ` ${pref0}-${m.b0}-t ${pref1}-${m.b1}-t `;
    }
    if (value.s || value.s === 0) {
      const s = int99ToBm(value.s);
      cont +=  `${pref0}-${s.b0}-s ${pref1}-${s.b1}-s `;
    }
  } else if (value || (value === 0)){
    const i = int99ToBm(value);
    cont += ` ${pref0}-${i.b0} ${pref1}-${i.b1} `;
  }
  return cont+" ";
}

// Given a number return a class for each display. the number is capped to max
// div should be 1 unless you want to divide the value by div to leter multiply it by div in the class. using a div = 5 you can map 1 to 100 into 1-20 range=> 5-10-15-20...95-100 so values will be rounded to the next 5
export function calcNumClasses(value,max,div,prefiss) {
    let cont = " ";
    const num = (val) =>{const i = val/div; return (i > max ? max : Math.round(i))};
    if (isObj(value)) {
      cont += ` ${prefiss}-${num(value.l)} `;
      if (value.m || value.m === 0) cont += ` ${prefiss}-${num(value.m)}-t `;
      if (value.s || value.s === 0) cont += ` ${prefiss}-${num(value.s)}-s `;
    } else if (value || (value === 0)) {
      cont += ` ${prefiss}-${num(value)} `;
    }
    return cont+" ";
}

// return classes that maps a flot as a int variable, use mult as a multiplier to trasnform the float to an int, and max to cap to values to a max value. The css class file needs then to divide the int by the mult used here to get back the float
export function calcFloatClasses(value, max, mult, prefiss) {
  let cont = " ";
  const num = (val) =>{const i = val*mult; return (i > max ? max : Math.round(i))};
  if (isObj(value)) {
    cont += ` ${prefiss}-${num(value.l)} `;
    if (value.m || value.m === 0) cont += ` ${prefiss}-${num(value.m)}-t `;
    if (value.s || value.s === 0) cont += ` ${prefiss}-${num(value.s)}-s `;
  } else if (value || (value === 0)) {
    cont += ` ${prefiss}-${num(value)} `;
  }
  return cont+" ";
}
