import Logo from '../../Logo';import Row from './Row';


function Center(props) {

  return (
    <div className="top-bar-center flex-cen-cen  colorsvg1" >
      <Logo logoSettings={props.shared.logoSettings}/>
      {props.rows.map((row,index) => <Row {...props.shared} {...row} center={true} key={index}/>)}
    </div>
  )
}

export default Center;
