import {TopBar,Pickers} from 'modules';

export function PickersPage(props) {
    return (
      <div>
          <TopBar  bkg="blue" a={1} position="relative" />
          <Pickers position="relative"/>
      </div>
    );
}
