import React, {useRef,forwardRef,useState,useEffect} from 'react';import './css/divSelect.css'; import './css/divSelectColors.css';import './css/divSelectIcons.css';
import {Calc,SvgIcon,mediaVal,calcFloatClasses} from '../../modules'; import {Overlay, RightIcon,DropDown,calcOptionsArray,Display} from './';

function SelectInner(props) {
  const {error, setError, focus, setFocus, clicked, setClick} = props.state;
  const {value,name,media,inputContainer,btnContainer, dropSize,dropDefColor,iconEm} = props;
  const {arrow,cols} = props.dropSize ?? {};
  const [xAdj,setXadj] = useState(null);
  const [isOpen, setOpen] = useState("");
  const [tipPos, setTipPos] = useState(0);
  const [iconEmVars, setIconEmVars] = useState("");
  const arrowSize = props.dropSize?.arrow === false ? 0 : mediaVal(arrow?.size,media ) ?? 24; // dropDown arrow inside the button. if value is false means arrow is disabled so its size is 0
  const tipSize = mediaVal(props.dropSize?.tipSize, media) ?? 18; // drop menu top arrow tip toward the drop button
  const random = Math.random(); // The selected row will use this ni+umber as an id, so we can track ot on scrollSelectedIntoView
  const delta = 80;
  const dropArrowRef = useRef(null);
  const dropDownRef = useRef(null);

  // Compute the position of the tooltip arrow tip so that it's placed just under the button's dropdown arrow
  useEffect( ()=> {
      if (clicked) {
        setOpen(" select-is-open ");
        const dropDownRect = dropDownRef.current ? dropDownRef.current.getBoundingClientRect() : null;
        if (!dropDownRect) return;
        scrollSelectedIntoView(dropDownRect);
        const dropDownLeftPos  = dropDownRect.x;
        const dropDownRightPos  = dropDownRect.right;
        //console.log("right: "+dropDownRect.right);
        // No css transition when direction is set to right or left, because the orizzontal animation from center to left/right is unwanted, we only want to show the vertical, but after every click we reset the direction to center in case the button position changes and the drop down is not anymore overflowing. Furthermore to calculate the tip position we would have to wait to transition on translate to end and so set a timeout. For these two reasons we added css transform trasnsition only
        // when direction is center or full width
        const diff = dropDownRightPos - window.innerWidth;
        //console.log("diff: "+diff);
        if (dropDownLeftPos < 0) {
          setXadj(dropDownLeftPos * -1 + (10 - dropDownLeftPos % 10) + 10); // we round every number to decimal ending, ex 307 -> 310 css vars are rounded and add a 10 px margin
        } else if (diff > 0) {
          setXadj(-1 * (diff + 10 - diff % 10 + 10));
        } else {
          const dropDownArrowPos = dropArrowRef.current ? dropArrowRef.current.getBoundingClientRect().x : dropDownRect.x + dropDownRect.width / 2;
          const tipPos = dropDownArrowPos + arrowSize / 2 - dropDownLeftPos - tipSize / 2;  // tipsize is the border width, but the width of the tip is actually tipSize * 2, so we don't have to divide tipSize by two when we subtract it because it's already an half value. the tip is build with css border width whose value is 50% of the width. for example for a 19 px width we have to set the border to 9
          setTipPos(tipPos+"px");
        }
      } else { // when the button is closed we reset xAdj in case the button position in the document changes when reopened
        setXadj(null);setOpen("");
      }
    },[clicked,xAdj]
  );

  useEffect(()=>setIconEmVars(Array.isArray(iconEm) ? calcFloatClasses(iconEm[0] ?? 1.1,100,10,"d") + calcFloatClasses(iconEm[1] ?? 0.5,100,10,"e") +" input-icon-em input-icon-mr-em " : calcFloatClasses(iconEm ?? 1.1,100,10,"d") + " input-icon-em "),[]);

  const scrollSelectedIntoView = (rect) => {
    const el = document.getElementById(random);
    if (el) {
      const elRect = el.getBoundingClientRect();
      const delta = elRect.top - rect.top;
      if (delta > (rect.height - elRect.height) || (delta < elRect.height)) {
        dropDownRef.current.scrollTop +=  delta - elRect.height / 2 ;
      }
    }
  };

  const handleOptionsClick = (val) => {
      setClick(false); setError(false); setFocus(false);
      if (props.handleOptionClick) {
        props.handleOptionClick(val);
      }  else {
        props.handleChange(val);
      }
  };

  const handleDropOpening = (e) => {  // open / close the options menu clicking the title
      e.stopPropagation();
      if (!clicked) {
        setClick(true); setFocus(true);
      } else {
        setClick(false); setFocus(false);
      }
  }

  const handleOverlay = () => { if (clicked) {setClick(false);setFocus(false);} }

  const dropColors = props.dropColors;
  const [options, valueName,selectedIcon] = calcOptionsArray(props); //
  const displayProps = {valueName,selectedIcon,handleDropOpening, arrow, inputContainer,name,value};
  const dropDownProps = {handleOptionsClick,options,value,tipSize,xAdj,random,media,tipPos,isOpen,cols,btnContainer, dropSize,dropDefColor,dropColors};
  const topDrop = props.topDrop ? " div-select-top" : "";

  return (
    <div  className={"div-select-inner flex-cen-start w100 h100 " + topDrop+iconEmVars} >
        <Display  {...displayProps} ref={dropArrowRef} />
        <DropDown  {...dropDownProps}  open={clicked} defaultIcon={props.icon}  close={()=>setClick(false)} ref={dropDownRef}/>
        <Overlay clickHandler={handleOverlay} show={clicked} close={()=>{setClick(false);setFocus(false);}}/>
    </div>
  )
}

export default SelectInner;
