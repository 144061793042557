import {calcNumClasses,SvgIcon,isObj,Calc} from '../../../modules';

export default function Text({text,icon,rightIcon}) {
  if (text) {
    const margin = !icon ? "" : (rightIcon ? "icon-txt-mrR" : "icon-txt-mrL");
    return <div className={"flex-2 "+margin}>{text}</div>
  } else {
    return null;
  }
}
