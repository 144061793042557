

function H1({index,h1,h1Cl}) {
  const cl = "fs-cl " + h1Cl
  if (!h1) {
    return null;
  } else if (index === 0){
    return (
        <h1 className={cl}>
            {h1}
        </h1>
    );
  } else {
    return (
        <h2 className={cl}>
            {h1}
        </h2>
    );
  }
}

export default H1;
