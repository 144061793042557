import {Pickers,Color} from 'modules';

function IconPickers({activePicker,dispatchColor,button,settings,lRef}) {
  const a = {position:"relative"};
  if (activePicker === "Click") return null;
  if (!settings.lIcon || (activePicker === "Active")) {
    a.smallHexMenu = true;
    [a.color,a.setColor] = settlers(button,activePicker,dispatchColor);
  } else {
    a.editComponent = {type:"l",lRef,header:"Luminosity"};
    [a.color,a.setColor] = lSettlers(button,activePicker,dispatchColor);
  }
  return  <Pickers {...a}/>
  }

  function settlers(button,activePicker,dispatchColor) {
    let color,setColor;
    if (activePicker === "Blur") {
      color = new Color({hex:button.icon.icCl ?? "#ffffff"});
      setColor = (color) => dispatchColor({row:"icon",col:"icCl",value:color.hex});
    } else if (activePicker === "Hover") {
      color = new Color({hex:button.icon.icClH ?? "#ffffff"});
      setColor = (color) => dispatchColor({row:"icon",col:"icClH",value:color.hex});
    } else if (activePicker === "Active") {
      color = button.icon.icClA ? new Color({hex:button.icon.icClA}) : new Color({hex:"#ffffff"});
      setColor = (color) => dispatchColor({row:"icon",col:"icClA",value:color.hex});
    }
    return [color,setColor];
  }

  function lSettlers(button,activePicker,dispatchColor) {
  let color,setColor;
  if (activePicker === "Blur") {
    color = new Color({hsl:{h:button.bkg.b[0],s:button.bkg.b[1],l:button.icon.lIc ?? button.bkg.b[2]}});
    setColor = (color) => dispatchColor({row:"icon",col:"lIc",value:color.hsl.l});
  } else if (activePicker === "Hover") {
    color = new Color({hsl:{h:button.bkg.b[0],s:button.bkg.b[1],l:button.icon.lIcH ?? button.bkg.b[2]}});
    setColor = (color) => dispatchColor({row:"icon",col:"lIcH",value:color.hsl.l});
  }
  return [color,setColor];
  }


export default IconPickers;
