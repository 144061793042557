import {forwardRef,useState,useMemo,useContext} from 'react';
import {Cal} from './Cal';import {isObj,Shared,mediaVal} from '../../modules';

function calcDiv(cl,media,a,noShow,style={}) {
  const out = {cl:mediaVal(cl, media)??"",style:{...mediaVal(style,media)}};
  if (isObj(a) && !noShow) {
    Cal.all(a,out,media);
  }
  return out;
}
export {Di} from './functionsNew/Di';
export {Calc as CalcNew} from './functionsNew/Calc';
export const D = (props) => {
  const {media} = useContext(Shared);
  const container = useMemo(()=>calcDiv(props.cl,media,props.a,props.noShow,props.style),[props.cl,props.a,media,props.noShow,props.style]);
  if (props.noShow ) {
    return null;
  } else {
    const a = {className:container.cl,style:{...container.style,...props.style},onClick:props.click ?? null};
    if ((props.type === "div" || !props.type)) {
      return <div {...a} > {props.children}</div>;
    } else if ((props.type === "p")) {
      return <p {...a} >{props.children} </p>;
    } else if ((props.type === "h1")) {
      return <h1 {...a} >{props.children}</h1>;
    } else if ((props.type === "h2")) {
      return <h2 {...a} >{props.children}</h2>;
    } else if ((props.type === "h3")) {
      return   <h3 {...a} >{props.children} </h3>;
    } else if ((props.type === "h4")) {
      return <h4 {...a} > {props.children}</h4>;
    }
  }
};

export const DRef = forwardRef((props,ref) => {
  const {media} = useContext(Shared);
  const container = useMemo(()=>calcDiv(props.cl,media,props.a,props.style),[props.cl,props.a,media,props.style]);
  if (props.noShow ) {
    return null;
  } else {
    const a = {className:container.cl,style:{...container.style,...props.style},onClick:props.click ?? null};
    if ((props.type === "div" || !props.type)) {
      return <div {...a} ref={ref}> {props.children}</div>;
    } else if ((props.type === "p")) {
      return <p {...a} ref={ref}>{props.children} </p>;
    } else if ((props.type === "h1")) {
      return <h1 {...a} ref={ref}>{props.children}</h1>;
    } else if ((props.type === "h2")) {
      return <h2 {...a} ref={ref}>{props.children}</h2>;
    } else if ((props.type === "h3")) {
      return   <h3 {...a} ref={ref}>{props.children} </h3>;
    } else if ((props.type === "h4")) {
      return <h4 {...a} ref={ref}> {props.children}</h4>;
    }
  }
}
);




export const H1 = (props) => <D {...props} type="h1"/>
export const H2 = (props) => <D {...props} type="h2"/>
export const H3 = (props) => <D {...props} type="h3"/>
export const H4 = (props) => <D {...props} type="h4"/>
export const P = (props) => <D {...props} type="p"/>
export const H1Ref = forwardRef((props,ref) => <DRef {...props} type="h1" ref={ref}/>);
export const H2Ref = forwardRef((props,ref) => <DRef {...props} type="h2" ref={ref}/>);
export const H3Ref = forwardRef((props,ref) => <DRef {...props} type="h3" ref={ref}/>);
export const H4Ref = forwardRef((props,ref) => <DRef {...props} type="h4" ref={ref}/>);
export const PRef = forwardRef((props,ref) => <DRef {...props} type="p" ref={ref}/>);
