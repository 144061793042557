import React, {useState,useRef,useEffect,useCallback,useContext} from 'react';
import {Div,Di,Slider,TopBar,HeroRotator,Shared,Cal,mediaVal,D,calcBtnCssSize,Pickers} from '../modules';

function Home(props) {
  const {media,currentPage,serverData} = useContext(Shared);
  useEffect(()=> {window.addEventListener("scroll", setBarOpacity); return ()=> {window.removeEventListener("scroll", setBarOpacity);}},[]);
  const [barOpacity,setBarOp] = useState(0);
  const barHeights = mediaVal([65,95],media);


  const [barHeight,setBarHeight] = useState(barHeights[1]);
  const barPosition = "fixed";
  const [range,setRange] = useState(0);
  const setBarOpacity = useCallback(()=>{
    if (currentPage[0] == "home" && !window.fixedMenu) {
      if (document.documentElement.scrollTop  > media.height / 3) {setBarOp(1); setBarHeight(barHeights[0])} else {setBarOp(0); setBarHeight(barHeights[1]);}
    }
  },[]);

  const style = {};
  if (["fixed","absolute"].includes(barPosition)) style["--top-bar-height"]=barHeight+"px";
  return (
    <D a={{mr:{B:20}}} style={style}>
        <TopBar  bkg="blue" a={barOpacity} position={barPosition} noPadding={true}/>
        <HeroRotator />
        <D  a={{pd:30,mr:{S:30}}}>
            <input type="range" className="w100" min={0} max={100} value={range} onChange={(e)=>setRange(e.target.value)} />
        </D>

        <D a={{he:1500, colors:{text:"black",bkg:"yellow"},pd:30,wd:"60%",minWd:"80%",fSize:40,mr:{C:"auto",S:20}}} cl="p-rel " click={()=>alert("clicked")}>
            {serverData?.title ?? "default"}
            <D a={{colors:{bkg:"red"},wd:100,he:100,pos:["over","c4",[0,20]]}} />

        </D>

    </D>
  );

}

export default Home;

/*
<Di ss={
  {pd:{C:{d:50,mp:10},S:30},maxW:300,minW:{d:250,mp:90},wd:{d:200,mp:120},
  he:{d:300,mp:200},minH:100,maxH:{d:500},fSize:{d:80,mp:[8,10,30]},we:{d:700,mp:300},lh:{d:"13px",mp:1},brd:[{s:4,c:"yellow",rad:20,radH:20,bar:{p:"l",c:"blue",s:20,rad:20}},{s:10,c:"green",rad:0,bar:{s:30,c:"green",rad:0}}],
  pos:["in","t",0,"fixed",299]
}
} style={{backgroundColor:"red"}} cl="flex-cen-cen ov-hid"> prova</Di>


function Prova(props) {
  const [direction,setDir] = useState(0);
  const ref = useRef(null);
  //useEffect(()=>{clearTimeout(ref.current);ref.current = setTimeout(()=>setDir(direction === -200 ? 0 : direction-10),400)},[direction]);
  const out = {cl:"",style:{}};
  Cal.fSize({d:30,xt:25,mp:15,t:20,xm:10},out,props.media);
  Cal.pd({L:{d:30,mp:20},T:5,B:20,R:1},out,props.media);
  //cal.brd([{s:0,c:"black",rad:50,bar:{p:"l",s:0,c:"blue",rad:1}},{s:0,c:"blue",rad:0,bar:{p:"l",s:25,c:"red",rad:50}}],out,props.media);
  Cal.mr(30,out,props.media);
  Cal.shadow([null,{s:"-1px 0 6px",c:"black",a:0.7}],out,props.media);
  Cal.width(400,out,props.media);
  Cal.height(400,out,props.media);
  Cal.colors({bkg:"navy",text:"white",var:[["g",120],150],direction:0,},out,props.media);
  Cal.position(["in","c1",[1,200]],out,props.media);
  return (
      <div className={out.cl } style={out.style}>
          prova
      </div>
  );
}
*/
