import {styleVClass,isObj} from 'modules';



function calcMargin(mr,out) {
  const mrStyle = (side) => styleVClass(mr[side],out,"mr"+side,"px");
  if (isObj(mr) && !("d" in mr)) {
    if (mr.S) {
    mrStyle("S");
    } else {
      if (mr.T) mrStyle("T");
      if (mr.B) mrStyle("B");
    }
    if (mr.C) {
      mrStyle("C");
    } else {
      if (mr.R) mrStyle("R");
      if (mr.L) mrStyle("L");
    }
  } else {
    styleVClass(mr,out,"mr","px");
  }
}

export default calcMargin;
