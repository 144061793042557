import {useState,useContext,useReducer,useEffect} from 'react'; import {D,TopBar,Shared,inProduction} from 'modules';
 import Edit from './Edit/Edit'; import Select from './Select'; import {colorsReducer} from './reducer';

function FormTest(props) {
  const {media}= useContext(Shared);
  const [index,setIndex] = useState(0);
  const [view,setView] = useState("select"); // "edit","select"  select to choose a form to edit from the list of saved forms, edit toopen the edit view of the selected form
  const [colors,dispatchColor] = useReducer(colorsReducer,[]);
  const color = colors?.[index];
  const init = () => {
    if (inProduction) {
      const data = {method: 'POST', headers: {'Content-Type': 'application/json', },body: null,};
      fetch("/formColor/get", data).then((res) => res.json()).then((result)=>dispatchColor({fetched:result}), (error) =>  alert("fecth form error"));
    }
  };
  useEffect(init,[]);
  const saveElement = (i=index) => {
    if (inProduction) {
        const data = {method: 'POST', headers: {'Content-Type': 'application/json', },body: JSON.stringify(colors[i]),};
        fetch("/formColor/save", data).then((res) => res.json()).then((result)=>{console.log(result);dispatchColor({fetched:result})}, (error) =>  alert("fecth form save error"));
    }
  }
  const deleteElement = (i=index) => {
    if (inProduction) {
      const id = colors[i].id;
      if (id) { // the button has been alreadys aved to the server to we have to request to the server to delete it
        const data = {method: 'POST', headers: {'Content-Type': 'application/json', },body: JSON.stringify([id]),};
        fetch("/formColor/delete", data).then((res) => res.json()).then((result)=>dispatchColor({fetched:result}), (error) =>  alert("fecth error"));
      } else { // the button has not been saved to the server yet, so we have just to removed it locally splicing the buttons array
        colors.splice(i,1);
        dispatchColor({});
      }
    } else { // in developement
      colors.splice(i,1);
      dispatchColor({});
    }
    setView("select");
  }

  const deleteElements = (ids) => {
    if (ids.length) { // the button has been alreadys aved to the server to we have to request to the server to delete it
      const data = {method: 'POST', headers: {'Content-Type': 'application/json', },body: JSON.stringify(ids),};
      fetch("/formColor/delete", data).then((res) => res.json()).then((result)=>dispatchColor({fetched:result}), (error) =>  alert("fecth  error"));
    }
  }
  const add = () => {setView("edit");setIndex(colors.length);dispatchColor({add:true});}
  return (
    <D  a={{}} style={{"--top-bar-height":"65px"}}>
          <TopBar  bkg="blue" a={1} position="" />
          <Edit {...{color,dispatchColor,view,index,setView,saveElement,deleteElement}}/>
          <Select {...{colors,index,view,setView,setIndex,dispatchColor,add,deleteElements,deleteElement}}/>
    </D>
  );
}

export default FormTest;
