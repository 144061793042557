import {Pickers,Color} from 'modules';

function BrdPickers({activePicker,dispatchColor,button,settings}) {
  const {color,setColor} = brdSettlers(button,activePicker,dispatchColor);
  return  <Pickers {...{color,setColor,position:"relative",smallHexMenu:true}}/>
}

  function brdSettlers(button,activePicker,dispatchColor) {
    let color,setColor;
    if (activePicker === "Blur") {
      color = color = new Color({hex:button.brd.cl ?? button.text.cl ?? "#ffffff"});
      setColor = (color) => dispatchColor({row:"brd",col:"cl",value:color.hex});
    } else if (activePicker === "Hover") {
      color = new Color({hex:button.brd.clH ?? button.text.clH ?? "#ffffff"});
      setColor = (color) => dispatchColor({row:"brd",col:"clH",value:color.hex});
    } else if (activePicker === "Click") {
      color = new Color({hex:button.brd.click ?? button.text.cl ?? "#ffffff"});
      setColor = (color) => dispatchColor({row:"brd",col:"click",value:color.hex});
    } else if (activePicker === "Active") {
      color = new Color({hex:button.brd.active ?? button.text.clA ?? "#ffffff"});
      setColor = (color) => dispatchColor({row:"brd",col:"active",value:color.hex});
    }
    return {color,setColor};
}

export default BrdPickers;
