import { focusCalc} from "./calculators" ;
import {colToCss,calcNumClasses,cssInts,cssInt99, calcFloatClasses,Calc,mediaVal,isObj, def, defObj} from '../../../modules';


export function calcBkg(color) {
  // vars: --sm ->lHovBlur  --sm2->lHovErr, a,  plus-sign->lHovBlur, plus1-sign->lHovErr hsl,hsl1,hsl2->bkgblur,focus,error  hex,hex1,hex2,hex3->brd(blur,focus, err,hov)
  let {bkg, lBkg} = color;

  let lHovBlur, lHovErr;
  if (!isObj(lBkg)) {
    lHovBlur = lBkg ?? (color.var ? 0 : 10); lHovErr = lHovBlur;

  } else {
    lHovBlur = lBkg.b ?? (color.var ? 0 : 10);
    lHovErr = lBkg.e ?? lHovBlur;
  }
  const blurHovSign = lHovBlur >= 0 ? " plus-sign " : " minus-sign ";
  const errHovSign = lHovErr >= 0 ? " plus1-sign " : " minus1-sign ";
  lHovBlur = Math.abs(lHovBlur); lHovErr = Math.abs(lHovErr);
  let out = " a-"+(def(bkg.a, 1)).toString().replace(".","") + " ";
  if (!color.var) out += colToCss("hsl",bkg.b ?? "dodger blue") + " form-std-bkg "; // We add the blur bkg only if it's not a gradient because grandient are calculated in form Container
  if (bkg.f) out += colToCss("hsl1",bkg.f) + " form-focus-bkg ";
  if (bkg.e) out += colToCss("hsl2",bkg.e) + " form-error-bkg ";
  if (lHovBlur) out += calcNumClasses(lHovBlur,20,1,"sm");
  out += calcNumClasses(lHovErr,20,1,"sm2");
  out += (blurHovSign + errHovSign);

  return out;
}

export function calcBrd(color,brd={}) {
  let out = "";
  let border = color?.brd;
  if (brd.s || brd.bar) {
    border = brd.color ? brd.color : def(border,"dodger blue");
    let brdBlur, brdFocus, brdError, brdHover;
    if (!isObj(border)) {
      brdBlur = border; brdFocus = border; brdError = "red"; brdHover = border;
    } else {
      brdBlur = border.b ?? "dodger blue"; brdFocus = def(border.f, brdBlur); brdError = def(border.e, brdBlur); brdHover = def(border.h, brdBlur);
    }
    // Attewntion "hex3",brdHover) not yet implemented in Css
    out += (colToCss("hex",brdBlur ) + colToCss("hex1",brdFocus) + colToCss("hex2",brdError) + colToCss("hex3",brdHover));

  }
  return out;
}
