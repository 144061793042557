import React, {useState,useRef,useEffect} from 'react';
import {Div} from './modules';

const Logo = (props) => {
  const [aniRatio, setAniRatio]=useState(1);
  const animTimer = useRef(null);
  const logoAnimation = ()=> {
    if (aniRatio <= 0) {
    return;
    } else {
      animTimer.current = setTimeout(()=>setAniRatio(aniRatio-0.05),50);
    }
    return ()=> clearTimeout(animTimer.current);
  }
  useEffect(logoAnimation,[aniRatio]);

  return (
    <Div {...props.logoSettings} style={{"--col2":"#e93e3a"}} oClass="pointer">

    <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 833.69 225.09"  width="100%" className="svgtopbar">
      <defs>
        <linearGradient id="Orange_Yellow" data-name="Orange, Yellow" x1="3.51" y1="111.44" x2="938.11" y2="88.81" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="var(--col1)" />
          <stop offset="1" stop-color="var(--col2)"/>
        </linearGradient>
        <clipPath id="clippath">
          <rect x={189.34} width={365.88} height="102.98" fill="none" style={{transform:" translateX("+365.88*aniRatio+"px)"}}/>
        </clipPath>
      </defs>
      <g>
        <path d="M63.57,199.79s2.64-22.92,8.48-27.33c5.82-4.4,28.19,.97,32.54-2.01,5.25-3.58,2.86-17.15,3.05-17.57,.19-.43,2.22-2.39,3.4-5.49,.74-1.95-1.87-4.36-1.87-4.36,7.92-6.12-3.91-10.34-.51-12.22,7.15-3.99,10.07-5.54,9.52-8.72-.61-3.51-3.99-7.05-8.47-12.37-4.17-4.96-6.73-11.33-6.73-11.33-1.83-7.97,2.9-9.79,2.45-16.72-1.87-29.17-28.02-55-41.94-56.31-7.86-.74-16.15-.63-20.16,5.28-2.09,3.08-1.87,5.26-2.2,9.48,0,0,.12,125.03,.09,129.06-.09,9.43,.57,17.92,4,23.83,3.44,5.92,11.84,2.88,11.84,3.58v3.88l-9.48,.02-47.59,.14v-3.84c10.5,.42,10.34-1.74,12.05-4.18,2.45-3.48,2.58-6.64,2.64-10.16,.09-4.64,.18-135.38,.38-141.5,.14-4.55-.45-6.76-1.72-9.43-.42-.91-.94-1.88-1.52-3.01-2.29-4.43-11.83-3.38-11.83-3.38v-3.96s52.11-1.73,71,1.27c44.09,7.01,63.72,47.47,63.71,88.38-.01,46.32-31.65,92.37-71.14,88.94h0Z" fill="url(#Orange_Yellow)"/>
        <path d="M192.86,170.96c-9.29,12.44-19.45,15.09-24.1,15.09-17.71,0-28.45-15.56-28.45-33.44,0-10.58,3.48-20.38,9.44-27.22,6.1-7.31,14.08-11.35,21.63-11.35,12.63,0,22.21,11.67,22.21,24.11-.15,3.11-.58,4.51-2.9,5.13-2.9,.62-21.48,2.02-38.76,2.64-.44,20.84,11.47,29.4,21.77,29.4,5.95,0,11.47-2.64,16.84-7.93l2.32,3.58Zm-24.97-51.02c-6.68,0-13.21,6.69-15.24,19.29,8.13,0,16.26,0,24.82-.47,2.61,0,3.48-.78,3.48-3.11,.15-8.24-4.94-15.71-13.06-15.71Z" fill="url(#Orange_Yellow)"/>
        <path d="M235.67,184.18h-33.39v-4.35c9.14-.93,10.02-1.71,10.02-11.82v-32.35c0-10.27-.58-10.73-9-11.82v-3.89c7.11-1.25,13.64-3.11,20.47-6.38v17.11c5.08-8.09,11.18-16.64,18.43-16.64,5.37,0,8.42,3.58,8.42,7.47,0,3.58-2.32,7-4.94,8.56-1.45,.93-2.61,.78-3.77-.31-2.18-2.33-3.92-3.89-6.53-3.89-3.05,0-8.42,4.82-11.61,12.76v29.24c0,10.27,.73,11.04,11.9,11.98v4.35Z" fill="url(#Orange_Yellow)"/>
        <path d="M335.97,184.18v-4.35c8.56-.93,9.58-1.56,9.58-12.44v-25.98c0-11.04-3.48-18.04-12.48-18.04-5.52,0-10.6,3.27-16.11,8.09,.15,1.87,.44,3.73,.44,6.69v30.18c0,9.8,1.31,10.58,9.29,11.51v4.35h-31.06v-4.35c9-.93,10.31-1.56,10.31-11.82v-26.75c0-11.51-3.34-17.89-12.19-17.89-5.81,0-11.47,4.2-15.97,8.09v36.55c0,10.27,1.02,10.89,9.29,11.82v4.35h-31.21v-4.35c9.58-.93,10.45-1.56,10.45-11.82v-32.2c0-9.96-.58-10.58-8.71-12.13v-3.89c6.82-1.09,13.64-3.11,20.18-6.22v12.6c3.05-2.49,6.1-5.13,10.74-8.09,3.63-2.49,6.82-4.04,11.47-4.04,6.97,0,12.92,4.67,15.82,12.76,4.06-3.42,7.84-6.07,11.47-8.71,3.19-2.18,7.26-4.04,11.47-4.04,11.32,0,18.29,8.71,18.29,23.95v30.02c0,10.42,.87,10.89,9.14,11.82v4.35h-30.19Z" fill="url(#Orange_Yellow)"/>
        <path d="M421.47,186.04c-2.18,0-5.37-1.25-6.82-2.96-1.89-2.02-2.76-4.2-3.48-7-5.81,4.2-12.92,9.96-17.42,9.96-10.31,0-17.71-9.18-17.71-19.13,0-7.62,3.92-12.6,11.9-15.56,8.85-3.27,19.74-7.31,22.93-10.11v-3.11c0-11.04-5.23-17.42-12.77-17.42-3.34,0-5.37,1.71-6.82,3.58-1.6,2.18-2.61,5.6-3.92,10.11-.73,2.49-2.03,3.58-4.21,3.58-2.76,0-6.39-3.11-6.39-6.84,0-2.18,1.89-4.04,4.79-6.22,4.21-3.27,12.63-9.02,20.9-10.89,4.35,0,8.85,1.4,12.19,4.2,5.08,4.67,7.4,9.96,7.4,18.04v30.02c0,7.16,2.47,9.33,5.08,9.33,1.74,0,3.63-.78,5.23-1.71l1.45,4.35-12.34,7.78Zm-10.6-39.35c-3.19,1.71-10.16,4.98-13.5,6.53-5.52,2.64-8.85,5.6-8.85,11.35,0,8.24,5.81,11.98,10.45,11.98,3.77,0,9-2.49,11.9-5.6v-24.27Z" fill="url(#Orange_Yellow)"/>
        <path d="M466.9,184.02c-2.47,1.4-4.79,2.02-6.24,2.02-9.29,0-14.23-6.07-14.23-18.51v-45.27h-10.02l-.58-1.87,3.92-4.51h6.68v-13.07c2.76-3.11,6.97-7.16,9.73-10.27l2.18,.31c-.29,6.53-.44,15.56-.44,23.02h16.55c1.31,1.56,.87,4.98-.87,6.38h-15.68v39.82c0,12.6,4.64,14.93,8.42,14.93,3.48,0,6.82-1.4,8.85-2.49l1.31,4.04-9.58,5.44Z" fill="url(#Orange_Yellow)"/>
        <path d="M552.82,184.18v-5.6c9.29-1.2,10.45-2.2,10.45-15.4V71.04c.18-11.35-1.26-13.36-9.92-13.3l-.05-10.53c7.84-1.2,16.79-4.39,21.44-6.79v122.77c0,13.2,.87,14.2,10.31,15.4v5.6h-32.22Z" fill="url(#Orange_Yellow)"/>
        <path d="M632.13,179.55c-.01,.08-.02,.15-.04,.22,.19-.14,.4-.3,.59-.46-.18,.09-.36,.17-.55,.24Zm0,0c-.01,.08-.02,.15-.04,.22,.19-.14,.4-.3,.59-.46-.18,.09-.36,.17-.55,.24Zm0,0c-.01,.08-.02,.15-.04,.22,.19-.14,.4-.3,.59-.46-.18,.09-.36,.17-.55,.24Z" fill="url(#Orange_Yellow)"/>
        <path d="M687.52,161.47c-3.48,2.96-4.79,5.91-4.79,7.78,0,2.8,2.47,5.91,9,5.91,5.37,0,11.47-.16,16.26-.16,9.29,0,21.19,3.27,21.19,18.67,0,16.18-17.85,31.42-37.16,31.42-16.4,0-25.26-10.27-25.55-19.44,0-3.42,1.31-6.38,3.63-8.87,2.9-3.27,8.56-8.4,11.9-11.2-5.22-1.55-9-5.13-10.89-8.4-1.31-2.33-1.74-5.13-1.74-6.69,5.52-2.18,10.45-6.53,13.36-10.73l4.79,1.71Zm6.97,1.56c-13.5,0-24.68-8.87-24.68-23.49,0-17.73,14.81-25.98,26.13-25.98,4.79,0,9.58,1.4,13.93,3.89,6.68,0,15.39-1.24,19.45-2.33l.87,1.4c-.87,2.49-3.19,6.84-5.37,8.24-2.18,0-6.97-.62-9-.78,2.47,3.58,4.21,8.55,4.21,14.15,0,16.95-13.06,24.89-25.55,24.89Zm3.34,23.02c-7.11,0-10.31,.93-12.92,3.27-4.06,3.58-6.97,8.24-6.97,12.75,0,8.56,8.27,14.93,19.45,14.93,13.79,0,21.63-7.62,21.63-17.58,0-5.29-2.32-9.49-6.39-11.36-3.63-1.71-8.71-2.02-14.81-2.02Zm-3.34-67.51c-6.53,0-12.34,6.38-12.34,18.67s5.81,20.84,13.79,20.84c6.39-.31,12.05-6.07,12.05-18.67s-5.37-20.84-13.5-20.84Z" fill="url(#Orange_Yellow)"/>
        <path d="M736,184.18v-4.35c9.14-.93,10.16-1.71,10.16-12.29v-32.04c0-9.64-.44-10.27-9.14-11.82v-3.73c7.55-1.4,14.37-3.42,20.61-6.22v53.82c0,10.58,1.02,11.35,10.45,12.29v4.35h-32.08Zm15.1-85.71c-4.06,0-7.69-3.89-7.69-8.24,0-4.98,3.63-8.56,7.84-8.56s7.4,3.58,7.4,8.56c0,4.36-3.34,8.24-7.55,8.24Z" fill="url(#Orange_Yellow)"/>
        <path d="M821.35,186.04c-2.18,0-5.37-1.25-6.82-2.96-1.89-2.02-2.76-4.2-3.48-7-5.81,4.2-12.92,9.96-17.42,9.96-10.31,0-17.71-9.18-17.71-19.13,0-7.62,3.92-12.6,11.9-15.56,8.85-3.27,19.74-7.31,22.93-10.11v-3.11c0-11.04-5.23-17.42-12.77-17.42-3.34,0-5.37,1.71-6.82,3.58-1.6,2.18-2.61,5.6-3.92,10.11-.73,2.49-2.03,3.58-4.21,3.58-2.76,0-6.39-3.11-6.39-6.84,0-2.18,1.89-4.04,4.79-6.22,4.21-3.27,12.63-9.02,20.9-10.89,4.36,0,8.85,1.4,12.19,4.2,5.08,4.67,7.4,9.96,7.4,18.04v30.02c0,7.16,2.47,9.33,5.08,9.33,1.74,0,3.63-.78,5.23-1.71l1.45,4.35-12.34,7.78Zm-10.6-39.35c-3.19,1.71-10.16,4.98-13.5,6.53-5.52,2.64-8.85,5.6-8.85,11.35,0,8.24,5.81,11.98,10.45,11.98,3.77,0,9-2.49,11.9-5.6v-24.27Z" fill="url(#Orange_Yellow)"/>
        <path d="M632.68,179.31c-.19,.17-.4,.32-.59,.46,.01-.06,.02-.14,.04-.22,.19-.08,.37-.15,.55-.24Z" fill="url(#Orange_Yellow)"/>
        <path d="M632.68,179.31c-.19,.17-.4,.32-.59,.46,.01-.06,.02-.14,.04-.22,.19-.08,.37-.15,.55-.24Z" fill="url(#Orange_Yellow)"/>
        <path d="M624.41,110.68c-13.79,0-32.22,11.98-32.22,37.33,0,17.42,12.2,34.7,32.22,34.7,14.22,0,31.65-11.98,31.65-37.19,0-20.06-13.5-34.84-31.65-34.84Zm21.7,49.33s.87,.83,.62,1.5c-.4,1.08-1.07,1.75-1.14,1.9-.06,.15,.97,5.34-1.01,6.09-3.64,1.89-4.59-.83-8.5,2.01-8.14,6-16.71,7.02-27-2.87,0-.01-.01-.01-.01-.01-4.63-5.38-7.53-13.17-7.53-21.87s2.82-16.26,7.32-21.62c4.01-4.78,9.38-7.7,15.25-7.7,4.41,0,8.54,1.66,12.02,4.51,.06,.08,.12,.17,.18,.26,4.04,3.51,8.12,9.78,8.54,16.58,.16,2.4-1.41,3.03-.81,5.79,0,0,.85,2.2,2.24,3.92,.12,.14,.24,.28,.35,.42,1.32,1.61,2.29,2.75,2.48,3.86,.18,1.09-.79,1.63-3.18,3-1.14,.65,2.8,2.12,.17,4.24Z" fill="url(#Orange_Yellow)"/>
        <path d="M514.41,110.68c-13.79,0-32.22,11.98-32.22,37.33,0,17.42,12.2,34.7,32.22,34.7,14.22,0,31.65-11.98,31.65-37.19,0-20.06-13.5-34.84-31.65-34.84Zm21.7,49.33s.87,.83,.62,1.5c-.4,1.08-1.07,1.75-1.14,1.9-.06,.15,.97,5.34-1.01,6.09-3.64,1.89-4.59-.83-8.5,2.01-8.14,6-16.71,7.02-27-2.87,0-.01-.01-.01-.01-.01-4.63-5.38-7.53-13.17-7.53-21.87s2.82-16.26,7.32-21.62c4.01-4.78,9.38-7.7,15.25-7.7,4.41,0,8.54,1.66,12.02,4.51,.06,.08,.12,.17,.18,.26,4.04,3.51,8.12,9.78,8.54,16.58,.16,2.4-1.41,3.03-.81,5.79,0,0,.85,2.2,2.24,3.92,.12,.14,.24,.28,.35,.42,1.32,1.61,2.29,2.75,2.48,3.86,.18,1.09-.79,1.63-3.18,3-1.14,.65,2.8,2.12,.17,4.24Z" fill="url(#Orange_Yellow)"/>
      </g>
      <g clip-path="url(#clippath)">
        <rect x="410.97" y="47.21" width="142.41" height="10.53" fill="url(#Orange_Yellow)"/>
        <g>
          <path d="M411.7,51.48c0,28.44-49.77,51.49-111.18,51.49s-111.18-23.06-111.18-51.49S239.12,0,300.52,0s111.18,23.05,111.18,51.48Z" fill="url(#Orange_Yellow)"/>
          <path d="M402.13,51.48c0,24.26-45.49,43.93-101.61,43.93s-101.61-19.67-101.61-43.93S244.41,7.56,300.52,7.56s101.61,19.66,101.61,43.92Z" fill="#d5eff6"/>
          <g>
            <path d="M261.54,50.76c-1.81-1.51-4.03-2.6-6.66-3.3v-.15c2.23-.68,4.16-1.66,5.78-2.93,1.63-1.26,2.43-3.05,2.43-5.37,0-1.93-.55-3.54-1.67-4.86-1.12-1.31-2.6-2.37-4.44-3.19-1.84-.81-3.97-1.41-6.36-1.78-2.41-.38-4.89-.56-7.46-.56h-26.22v1.33c1.56,0,2.34,.44,2.34,1.34v33.65c0,.89-.78,1.33-2.34,1.33v1.33h30.74c2.63,0,4.98-.26,7.05-.78,2.06-.52,3.81-1.25,5.24-2.19,1.42-.93,2.49-2.04,3.22-3.3,.73-1.26,1.09-2.63,1.09-4.11,0-2.82-.91-4.99-2.73-6.49Zm-32.72-15.75h16.01c2.5,0,4.59,.29,6.23,.86,1.65,.56,2.47,1.77,2.47,3.6s-.82,3.02-2.47,3.59c-1.65,.57-3.73,.86-6.23,.86h-16.01v-8.9Zm23.59,24.35c-1.54,.96-4.07,1.45-7.58,1.45h-16.01v-10.53h16.01c3.51,0,6.04,.48,7.58,1.44,1.54,.96,2.31,2.23,2.31,3.82s-.77,2.86-2.31,3.82Z" fill="#8264ab"/>
            <path d="M314.7,65.85c-.34-.2-.64-.52-.92-.96l-13.57-25.2c-.28-.54-.54-1.02-.79-1.44-.25-.42-.38-.78-.38-1.07,0-.35,.1-.54,.29-.6,.2-.05,.38-.12,.54-.23v-1.33h-12.65v1.33c.17,.11,.33,.2,.47,.26,.14,.08,.21,.26,.21,.56,0,.39-.1,.8-.29,1.21-.2,.42-.46,.99-.79,1.67l-12.49,24.09c-.23,.4-.54,.83-.96,1.3-.41,.47-.96,.76-1.64,.86v1.33h11.23v-1.33c-.5,0-.89-.05-1.17-.15-.28-.1-.42-.35-.42-.74,0-.25,.08-.53,.22-.86,.13-.33,.31-.68,.54-1.07l2.34-4h17.93l2.27,4.22c.16,.4,.3,.73,.41,.96,.12,.25,.17,.52,.17,.82,0,.39-.15,.66-.47,.81-.3,.14-.6,.22-.88,.22v1.12h11.9v-1.33c-.39-.1-.76-.25-1.09-.44Zm-27.73-11.86l5.95-12.46,6.79,12.46h-12.74Z" fill="#8264ab"/>
            <path d="M362.67,65.66c-.53-.27-1.02-.68-1.46-1.22l-8.22-10.6c1.18-.25,2.32-.61,3.44-1.07,1.12-.47,2.14-1.08,3.06-1.85,.92-.77,1.67-1.67,2.22-2.71,.56-1.03,.83-2.22,.83-3.56,0-3.5-1.39-5.98-4.18-7.45-2.8-1.45-6.68-2.19-11.64-2.19h-23.12v1.33c1,0,1.51,.38,1.51,1.12v27.72c0,.75-.51,1.12-1.51,1.12v1.33h11.13v-1.33c-.33,0-.68-.09-1.04-.26-.37-.17-.54-.46-.54-.86v-10.6h11.64l6.61,8.75c.85,1.04,1.26,1.81,1.26,2.3,0,.44-.36,.67-1.08,.67v1.33h12.81v-1.33c-.61-.15-1.18-.36-1.71-.63Zm-16.13-16.58h-13.24v-8.6h13.24c2.85,0,4.93,.36,6.24,1.07,1.31,.73,1.97,1.7,1.97,2.94,0,3.06-2.74,4.59-8.22,4.59Z" fill="#8264ab"/>
            <path d="M388.52,36.34v-1.33h-12.73v1.33c.89,0,1.51,.09,1.84,.26,.33,.17,.5,.47,.5,.86v27.72c0,.4-.17,.68-.5,.86-.34,.17-.95,.26-1.84,.26v1.33h12.73v-1.33c-1.56,0-2.34-.37-2.34-1.12v-27.72c0-.74,.78-1.12,2.34-1.12Z" fill="#8264ab"/>
          </g>
        </g>
      </g>
    </svg>
    </Div>
  );
}

export default Logo;
