import {SvgIcon,isObj} from '../../../modules';

export function SelectOption(props) {
    const {option} = props;
    const name = option[0];
    const value = option[1];
    const icon =  option?.[2] ?? props.defaultIcon ?? null;
    const isSelected = (value === props.value);
    const id = isSelected ? props.random : null;  // we need this id in order to scroll to the selected element in parent component use effect
    const activeClass = isSelected ? " no-hover select-option-active p-rel " : "";
    const noBorder = props.noBorder ? " no-brd " : "";
    function handleClick(e) {
        e.stopPropagation();
        if (isSelected) {
          return null;
        } else {
          props.handleClick(value);
        }

    }

    return (
        <div className={"div-select-option-item flex-cen-start pointer  a-flex-col "+activeClass+noBorder}  id={id} onClick={handleClick}>
                <SvgIcon icon={icon} sizeClass="div-select-icon-size" colorClass="div-select-icon-color" />
                <div className="truncate">{name}</div>
                <SvgIcon icon="done" noShow={!isSelected} color={props.checkColor ?? "green"}  className="div-select-acive-check" />
        </div>
    );
}
