import  {createContext} from 'react';
import SvgIcon from './Svg/SvgIcon'; import Link from './Link';
import Tooltip from './css/Tooltip/Tooltip';import { useMediaQuery } from 'react-responsive'; import svg from './Svg/svg.js';

export {calcButtonColors,calcBtnCssSize} from './Buttons/';
export * from './css/Calc/Div';  export * from './helpers';  export * from './forms/forms';
export * from './css/ClassesCalculators/calc'; export {SvgIcon, Link, Tooltip, useMediaQuery, svg};
export {calcCssClasses, calcCssClass} from './css/ClassesCalculators/calc_helpers'; export {calcSvgColors} from './Svg/calc.js';
export {calcGradientDirection, calcComponent, calcColorStops} from './css/Calc/functions/colorsHelper';
export * from './Positioned/SlidingPanel/SlidingPanel'; export {TopBar} from './TopBar/TopBar'; export * from './Slider/Slider'; export * from './Rotator/HeroRotator';
export const Shared = createContext({}); export {Cal} from './css/cal/Cal';
export * from './css/cal/D'; export {useViewport} from './Hooks/useViewport'; export * from "./css/cal/helpers"; export * from './css/';
export const Style = ({sheet}) => sheet ? <style>{sheet}</style> : null; export {FlexCols} from './css/cal/FlexCols/FlexCols';export * from './Buttons/';
export * from 'Colors';

/*
export {Button} from './Buttons/Button'; export {Link} from './Link'; export {SvgIcon} from './SvgIcon';
*/

/*
import  {createContext} from 'react';
export const Shared = createContext({});

*/
