import { focusCalc} from "./calculators" ;
import {calcNumClasses,Calc,isObj, def} from '../../../modules';
import {calcBkg,calcBrd} from './bkg'; import {calcBoxShadow} from './boxShadow';

const calcBkgContainer = (size, media, color, label, brd={},formType) => {
  // Vars b,c,d,f, sm1,xs
  // calc bkg vars: sm,sm2,plus-sign plus1-sign,a, hsl,hsl1,hsl2 hex,hex1,hex2,hex3
  // vars --xs4,xs5,xs6,xs7  a1-  a-rgb1- Box Shadow
  // brd rad vars: xs9,xs10
  
  let out = {shared:" form-bkg-container ", blur:"",focus:"",shadow:{}};
  if (formType === "textArea") {
    out.shared += " heigh-var-auto ";
  } else if (Array.isArray(size.height)) {
    out.blur += Calc.varHeight(size.height[0],"f");  // var f
    out.focus += Calc.varHeight(size.height[1],"f");  // var f
  } else {
    out.shared += Calc.varHeight(size.height ?? 30,"f");  // var f
  }
  if (Array.isArray(size.font)) {
    out.blur += Calc.fontSize(size.font[0]?.i ?? size.font[0] ?? 14);
    out.focus += Calc.fontSize(size.font[1]?.i ?? size.font[1] ?? 14);
  } else {
    out.shared += Calc.fontSize(size.font?.i ?? size.font ?? 14);
  }
  if (Array.isArray(size.weight)) { // xs
    out.blur += Calc.weight(size.weight[0]?.i ?? size.weight[0]);
    out.focus += Calc.weight(size.weight[1]?.i ?? size.weight[1]);
  } else {
    out.shared += Calc.weight(size.weight?.i ?? size.weight);
  }
  if (size.lh) out.shared += Calc.lh(size.lh); // sm1
  out.shared += calcBkg(color);   // vars: sm1,sm2,plus-sign, plus1-sign,hsl,hsl1,hsl2 hex1,hex1,hex2,hex3
  out.shared += calcBrd(color,brd);   // vars: sm1,sm2,plus-sign, plus1-sign,hsl,hsl1,hsl2 hex1,hex1,hex2,hex3
  calcBrdRad(brd,out);  // Vars xs9,xs10

  if (brd.shadow) calcBoxShadow(brd.shadow,out.shadow); // vars --xs --xs1  a1-, --xs2, a-rgb1-

  return out;
}

const calcBrdRad = (brd,out) => {
  // Vars x7,x8
  if (brd?.bar && brd.bar?.rad) {
    const barPosition = brd?.bar && brd?.bar?.p && ["l","r","t","b"].includes(brd.bar.p) ? brd.bar.p : "l";
    if (Array.isArray(brd.bar.rad)) {
      out.blur += calcNumClasses(brd.bar.rad[0],10,5,"xs9")  + ` form-${barPosition}Bar ` ;
      out.focus += calcNumClasses(brd.bar.rad[1],10,5,"xs9") + ` form-${barPosition}Bar ` ;
    } else {
      out.shared += calcNumClasses(brd.bar.rad,10,5,"xs9") + ` form-${barPosition}Bar ` ;
    }
  }
  if (Array.isArray(brd.rad)) {
    out.blur += calcNumClasses(brd.rad[0],10,5,"xs10")  + (brd.bar?.rad ? "": " form-brd-rad ");
    out.focus += calcNumClasses(brd.rad[1],10,5,"xs10")  + (brd.bar?.rad ? "": " form-brd-rad ");
  } else {
    out.shared += calcNumClasses(brd.rad??0,10,5,"xs10")  + (brd.bar?.rad ? "": " form-brd-rad ");
  }


}


export default calcBkgContainer;
