import {isObj,Cal,addStyles,addStylesProp,calcCss,addPx,addVw,calcFont,hasValue,isNumber} from 'modules';

export function calcDropRow(row,out) {
  if (!isObj(out)) return null;
  // Vars: d,e,g,xs,xs2,sm1
  const {pdC,pdS,lh,height,icon,gap,brd,brdRad,weight} = row;
  calcFont(row?.fSize,out,);  // Row Font size
  calcFont(row?.expArrow,out,"--expArrowSize");  // Expander drop arrow size
  addStyles(pdC,"rowContainer",out);
  if (pdS) {
    addStyles(pdS,"rowPdS",out);
    addStyles("auto","rowHeight",out); // when we set pdS we need to set height to auto toa void the default value of 2em
  } else if (height) {
    addStyles(height,"rowHeight",out);
  }
  if (icon) addStyles(icon,"btnIconSize",out);
  if (gap) addStyles(gap,"drRowGap",out);
  if (brd) addStyles(brd,"dropRowBrd",out," drop-btn-brd-bottom ");
  addStyles(brdRad,"rowBrdRad",out,null,null,true);
  if (weight) {
    if (!isObj(weight)) {
      out.cl += Cal.we(weight);
    } else {
      addStylesProp(weight,"font-weight",out,true)
    }
  }
  if (lh) {
    if (!isObj(lh) && isNumber(lh)) { // if lh is not a number but contains a unit like px we calculate as a style below
      out.cl += Cal.lh(lh);
    } else {
      addStylesProp(lh,"line-height",out,true)
    }
  }

}
