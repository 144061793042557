import {useMemo,forwardRef} from 'react';import {Color} from 'Colors/';import './background.css';

const Background = forwardRef(({color,click},ref) => {
  const background = useMemo(()=>Color.hslToCss({h:color.hsl.h, s:100,l: 50, a: 1}),[color.hsl.h, color.a]);  // changes the background color only when the H compoment chanages from props
  return (
    <div className="col-color-rect-bl-wh"  style={{background:background,opacity:color.a}} ref={ref}>
       <div className="col-color-rect-white-grad" >
           <div className="col-color-rect-black-grad" onClick={(e)=>click(e, true)}  >
           </div>
       </div>
    </div>
  )
});

export default Background;
