import React, {useMemo,useContext} from 'react';import {BtnContext} from 'Buttons/';
import {Link,SvgIcon,colToCss,addPc,isObj} from 'modules'; import './dropRow.css';import './dropIcons.css';

// <div className=""></div>

function DropLi({meta,icon,iconColor,admin,user,href,click,text,multiDrop}) {
  const {closeDropDown,handler,dropMenu,adminLogged,userLogged,dropContainers} = useContext(BtnContext);
  const {defaultIcon} = multiDrop;
  const {customIconColorClass,iconStyle} = useMemo(()=> icon || defaultIcon ? calcIconColor(iconColor) : "",[iconColor]);
  const handleClick = click ? ()=>{click();closeDropDown();} : (meta && handler ? ()=>{handler(meta);closeDropDown();} : null);
  const iconColorClass = "drop-row-icon-color "+(dropContainers?.rowIconContainer??"") + (customIconColorClass ?? "");
  const iconProps = {colorClass:iconColorClass};
  if (iconStyle) iconProps.style = iconStyle;
  if ((admin && !adminLogged) || ((user === true) && !userLogged ) || ((user === "no") && userLogged )) { // if it's an admin button and the user is not an admin the nutton will be hidden
    return null;
  } else {
    return (
        <Link href={href} click={handleClick} className={"a-btn-drop-row flex-cen-start "+(dropContainers?.rowContainer??"")}>
          <SvgIcon icon={icon ?? defaultIcon} sizeClass="drop-row-icon-size no-shrink" {...iconProps} />
          <div className={dropMenu.noTruncate?"":"truncate"}>{text}</div>
        </Link>
    );
  }
}

export default DropLi;

function calcIconColor(iconColor) {
  let out = {};
  if (isObj(iconColor) && !iconColor.lTxt && iconColor.b) {
     out.customIconColorClass += colToCss("hex2",iconColor.b) + colToCss("hex3",iconColor.h ?? iconColor.b) +  " a-btn-icon-custom-col a-btn-icon-custom-col-hov ";
  } else if (isObj(iconColor) && iconColor.lTxt) {
     out.customIconColorClass += colToCss("hsl2",iconColor) + " a-btn-icon-custom-lTxt ";;
     out.iconStyle = {"--icon-lTxt":addPc(iconColor.lTxt)};
  }else if (iconColor) {
    out.customIconColorClass += colToCss("hex2",iconColor) + colToCss("hsl2",iconColor) + " a-btn-icon-custom-col "; // we add both hex2 and hsl2 because if there is text on hover hsl2 will be used to add opacity effect
  }
  return out;
}
