import {calcNumClasses,SvgIcon,isObj,Calc} from '../../../modules';


export default function ButtonIcon({containers,icon,rightIcon,isActive,btnClicked}) {
  if (!icon) {
    return null;
  } else {
    const {colors} = containers;
    const iconColors = colors.icon?.a && isActive ? colors.icon?.a : ((btnClicked  && colors.icon?.c) ? colors.icon?.c : colors.icon?.b);
    const order = rightIcon ? "flex-3 " : "flex-1 ";
    return   <SvgIcon icon={icon} sizeClass={order} colorClass={"icon-btn-color "+iconColors} />;
  }
}
