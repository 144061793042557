import {cssInts} from '../../../modules';
import {isObj} from '../../../helpers.js';

function calcMargin(margin) {
    // Vars: g,m,n,t -> margin
  if (!margin && (margin !== 0)) return "";
  let out = "";
  if (isObj(margin) && !("m" in margin)) {
    if (margin.s) {
      out += cssInts(margin.s,"g", "t") + ` a-mrS `;
    } else {
      if (margin.t) out += cssInts(margin.t,"g", "t") + " a-mrT ";
      if (margin.b) out += cssInts(margin.b,"n", "b") + " a-mrB ";
    }
    if (margin.c) {
      out += cssInts(margin.c,"m", "l") + ` a-mrC `;
    } else {
      if (margin.r) out +=  cssInts(margin.r,"m", "r") + " a-mrR ";
      if (margin.l) out +=  cssInts(margin.l,"t", "l") + " a-mrL ";
    }
  } else if (Array.isArray(margin)) {
    out += " a-mr-4 ";
    if (margin.length === 4) {
      out += cssInts(margin[0],"g", "t");
      out += cssInts(margin[1],"m", "r");
      out += cssInts(margin[2],"n", "b");
      out += cssInts(margin[3],"t", "l");
    } else if (margin.length === 2) {
      out += cssInts(margin[0],"g", "t");
      out += cssInts(margin[1],"m", "r");
      out += cssInts(margin[0],"n", "b");
      out += cssInts(margin[1],"t", "l");
    }
  } else if (Number.isInteger(margin) || (typeof margin === "string") || (isObj(margin) && ("l" in margin))) {
    out +=  cssInts(margin,"g", "t") + " a-mr-1 ";
  }
  return out;
}

export default calcMargin;
