import {isObj,isString,mediaVal,hasValue} from '../../modules';

export function calcStyle(val, out, className,media,stylePref) {
  const value = media ? mediaVal(val,media) : val;
  if (!hasValue(value)  || !out) {
    return;
  } else {
    stylePref = stylePref ?? className;
    const style = out.style;
    if (className) out.cl += ` ${className} `  ;
    if (!isObj(value)) {
       style["--"+stylePref] = addPx(value);
    } else {
      // Problem to fix, we need to add a class to every display, otherwise for example if we skip value.t, we expect value.d to be used but intsead if a parent defined that var for the same display size that will be used instead.
       style["--"+stylePref] = addPx(value.d);
      if (value.t) {
        style["--"+stylePref+"-t"] = addPx(value.t);
      }
      if (value.xt) {
        style["--"+stylePref+"-xt"] = addPx(value.xt);
      }
      if (value.mp) { // We don't add a class for ml becuase we always set ml when mp is set, but the value is not compulsory, if ml is not set we copy the mp value. We need to set ml to avoid inheriting the variable from an higher component when the default value should be mp
        style["--"+stylePref+"-mp"] = addPx(value.mp);
        style["--"+stylePref+"-ml"] = addPx(value.ml ?? value.mp);
      }
      if (value.xm) {
       style["--"+stylePref+"-xm"] = addPx(value.xm);
      }
    }
  }

}


// arr = [value,varName] || [[value,varName] ,[value,varName] ,[value,varName] ,[value,varName] ]  -> var name is the intended value of the css variable without -- prefix

export function addStyle(arr,styleObj,media) {
  if (Array.isArray(arr?.[0])) {
    for (let i=0; i<arr.length;i++) {
      addStyleRow(arr[i],styleObj,media);
    }
  } else {
    addStyleRow(arr,styleObj,media);
  }

}

function addStyleRow(row,styleObj,media) {
  const value = mediaVal(row?.[0],media);
  const stylePref = row?.[1];
  const noPx = row?.[2];
  if ((value === null)  || !styleObj || !stylePref) {
    return;
  } else {
    styleObj["--"+stylePref] = addPx(value,noPx);
  }
}

const addPx = (val,noPx) => (isString(val) || noPx) ? val : val+"px" ;
