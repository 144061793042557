import {calcCssClass, calcCssClasses, colToCss,cssInts,calcNumClasses} from '../../../modules';
import {isObj} from '../../../helpers.js';

const calcBtnShadow = (shadow,colorPref) => {

  //  Vars: xs4,xs5,xs6,xs7,a1,hsl2
  let out = " ";
  if (shadow === true) {
    out = " a-shadow-fb ";
  } else if (isObj(shadow)){
    const size = shadow.s;
    if (!size) return out;
    let blur = size;
    if (shadow.bm) {
      blur = shadow.bm;
      out += " shadow-blur-multiply ";
    } else if (shadow.ba) {
      blur = shadow.ba;
      out += " shadow-blur-add ";
    }
    out += calcNumClasses(size,10,1,"xs4");
    out += calcNumClasses(blur,10,1,"xs5");
    out += (" a1-"+(shadow.a ?? 0.3).toString().replace(".","")+" ");
    const shaColor = shadow.c ?? "black";
    out += colToCss(colorPref,shaColor);
    const hover = shadow.hover;
    if (hover) {
      if (hover.only) {
        out += ` a-shadow-hover-${colorPref} `;
      } else {
        out += ` a-shadow-${colorPref} `;
        if (hover.add) out += (" a-shadow-hover-inc " + calcNumClasses(hover.add,10,1,"xs6"));
      }
    } else {
      out += ` a-shadow-${colorPref} `;
    }

  }
  return out;
}


export default calcBtnShadow;
