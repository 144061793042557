/*import React, {useContext} from 'react'; import {Shared} from '.../modules';ocnst {media} = useContext(Shared);*/
import './hero.css'; import Bkg from './Bkg'; import Front from './Front/Front';

function Hero(props) {
    return (
        <div className="h100 flex-cen-cen p-rel w100 a-font-white">
            <Bkg {...props.bkg}/>
            <Front {...props}/>
        </div>
    );
}

export default Hero;
