import {isObj,colToCss,addPc} from 'modules';


export function calcIconColor(colors,isGradient,out) {
  // Vars  hex,hex1,hex2,hex3,hsl2
  iconColor(colors.b,colors.h,out,"b");
  if (colors.c) iconColor(colors.c,null,out,"c"); // Click color can be set just with lClick, in that case we have to use the same text and bkg defined in the blur color
  if (colors.a) iconColor(colors.a,null,out,"a");
}

function iconColor(color,hover,out,type) {
  if (color.icon) {
     if (hover?.lIcon && !hover?.icon) {
        out[type] +=  colToCss("hsl",color.icon) + " icon-btn-custom-hsl ";
        out.style[type]["--lIcon"] = addPc(hover.lIcon);
      } else {
        out[type] +=  colToCss("hex",color.icon) + " icon-btn-custom-hex ";
        if (hover?.icon) out[type] += colToCss("hex1",hover.icon) + " icon-btn-custom-hover-color ";
      }
  }
}
