import {calcCssClass, calcCssClasses, colToCss,isObj,mediaVal,def,defOb,Calc} from '../../../modules';import {defColors, defSizes} from "../defs.js";



const focusCalc = (i, focus, def=null) => (!i && i!== 0) ? def : ((!Array.isArray(i) || (i.length !== 2)) ? i : (focus && i[1] ? i[1] : i[0]));

function calcCoveringLabel(props) {
  // Vars: hex ->blurColor
  const weight = focusCalc(props.size.weight, false);
  const font = focusCalc(props.size.font, false);
  const container = focusCalc(props.size.container, false);
  let cont = " ";
  const clSize = (isObj(font) && font.cL) ? font.cL : null;
  const clWeight = (isObj(weight) && weight.cL) ? weight.cL : null;
  const clContainer = (isObj(container)) ? container.cL ?? container.i : container;
  if (clSize) cont += Calc.fontSize(clSize);
  if (clWeight) cont += Calc.weight(clWeight);
  cont += Calc.padding({c:def(clContainer,16)});
  const blurColor = props.color.cLabel && props.color.cLabel.b ? props.color.cLabel.b : props.color.input.b;
  cont += colToCss("hex",blurColor);
  return cont;
}


function calcTextColor(col,isOuterLabel) {
  // Used for label, input and all kind of text. Col is a generic input, can be label, coveringLabel, input...
  // Vars used: hex,hex1,hex2,hex4
  let blur, focus, error, hover;
  if (!isObj(col)) {
    blur = col; focus = col; error = col; hover = col;
  } else {
    blur = col.b ?? "black"; focus = col.f ?? blur; error = col.e ?? blur; hover = col.h ?? blur;
  }
  let out = " ";
  out += colToCss("hex",blur) + colToCss("hex1",focus) + colToCss("hex2",error) + colToCss("hex3",hover);
  if (isOuterLabel && col?.bkg) out += colToCss("bkg",col?.bkg);
  return out;
}

function calcLabel(props) {
  const labelPosition = props.labelPosition;
  let out = {shared:"frm-label-color frm-label frm-label-"+labelPosition,focus:"",blur:""}
  const {size, color} = props;
  if (size.weight?.l || size.weight?.[0]?.l) {
    if (Array.isArray(size.weight) ) { // xs
      out.blur += Calc.weight(size.weight[0].l);
      out.focus += Calc.weight(size.weight[1].l);
    } else {
      out.shared += Calc.weight(size.weight.l);
    }
  }
  if (Array.isArray(size.pd?.c)) { // xs
      out.blur += Calc.padding({c:size.pd?.c[0]?.l ?? size.pd?.c[0]});
      out.focus += Calc.padding({c:size.pd?.c[1]?.l ?? size.pd?.c[1]});
    } else {
      out.shared += Calc.padding({c:size.pd?.c?.l ?? size.pd?.c});
  }
  out.shared += calcTextColor(color.label,labelPosition === "out");
   // if has not been set a font weight we don't add a default one because it will be inherited via css from form contaiunber
  if ((labelPosition === "left") && props.labelMaxWidth && !props.labelWidth){
    if (Array.isArray(props.labelMaxWidth)) { // xs
        out.blur += Calc.maxWidth(props.labelMaxWidth);
        out.focus += Calc.maxWidth(props.labelMaxWidth);
      } else {
        out.shared += Calc.maxWidth(props.labelMaxWidth);
    }
  } else if ((labelPosition === "left") && props.labelWidth){
    out.shared += " frm-label-width "; // Since label width is fixed it's calculated in parent form containmer in a var and catched here in .frm-label-width and in input with a proper class
  }
  return out;
}


export {calcTextColor, focusCalc, calcLabel,calcCoveringLabel};
