import {useContext} from 'react'; import SliderInner from './SliderInner'; import './slider.css';import {cssInts,useMediaQuery,mediaVal,Shared} from '../modules';

export function Slider(props) {
  // Laziness  shoul be recheked especially when circle is off
  /******* Media Queries ****/
  const {media} = useContext(Shared);
  /******* Media Queries ****/
  const {rows,auto} = props;
  if (!rows || !Array.isArray(rows)) {
    return null;
  } else {
    let pr = {media};
    const length = rows.length;
    pr.circle = length > 1 ? props.circle : false;
    const mediaCols = mediaVal(props.cols??1,pr.media);
    pr.cols = !pr.circle ? Math.min(mediaCols,length) : ((length <=2 ) ? 1 : Math.min(mediaCols,Math.floor((length-1)/2)));
    pr.endIndex = !rows ? 0 : length - 1;
    const remainder = length % pr.cols;
    pr.lastIndex = length - (remainder > 0 ? remainder : pr.cols);
    // The number of element to animate in the last iteration when !circle, or better the number or elements we would need to have a complete last iteration with cols elements
    pr.lastAinmationCols = pr.lastIndex  + pr.cols - length;
    // lastCss is is different from lastAinmationCols, it's the number of element in the last uncomplete iteration, this number is < cols. lastCss+lastAinmationCols = cols
    pr.lastcss = length - pr.lastIndex; // Number of elements in the last row when circle is disabled. When circle is enabled we fill uncompleted cols with nxt or previous elements so that every animation contains cols elements
    // Whwn circle is disabled the last next and previous animation are shorter because we animate lastcss elements instead of cols elements, so the aniamtion should be shorter, based on the number of elements in the last row (lastcss)
    pr.lastIterRatio = pr.lastcss/pr.cols;
    pr.pd = mediaVal(props.pd) ?? 0;
    if (!isNaN(pr.pd)) pr.pd += "px";

    return <SliderInner {...props} {...pr}/>;
  }
}
