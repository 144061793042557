import {useState,useReducer,useEffect} from 'react'; import {Color,isString,D,hasValue,inProduction} from 'modules';
import PickersSection from './PickersSection/PickersSection';import EditSection from './EditSection/EditSection'; import Hex from './Hex';
 import {maxStepValue} from './helpers';

const init = ()=> new Color();

export const Pickers = (props) => props.noShow ? null : (("setColor" in props) ? <PickersInner {...props}/> : <PickersState {...props}/>);

function PickersState(props) {
  const [color,setColor] = useState(null);
  return <PickersInner{...{...props,color,setColor}}/>
}


function PickersInner({setColor,color,position,width,height,editComponent,smallHexMenu,...props}) {
  const dispatchColor = (obj) => setColor(pickersReducer(color,obj));
  const [editColor,setEditMode] = useState(true);
  const colorVerified = color instanceof Color;
  const [showHsv,setShowHsv] = useState(false);
  const [savedColors,dispatchColors] = useReducer(savedColorsReducer,null);
  useEffect(()=>initColors(),[]);

  function initColors() {
    if (inProduction) {
      const data = {method: 'POST', headers: {'Content-Type': 'application/json', },body: null,};
      fetch("/colors/get", data).then((res) => res.json()).then((result)=>dispatchColors({fetched:result}), (error) =>  alert("fecth colors error"));
    } else {
      import("./savedColors.js").then((res) => dispatchColors({fetched:res.savedColors}));
    }

  }

  if (!colorVerified) {  // Not sure if it's proper to call setColor in the functipon body, but it shouldn't egnerate anu loop because after it runs once, the color is set and colorVerified will always be true

    return null;
  } else {
    const thumbSize = 16;
    const isView = position === "relative";
    const a = {dispatchColor,color,thumbSize,savedColors,dispatchColors,editComponent};
    const wd = width ?? (!isView  ? 350 :  "100%");
    return (
      <D cl="z-100 " a={{pos: isView  ? null: ["in","c2",10,"fixed"],pd:10,wd: wd,he:height ?? (isView ? "auto" : "70vh"),colors:{bkg:"white",text:"black"}}}>
          <div className="w100 h100 ov-hid">
            <div className="w100 h100 ov-auto no-scr-bar p-rel">
                <Hex {...{color,editColor,setEditMode,editComponent,smallHexMenu,showHsv,setShowHsv}}/>
                <PickersRouter {...{a,editColor,setEditMode,showHsv}}  />

            </div>
          </div>
      </D>
    );
  }


}



const PickersRouter = (props) => props.editColor ? <EditSection {...props} /> : <PickersSection {...props} />;

function savedColorsReducer(savedColors,{fetched}) {
  if (fetched) {
    return fetched;
  }
}

function pickersReducer(color,{component,value,increase,decrease,s,v}) {
  const {a,hex,rgb,hsl,hsv} = color;
  if (component === "color") {

    return new Color({...value,a});
  }
  const [max,step,oldColor] = maxStepValue(component,color);
  const parsedValue = !hasValue(value) ? value : ((component === "a") ? parseFloat(value) : parseInt(value));
  let newValue = parsedValue ?? (increase ?  Math.min(oldColor + step, max) : (decrease ? Math.max(oldColor - step, 0) : oldColor));
  if (["r","g","b"].includes(component)) {
    color.rgb[component] = newValue;
    return new Color({rgb,a});
  } else if (["h","s","l"].includes(component)) {
    color.hsl[component] = newValue;
    return new Color({hsl,a});
  } else if (component === "hue") {
    return new Color({hsl:{h:newValue,s:100,l:50},a});
  } else if (component === "grey") {
    return new Color({rgb:{r:newValue,g:newValue,b:newValue},a});
  } else if (component === "a") {
    return new Color({hex,rgb,hsl,hsv,a:Math.round(newValue*100)/100});
  } else if (component === "hsv") {
    return new Color({hsv:{h:color.hsv.h,s,v},a});
  }
}
