import {svg} from  '../../../modules';


export function calcOptionsArray(props) {
  let options = [];let valueName = "";let icon=null;
  if (props.iconsSelect) {
    const keys = svg();
    for (let i of keys) {
      options.push([i,i,i]);
    }
    valueName = props.value;
    icon = props.value;
  } else if (props.options && Array.isArray(props.options[0])) {
      options = props.options;
  } else if (props.options) {
      for (var m=0; m < props.options.length; m++) {
          options.push([props.options[m], props.options[m]]);
        }
  } else if ((props.rangeStart>= 0 && props.rangeEnd>=0) || Array.isArray(props.range)) {
    valueName = props.value;
    const rangeStart = Array.isArray(props.range) ? props.range[0] : props.rangeStart;
    const rangeEnd = Array.isArray(props.range) ? props.range[1] : props.rangeEnd;
    for (var start = rangeStart; start <= rangeEnd; start++) {
        options.push([start, start]);
    }
  } else {
    return null;
  }

  // options = [["name","id"],"name2","id2"],...]
  // props.value is an id so we have to find the related name in the options array

  if (!props.iconsSelect && !props.rangeStart) {
    for (var i=0; i < options.length; i++) {
      const optionsArray = options[i];
      if (optionsArray[1] == props.value) {
        valueName = optionsArray[0];
        icon = optionsArray?.[2] ?? props.icon ?? null;
        break;
      }
    }
  }
  return [options, valueName,icon];
}
