import {sizes} from './style/sizes'; import {Div} from '../modules';import {colors} from './style/colors';

const dropMenu = {
  rows:[{title:"Title 1",keepExpanded:true,buttons:[{text: "Drop 1", meta:["home"]},{text: "Drop 2", click:()=>null},{text: "Drop 3", click:()=>null}]},
  {title:"Title 2",buttons:[{text: "Drop 1", click:()=>null},{text: "Drop 2", click:()=>null}]},
  {title:"Title 3",buttons:[{text: "Drop 1", click:()=>null},{text: "Drop 2", click:()=>null},{text: "Drop 3", click:()=>null}]},
  {title:"Title 4",buttons:[{text: "Drop 1", click:()=>null},{text: "Drop 2", click:()=>null},{text: "Drop 3", click:()=>null}]},
  {title:"Title 5",buttons:[{text: "Drop 1", click:()=>null},{text: "Drop 2", click:()=>null},{text: "Drop 3", click:()=>null},{text: "Drop 4", click:()=>null}]},
  {title:"Title 6",buttons:[{text: "Drop 1", click:()=>null},{text: "Drop 2", click:()=>null},{text: "Drop 3", click:()=>null}]},
  {title:"Title 7",buttons:[{text: "Drop 1", click:()=>null},{text: "Drop 2", click:()=>null},{text: "Drop 3", click:()=>null}]},
  {title:"Title 8",buttons:[{text: "Drop 1", click:()=>null},{text: "Drop 2", click:()=>null},{text: "Drop 3", click:()=>null}]},
  {title:"Title 9",buttons:[{text: "Drop 1", click:()=>null},{text: "Drop 2", click:()=>null},{text: "Drop 3", click:()=>null}]},
  {title:"Title 10",buttons:[{text: "Drop 1", click:()=>null}]}
  ],

  expandable:true,arrowAnimation:"2s",width:200,multiCols:{cols:1,mrR:30,mrB:8},
}




//const component =  <Div colors={{bkg:"red",text:"white"}} position={{top:0, left:50,unit:"vh"}} width="30%" height="30%" oClass="z-1000" click={(e)=>e.stopPropagation()}/>;


export function calcTopBar(topBarFetch, showLogin,handleLogOut,handler,media,bot,adminLogged,userLogged) {
  const fixedMenu={...dropMenu, heading:<Div height={80} fontSize={22} weight={700} pd={25} iClass="flex-cen-start" colors={{bkg:"red"}}>Hello {userLogged}</Div>,
    width:{l:400,s:"100%"},animation:{duration:0.4,ratio:{l:0.5,s:0.7}},direction:"left",
  closeBtn:{ext:{top:20,size:{l:20,s:[6,"vw"]},hide:{l:0,m:0,s:1}},int:{hide:{l:1,m:1,s:0},size:{font:18, heightEm:2,position:["in","c2",50,"%"]}}}
  };
  let topBar = {};
  topBar.shared = {...sizes,...colors,logoSettings:{width:{l:150,s:"85%"},mr:{r:{l:20,s:0}}},media,bot,adminLogged,userLogged,handler,noBlurBkg:true};
  topBar.leftButtons = [{icon:"menu",fixedMenu:fixedMenu,admin:false},{meta:["home"],icon:"home"},{meta:["prova"],icon:"mail",className:"no-phone"}];
  topBar.centerButtons = [{text:"Div",icon:"home",dropMenu},{text:"Button Test",meta:["btn"]},{text:"Form Test "+userLogged,click:handleLogOut,user:true},{text:"Form Test", meta:["formTest"]},{text:"Pickers",meta:["pickers",{position:"relative"}]}];
  topBar.rightButtons = [{icon:"gradient",meta:["pickers",{position:"relative"}]},{meta:["btn"],icon:"mail_btn"}];
  return topBar;
}
