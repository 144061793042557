import {useState, useEffect,useRef,useMemo,forwardRef} from 'react'; import './hsvRect.css';  import Cursor from './Cursor/Cursor';import Background from './Background/Background';
import {calcSV} from './calcSV';

function HsvRect({color,dispatchColor}) {
      const [dragging, setDragging] = useState(false);
      const ref = useRef(null);
      const {hsv} = color;
      const cursorSize = 12;
      useEffect(()=> {
        document.documentElement.addEventListener('mouseup', mouseUp);
        return ()=>document.documentElement.removeEventListener('mouseup', mouseUp);
      },[]);
      useEffect(()=> {
        if (dragging) document.documentElement.addEventListener('mousemove', mouseMove, true);
        return ()=>document.documentElement.removeEventListener('mousemove', mouseMove,  true);
      },[dragging]); // we need the dragging dependency to remove mouse move every time dragging has eneded and restart when restarts

      const mouseUp = ()=> setDragging(false);
      const click = (e) => dispatchColor({component:"hsv",...calcSV(ref.current,e.clientX,e.clientY,hsv,cursorSize)});
      const mouseMove = (e) => {e.stopPropagation();e.preventDefault();click(e)};
      const touchMove = (e) => dispatchColor({component:"hsv",...calcSV(ref.current,e.changedTouches[0].clientX,e.changedTouches[0].clientY,hsv,cursorSize)});
      const touchHandlers = {onTouchStart:(e)=>{e.stopPropagation();e.preventDefault();setDragging(true)},onTouchEnd:(e)=>{setDragging(false);touchMove(e);},onTouchMove:touchMove};
      return (
            <div className={"cl-sv-graph-cont "+(dragging ? "cl-sv-dragghing":"")} >
                <div className="col-color-rect-hsv-bord-cont" >
                  <div className="col-color-rect-hsv-bord-cont-inner" >
                      <Cursor {...{dragging,hsv,current:ref.current,color,setDragging,touchHandlers,cursorSize}}/>
                      <Background {...{color,click}} ref={ref}/>
                  </div>
            </div>
         </div>
    );
  }

      export default HsvRect;
