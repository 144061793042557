import {cssInts, colToCss, calcNumClasses,Calc,isObj,def,calcGradientDirection, calcComponent, calcColorStops} from '../../../modules';import {defColors, defSizes} from "../defs.js";
import { focusCalc} from "./calculators" ;

export const calcFormCont = (brd={}, labelPosition, label, noPointer,pd,formType, labelWidth,color) => {
  // vars:  f,g
  // vars imported from bkgContainer and used in form-container css property: hsl,hsl1,hsl2 hex1,hex1,hex2,hex3

  
  const out = {shared:" form-container noselect flex-cen-start ",blur: " ", focus:" form-focused ",error:" form-err "};
  if (!noPointer) out.shared += " pointer ";
  if (brd.bar && isObj(brd.bar) && ["l","r","t","b"].includes(brd.bar.p) && brd.bar?.s) {
    out.shared += ` form-${brd.bar.p}Bar-width `;
  }
  if (Array.isArray(pd?.s)) {
    out.blur += Calc.padding({s:pd?.s[0]}); // var g;
    out.focus += Calc.padding({s:pd?.s[1]}); // var g;
  } else {
    out.shared += Calc.padding({s:pd?.s}); // var g;
  }
  if (color.var) out.blur += calcGradient(color);
  if (labelWidth){
    out.shared += " frm-label-fixed-width ";
    if (Array.isArray(labelWidth)) {
      out.blur += cssInts(labelWidth[0], "f","width");
      out.focus += cssInts(labelWidth[1], "f","width");
    } else {
      out.shared += cssInts(labelWidth, "f","width");
    }
  }
  return out;
}



export function calcGradient(color={}) {
    const blurVar = Array.isArray(color.var) && Array.isArray(color.var[0]) ? color.var[0] : color.var ; // To set an hover value we use an array [blur, hover], instead of just blur
    let out =   " a-btn-grad-lin-" + blurVar[0]+"h ";
    out += (["r","g","b"].includes(blurVar[0])) ? colToCss("rgb1",color.bkg.b) : colToCss("hsl",color.bkg.b); // hsl var is not overriding with the hsl imported from calcFormCont because gradient is set only when normal bkg is not set, so when a gradient is set hsl var is not set in bkg
    out += calcComponent(color,blurVar); // Vars: sm, sign,xs9, sign1,
    out += calcColorStops(color);   // Vars: sm2,sm3,xs2,xs3
    out += calcGradientDirection(color); // Vars: xs10,11
    return out;
  }


/*

export const calcFormCont = (focus, brd={}, labelPosition, label, error, noPointer,pd,formType, labelWidth,color) => {
  // vars:  f,g
  // vars imported from bkgContainer and used in form-container css property: hsl,hsl1,hsl2 hex1,hex1,hex2,hex3

  const pdS = focusCalc(pd?.s,focus) ?? ((formType === "text") ? 12 : 0);
  let out = {shared:" form-container noselect flex-cen-start ",b: " ", f:" form-focused ",e:};
  if (error) out += " form-err ";
  if (focus) out += " form-focused ";
  if (!noPointer) out += " pointer ";
  if (pdS) out += Calc.padding({s:pdS}); // var g;
  if (brd.bar && isObj(brd.bar) && ["l","r","t","b"].includes(brd.bar.p) && brd.bar?.s) {
    out += ` form-${brd.bar.p}Bar-width `;
  }
  if (color.var && !focus && !error) out += calcGradient(color);
  if (labelWidth){
    out += " frm-label-fixed-width ";
    out += cssInts(focusCalc(labelWidth, focus), "f","width");
  }
  return out;
}

*/
