import {useMemo} from 'react';
import {colors} from './btnColor';import {Btn,Style,FlexCols,D,isString} from 'modules';

function TestButtons({button,props}) {
  const [sheet,cl] = useMemo(()=>button ? colors(button,button.cssClass) : ["",""],[button]);
  const size= {height:"2.5em",font:{d:19,mp:13},icon:{d:"1.1em",mp:"1em"},brd:2}
  if (button) {
    const c = (props.className ?? "") + (cl??"") + " a-flex-col ";
    return (
      <D a={{mr:{B:20}}}>
      <Style sheet={sheet}/>
      <FlexCols {...{length:4,cols:4,mrR:{d:20,mp:3},s:{wd:"100%"}}}>
          <Btn {...props} copy={true} size={size} className={c} text="Blur"/>
          <Btn {...props} size={size} className={c+" btn-force-hover "} text="Hover"/>
          <Btn {...props} size={size} className={c+" btn-clicked "} text="Click"/>
          <Btn {...props} size={size} className={c} active={true} text="Active"/>
      </FlexCols>
      <D a={{mr:{T:20}}}>
        <FlexCols cols={3} mr={10} width={600}>
          <Btn  size={size} className={c} text="Css Classes" click={()=>navigator.clipboard.writeText(cl)}/>
          <Btn  size={size} className={c} text="Css File" click={()=>navigator.clipboard.writeText(sheet)}/>
        </FlexCols>
      </D>
      </D>
    );
  } else {
    return null;
  }
}

export default TestButtons;
