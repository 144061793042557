import Row from './Row';

export function Side (props) {
  const direction = props.left ? "flex-cen-start" : "flex-cen-end";


  return (
    <div className={"top-bar-side "+direction} >
      {props.rows.map((row,index) => <Row {...props.shared} {...row}  key={index}/>)}
    </div>
  );
}
