/*import {colToCss} from './modules'; */
export const isObj = (val) => (typeof val === 'object' && !Array.isArray(val) && val !== null) ;
export const isString = (i) => (typeof i === "string");
export const isNumber = (i) => (typeof i === "number");
export const hasValue = (value) => (!value && (value !== 0)) ? false : true;
export const inProduction = (process.env.NODE_ENV === "production");
export const space = (string) => ` ${string} `;
export const mediaVal = (value, media) => {
    if (!isObj(value) || (!("d" in value) && !value.only) || !media) {
       return value;
    } else if (!isObj(media)) { // For css computation instead of inline style we return the value only if declared for that display because the mediaqueries and not js will take care on inheriting values from other display,
      return value[media];
    } else {
       if (media?.isDesktop && value.d) {
         return (value.d);
       } else if (media?.isXTablet && (value.xt || !value.only)) {
         return (value.xt ?? value.d);
       } else if (media?.isTablet && (value.t || !value.only)) {
         return (value.t ?? value.xt ?? value.d);
       } else if (media?.isMobileP && (value.mp || !value.only)) {
         return (value.mp ?? value.t ?? value.d);
       } else if (media?.isMobileL && (value.ml || !value.only)) {
         return (value.ml ?? value.mp ?? value.t ?? value.d);
       } else if (media?.isXMobileP && (value.xm || !value.only)) {
         return (value.xm ?? value.mp ?? value.t ?? value.d);
       } else {
         return null;
       }
    }
}
/* aready exported from css/calc
export const colToCss = (prefiss, name) => ` a-${prefiss}-${name.toLowerCase().replace(" ","-")} `;
*/

export const def = (value, defaltVal) => value ? value : defaltVal;
export const defObj = (obj, i, defaltVal) => (isObj(obj) && obj[i]) ? obj[i] : defaltVal;

export const isInViewport = (elem) => {
    if (!elem) {
      return null;
    } else {
      var rect = elem.getBoundingClientRect();
      return (
          rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }
  };

  export function calcWindowSizes() {
    const media = {};
    media.width = document.body.clientWidth;
    media.height = window.innerHeight;
    media.isPortrait = media.height > media.width;
    if (media.width <= 350 && media.isPortrait) {
      media.isXMobileP = true;
      media.display = "xp";
    } else if (media.width <= 450 && media.isPortrait){
      media.isMobileP = true;
      media.display = "mp";
    } else if (media.height <= 500 && !media.isPortrait && media.width <= 970) {
      media.isMobileL = true;
      media.display = "ml";
    } else if (media.width > 450 && media.width <= 1100 && media.height > 500) {
      media.isTablet = true;
      media.display = "t";
    } else if (media.width > 1100 && media.width <= 1400) {
      media.isXTablet = true;
      media.display = "xt";
    } else {
      media.isDesktop = true;
      media.display = "d";
    }
    media.isMobile = media.isMobileL || media.isMobileP;
    return media;
  }
