import {useReducer,useRef,useEffect,useState} from 'react'; import {D} from 'modules';import {maxStepValue } from '../helpers';
import RangeInput from './RangeInput'; import RangeValue from './RangeValue'; import Header from './Header';

function InputContainer(props) {
  const [p, handleTouch] = useReducer(setP,null);
  const {color,type,lRef} = props;
  const maxSteapValueObj = maxStepValue(type,color,true);
  const [width,setWidth] = useState(null);  // It's set by use effect, the width value is needed to compute  position
  const {rgb,hsl} = color;
  // not value is used only for hue and gray colors because if the color is not gray or a pure hue than we cannot select any value in the input until we chose one valid value
  const noValue = (type === "hue" && !(hsl.s === 100 && hsl.l === 50)) || (type === "grey" && !(rgb.r === rgb.g && rgb.r === rgb.b));
  const ref = useRef(null);
  useEffect(()=>setWidth(ref.current.getBoundingClientRect().width),[]);
  return (
    <D a={{pd:{C:7},mr:{S:5},colors:{bkg:"white smoke"}}} cl={noValue ? "no-thumb" : ""}>
        <RangeValue  {...{...props,width,...maxSteapValueObj,noValue,lRef}} />
        <RangeInput {...props} {...{p,handleTouch,maxSteapValueObj}} ref={ref}/>
        <Header  {...{...maxSteapValueObj,noValue,...props}}/>
    </D>
  );
}

export default InputContainer;



const setP = (p,{e,type})=> {
  if (type === "touchStart") { // touch start case
    return {x:e.changedTouches[0].pageX,y:e.changedTouches[0].pageY};
  } else if (type === "touchMove")  { // touch
    if (p === "scrolling") return "scrolling";
    const pageY = e.changedTouches[0].pageY;
    const pageX = e.changedTouches[0].pageX;
    if ( p && (p !== "sliding") && ((Math.abs(p.y - pageY) > 30) || ( Math.abs(p.y - pageY) > (Math.abs(p.x - pageX) / 2)))) {
      return "scrolling";
    } else  {
       return "sliding";
    }
  } else if (type === "touchEnd") {
    return null;
  } else if (type === "mouseDown") {
    return "sliding";
  } else  { // mouseUp case
    return null;
  }
}
