import {Btn,D,Color} from 'modules';

function Menu({setView,saveElement,deleteElement}) {
  const showListView = () => {setView("select");/*if (button?.edited || !button.id) saveBtn();*/}; // If we swith from the edit view to the buttons list we save the changes to the button being edited if we made some edits.

  return (
    <D a={{pd:10}} cl="flex-cen-start">
      <Btn text="List" click={showListView} size={{height:"2.2em",font:13}} width="auto"/>
      <Btn text="Save" click={saveElement} size={{height:"2.2em",font:13}} mrL={10} width="auto"/>
      <Btn text="Delete" click={deleteElement} size={{height:"2.2em",font:13}} mrL={10} width="auto"/>
      <Btn text="Add" click={deleteElement} size={{height:"2.2em",font:13}} mrL={10} width="auto"/>
    </D>
  );
}

export default Menu;

/*
<Btn text="Add New" click={addBtn} size={{height:"2.2em",font:13,}} width="auto"  mrL={10}/>
<Btn text={"Save "+name} click={saveBtn} size={{height:"2.2em",font:13,}} width="auto"  mrL={10}/>
<Btn text={"Delete "+name} click={deleteBtn} size={{height:"2.2em",font:13,}} width="auto"  mrL={10}/>
*/
