import {D,Button} from 'modules';

function ActionButtons({editName,deleteList,id,setName,name,saveList}) {
  if (editName) {
    return (
      <D cl="flex-cen-cen">
        <ListBtn text="save" click={saveList}/>
        <ListBtn text="cancel" click={()=>setName(null)}/>
      </D>
    );
  } else {
    return (
      <D cl="flex-cen-cen">
        <ListBtn text="add"/>
        <ListBtn text="edit" click={()=>setName(name)}/>
        <ListBtn text="delete" click={()=>deleteList(id)}/>
      </D>
    );
  }
}

const ListBtn = (props) => <Button mrL={5} size={{font:{d:"2"},height:40}} {...props} colors={{b:{text:"white",bkg:"dodger blue"},c:{bkg:"blue"},h:{lBkg:10}}}/>


export default ActionButtons;
