const maxInput = (component) => ["h","hue"].includes(component) ? 360 : (["s","l"].includes(component) ? 100 : (component === "a" ? 1 : 255));

function componentValue(component,color) {
  if (component === "hue") {
    return color.hsl.h;
  } else if (component === "grey") {
    return color.rgb.r;
  } else if (["r","g","b"].includes(component)) {
    return color.rgb[component];
  } else if (["h","s","l"].includes(component)) {
    return color.hsl[component];
  } else if (component === "a") {
    return color.a;
  } else if (component === "hsv") {
    return (color.hsv);
  }

}

const step = (component) => (component === "a") ? 0.01 : 1;

export const maxStepValue = (component,color,returnObject) => returnObject ? {max:maxInput(component),step:step(component),value:componentValue(component,color)}: [maxInput(component),step(component),componentValue(component,color)];
