import {isString,isObj,hasValue} from 'modules'; import {hsvToRgb} from './converters/hsvToRgb';import {rgbToHsv} from './converters/rgbToHsv';
import {hexToRgb,rgbToHsl,hslToRgb,intToEsa} from './converters/rgbToHslAndHex.js';

export class Color {
    constructor(color={hex:"#000000"}) {
      if (isObj(color)) {
        const {hex,rgb,hsl,hsv,a,name,id,contrast} = color;
        if (hex) {
          this.hex = hex;
          this.rgb = rgb ?? Color.hexToRgb(this.hex);
          this.hsl = hsl ?? Color.rgbToHsl(this.rgb);
          this.hsv = hsv ?? Color.rgbToHsv(this.rgb);
        } else if (rgb) {
          this.rgb = rgb;
          this.hex = Color.rgbToHex(this.rgb);
          this.hsl = Color.rgbToHsl(this.rgb);
          this.hsv = Color.rgbToHsv(this.rgb);
        } else if (hsl){
          this.hsl = hsl;
          this.rgb = Color.hslToRgb(this.hsl);
          this.hex = Color.rgbToHex(this.rgb);
          this.hsv = Color.rgbToHsv(this.rgb);
        } else if (hsv) {
          this.hsv = hsv;
          this.rgb = Color.hsvToRgb(this.hsv);
          this.hex = Color.rgbToHex(this.rgb);
          this.hsl = Color.rgbToHsl(this.rgb);
        }
        this.a = a ?? 1;
        this.contrast = contrast ?? this.contrastColor();
        this.name = name ?? "";
        this.id = id ?? "";
      }
    }

    name = ""; id = ""; hex = ""; rgb = {}; hsl = {}; hsv = {}; a = 1;
    static hexToRgb = (hex) => hexToRgb(hex);
    static rgbToHsl = (rgb) => rgbToHsl(rgb);
    static hslToRgb = (hsl) => hslToRgb(hsl);
    static rgbToHex = (rgb) => "#"+intToEsa(rgb.r)+intToEsa(rgb.g)+intToEsa(rgb.b);
    static hexToHsl = (hex) => Color.rgbToHsl(Color.hexToRgb(hex));
    static hslToHex = (hsl) => Color.rgbToHex(Color.hslToRgb(hsl));
    static rgbToHsv = (rgb) => rgbToHsv(rgb);
    static hsvToRgb = (hsv) => hsvToRgb(hsv);
    static rgbToCss = ({r,g,b,a})=> ((!a && (a !== 0 )) || (a === 1 )) ? ( "rgb(" + r + "," + g + "," + b + ")" ) : ("RGBA(" + r + "," + g + "," + b+ "," +a + ")" );
    static hslToCss = ({h,s,l,a}) => ((!a && (a !== 0 )) || (a === 1 )) ? ( "hsl(" + h + "," + s + "%," + l + "%)" ) : ("HSLA(" + h + "," + s + "%," + l + "% ,"+ a+")" );
    static hsvToCss = ({h,s,v,a}) => ((!a && (a !== 0 )) || (a === 1 )) ? ( "hsv(" + h + "," + s + "% ," + v + "%)" ) : ("HSVA(" + h + "," + s + "% ," + v + "%,"+ a+")" );
    static contrast = (rgb,a=1) => {
      const luminance = (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b)/255;
      return (((luminance > 0.6) || (a <= 0.4)) ? "black" : "white");
    }

     contrastColor() {  // Choose black or white font color based on the background. in an out color in rgb object {r:i, g:i, b:i}
      const luminance = (0.299 * this.rgb.r + 0.587 * this.rgb.g + 0.114 * this.rgb.b)/255;
      return (((luminance > 0.6) || (this.a <= 0.4)) ? "#000000" : "#ffffff");

    }
    get hexToCss() {
      let opacity = this.a<1 ? (Math.round(255 * this.a)).toString(16) : "";
      if (opacity.length === 1) opacity = 0 + opacity;
      return this.hex + opacity;
    }
    get rgbToCss() {return Color.rgbToCss({r:this.rgb.r,g:this.rgb.g,b:this.rgb.b,a:this.a})};
    get hslToCss() {return Color.hslToCss({h:this.hsl.h,s:this.hsl.s,l:this.hsl.l,a:this.a})};
    get hsvToCss() {return Color.hsvToCss({h:this.hsv.h,s:this.hsv.s,v:this.hsv.v,a:this.a})};

    get greyGradient() {return gradient(`rgba(0, 0, 0,${this.a}), rgba(127.5, 127.5, 127.5,${this.a}), rgba(255, 255, 255,${this.a})`)};
    get hueGradient() {return gradient(`rgba(255, 0, 0,${this.a}) 0%, rgba(255, 255, 0,${this.a}) 17%, rgba(0, 255, 0,${this.a}) 33%, rgba(0, 255, 255,${this.a}) 50%, rgba(0, 0, 255,${this.a}) 67%, rgba(255, 0, 255,${this.a}) 83%, rgba(255, 0, 0,${this.a})`)};
    get rGradient() {return gradient(Color.rgbToCss({r:0, g:this.rgb.g, b:this.rgb.b, a: this.a}) + " 0, " + Color.rgbToCss({r:127.5, g:this.rgb.g, b:this.rgb.b, a: this.a}) + " 50%, " +  Color.rgbToCss({r:255, g:this.rgb.g, b:this.rgb.b, a: this.a}) + " 100%" )};
    get gGradient() {return gradient(Color.rgbToCss({r:this.rgb.r, g:0, b:this.rgb.b, a: this.a}) + " 0, " +  Color.rgbToCss({r:this.rgb.r, g:127.5, b:this.rgb.b, a: this.a}) + " 50%, " + Color.rgbToCss({r:this.rgb.r, g:255, b:this.rgb.b, a: this.a}) + " 100%" )};
    get bGradient() {return gradient( Color.rgbToCss({r:this.rgb.r, g:this.rgb.g, b:0, a: this.a}) + " 0, " + Color.rgbToCss({r:this.rgb.r, g:this.rgb.g, b:127.5, a: this.a}) + " 50%, " + Color.rgbToCss({r:this.rgb.r, g:this.rgb.g, b:255, a: this.a}) + " 100%" )};
    get hGradient() {
      const el = (h,pc)=>Color.hslToCss({h:h, s:this.hsl.s, l:this.hsl.l, a: this.a}) + (pc>=0 ? ` ${pc}%, `:" 100% ");
      return gradient(el(0,0)+el(60,17)+el(120,33)+el(180,50)+el(240,67)+el(300,83)+el(360));
    };
    get sGradient() {return gradient( Color.hslToCss({h:this.hsl.h, s:0, l:this.hsl.l, a: this.a}) + ", " +  Color.hslToCss({h:this.hsl.h, s:100, l:this.hsl.l, a: this.a}))};
    get lGradient() {return gradient( Color.hslToCss({h:this.hsl.h, s:this.hsl.s, l:0, a: this.a}) + ", " + Color.hslToCss({h:this.hsl.h, s:this.hsl.s, l:50, a: this.a}) + ", " + Color.hslToCss({h:this.hsl.h, s:this.hsl.s, l:100, a: this.a}))};
    get aGradient() {return gradient( Color.rgbToCss({r:this.rgb.r, g:this.rgb.g, b:this.rgb.b, a:0}) + " 0, " + Color.rgbToCss({r:this.rgb.r, g:this.rgb.g, b:this.rgb.b, a: 0.5}) + " 50%, " + Color.rgbToCss({r:this.rgb.r, g:this.rgb.g, b:this.rgb.b, a:1}) + " 100%" )};
}

const gradient = (inner) => {return {backgroundImage: `linear-gradient(to right,${inner})`}};
