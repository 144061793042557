export function calcSlides(index, circle, rows, cols,action,lastAinmationCols,lastIndex,lazy) {
      let out = [];
      console.log("Slides")
      const length = rows.length;
      const endIndex = length - 1;
      let pre,post;
      let translation = 0;
      let array = [];
      let len = length;
      let lazyRange;
      if (!circle) {
        array = rows;
        if ((index === lastIndex) && (action === "next")) {
          translation =  index - lastAinmationCols;
        } else {
          translation = index;
        }
        lazyRange = lazy && (lazy < length) ? (index === 0 ? [index,index+lazy] : [index-1,index+lazy-1]): true;
      } else if (length >= 3 * cols) {
        const midIndex = Math.floor(length / 2 - cols/2);
        const diff = index - midIndex;
        if (diff>0) {
          array = rows.slice(diff,length).concat(rows.slice(0,diff));
        } else if (diff < 0) {
          array = rows.slice(length+diff,length).concat(rows.slice(0,length+diff));
        } else {
          array = rows;
        }
        translation = midIndex;
        lazyRange = (!lazy || (lazy >= midIndex)) ? true : [midIndex-lazy/2,midIndex+lazy/2];
      } else { // length must be > (2 * cols +1) to make it work, we will force cols to comply to this in the inizialization so we won't have to test it here
        const delta = index - cols;
        lazyRange = true;
        if (action === "next") {
          array = (delta < 0) ? rows.slice(length+delta,length).concat(rows.slice(0,length+delta)) : (delta > 0 ? rows.slice(delta,length).concat(rows.slice(0,delta)) : rows);
          translation = cols;
        } else  { // This case works for action="prev" or action at all
          array = (index === 0) ? rows.slice(endIndex,length).concat(rows.slice(0,endIndex)) : rows.slice(index-1,length).concat(rows.slice(0,index-1));
          translation = 1;
        }

      }

      return {array,translation,len,lazyRange};
}

/*
else if (index === 0){ // case circle and enought room on both sides, or when cricle with no action (first load) because only when the action is specified we decide whether to add an item ont he front or on the back
  array = rows.slice(endIndex,length).concat(rows.slice (0,length-1));
  translation = index+1;
} else if (index === endIndex){ // case circle and enought room on both sides, or when cricle with no action (first load) because only when the action is specified we decide whether to add an item ont he front or on the back
  array = rows.slice(endIndex,length).concat(rows.slice (0,length-1));
  translation = index+1;


*/
