import {forwardRef,useState,useEffect,} from 'react';
import {calcMarginCont,calcPaddingCont} from './calcDiv.js';import Calc from './Calc'; import Tooltip from '../Tooltip/Tooltip';

/*  <div className=""></div>  */

const Div = (props) => {
  const [containers,setContainers] = useState(null);
  useEffect(()=>setContainers({marginContainer:calcMarginCont(props),paddingContainer:calcPaddingCont(props)}),[props]);
  if (props.noShow) {
    return null;
  } else if (props.click) {
    return (
      <div className={containers?.marginContainer ?? ""} onClick={props.click} style={props.style}>
          <DivInner containers={containers} {...props}/>
      </div>
    );
  } else {
    return (
      <div className={containers?.marginContainer ?? ""} style={props.style}>
          <DivInner containers={containers} {...props}/>
      </div>
    );
  }
};

const DivInner = (props)  => {
  const containers = props.containers
  if (!containers) {
    return null;
  } else {
    return (
      <div className={containers?.paddingContainer} >
        <Tooltip {...props.tooltip}/>
        {props.children}
      </div>
    );
  }
}

const DivRef = forwardRef((props,ref) => {
  const [containers,setContainers] = useState(null);
  useEffect(()=>setContainers({marginContainer:calcMarginCont(props),paddingContainer:calcPaddingCont(props)}),[props]);

  if (props.noShow) {
    return null;
  } else if (props.click) {
    return (
      <div className={containers?.marginContainer ?? ""} onClick={props.click} ref={ref}>
          <DivInner containers={containers} {...props}/>
      </div>
    );
  } else {
    return (
      <div className={containers?.marginContainer ?? ""} ref={ref}>
          <DivInner containers={containers} {...props}/>
      </div>
    );
  }


});

// <FlexCols mr mrB mrR pd cols length width className />
// mr,mrB,mrR define the intra margins between the flex items, not the margins of the flex container. Use mr to define same right and bottom margin, or use mrR and mrB to set different values
// cols: the number of elements per row for each display, length the number of elements
// Ech children block should have the width set via Calc.whidths as a flex=true (or just add class " a-flex-col ") or pFlex=intval, flext sets the width equal to every block, pFlex allows to set a custom % to each blcck


export {Div,Calc,DivRef};

/*
Div creates an outer container with margin, padding, width..
Only 3 props needed put other component inside as childrens, div is only an encloser!

<Div divStyle=str iClass=str oClassù=str>
    {children}
</Div>

divStyle = {
  mr: int (same t/r/b/s x every screen) || [t,r,b,l] || [section,container]  || {s(section) (|| t(top),b(bot)), c(container) (|| r(right),l(left)): all are intvals , u:{t,b,r,l,s,c:unit||{l,m,s:unit} }, unit = "px"(defalt), "vw","pc","vh"
  pd: int (same t/r/b/s x every screen) || [t,r,b,l] || [section,container]  || {s(section) (|| t(top),b(bot)), c(container) (|| r(right),l(left)): all are intvals  , u:{t,b,r,l,s,c:unit||{l,m,s:unit} }, unit = "px"(defalt), "vw","pc","vh"
  width, maxWidth: intVal
  brd: {s:intVal,c: "string",rad:intVal,bar:{p:("l","r","t","b"), s:intVal,c:"color",rad:intVal}, || [{blur},{hover}]
  shadow: true (fb shadow) || {s:intVal (size), bm:intVal (blur = bm*s),ba:intVal (blur = ba+s),c:"color-name",a:(0,0.1,...,0.9,1), hover:{only:bol (shadow only on hov),trans:+-intVal (translate),add:intVal (increase size on hover)} }, // hval = true (display box shadow only on hover ) or intVal (display shadow in any state and increase it on hover by the value specified in hval)
  colors: {
    text: string
    textHov: string
    bkg: string
    lHov: intVal
    bkgHov: str
    a: 0-1 (opacity),
    weight: intVal
    lh: float  || {l,m,s:float}
  }



*/
