import {FlexCols} from 'modules'; import "./multiDrop.css";

function FlexWrapper({dropContainers,multiCols={},length,multiDrop,children}) {
  if (multiDrop) {
    return (
      <div className={" multi-drop-container "+(dropContainers?.multiDropContainer??"")}> {/* Compute the vars for the section headers when it's a multiDrop*/}
          <FlexCols container={(dropContainers?.flexContainer??"") + " drop-multi-flex-wrapper "} {...multiCols}  length={length}>
          {children}
          </FlexCols>  {/* Flex Cols calculates the padding of the dropDown window, and the vars of the  width and margins of the child sections. */}
      </div>
    )
  }
  else {
    return children;
  }
}

export default FlexWrapper;
