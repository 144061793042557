import {useState,useReducer,useEffect} from 'react';
import {D,FormInput,Button,FormCheck,FlexCols} from 'modules';

function SaveColor({setSaveView,savedColors,dispatchColors,color}) {
  const [name,setName] = useState(color.name ?? "");
  const [map,setMap] = useState(null);
  useEffect(inizializeMap,[]);
  function inizializeMap() {
    if (!color.name) {
      setMap(initLists(savedColors));
    } else {
      const data = {method: 'POST', headers: {'Content-Type': 'application/json', },body: null};
      console.log("color.id");
      console.log(color.id);
      fetch("/colors/listsInColor/"+color.id, data).then((res) => res.json()).then((ids)=>setMap(initLists(savedColors,ids)), (error) =>  alert("save error"));
    }
  }
  const dispatch = (result) => {
      if (result?.[0] !== "error") {
        dispatchColors({fetched:result});
        setSaveView(false);
      }
  }

  const handleSaveColor = () => {
    const keys = Object.keys(map);
    const lists = [];
    keys.forEach((id) => {if (map[id]) lists.push(id)});
    const body = {color:{...color,name:name},lists}
    const data = {method: 'POST', headers: {'Content-Type': 'application/json', },body: JSON.stringify(body)};
    fetch("/colors/save", data).then((res) => res.json()).then(dispatch, (error) =>  alert("save error"));
  }
  return (
    <D >
      <D cl="flex-cen-btw">
        <D >Save Color</D>
        <Button icon="close" click={()=>setSaveView(false)}/>
      </D>
      <D a={{mr:{S:10}}}>
          <FormInput value={name} handleChange={setName} size={{font:13,height:30,pd:{c:12,i:0},width:250}} color={{bkg:{b:"dodger blue",f:"green",e:"red"},input:{}}}/>
      </D>
      <ListsCheckBox {...{savedColors,map,setMap}}/>
      <Button text="save" click={handleSaveColor}/>
    </D>
  );
}


export default SaveColor;

function ListsCheckBox({savedColors,map,setMap}) {
  if (!map) {
    return null;
  } else {
    return (
      <FlexCols cols={3} length={savedColors.length} mr={10}>
          {savedColors.map((row,index) => <CheckRow value={row.id} {...{map,setMap}} title={row.name} key={index} oClass="a-flex-col"/>)}
      </FlexCols >
    );
  }
}

const initLists = (savedColors,checkedIds) => {
  const keys = Object.keys(savedColors);
  const lists = {}

  if (checkedIds) {
    savedColors.forEach((item) => lists[item.id] = checkedIds.includes(item.id));
  } else {
    savedColors.forEach((item) => lists[item.id] = false);
  }

  return lists;


}

const box = {
  size: {
          box: 30 ,  // width-height of the ckeck/radio box
          gap: 10, // margin between label and checkbox
          brd: 0, // checkbox border in checked state, usually to be used when bkg doesn't change when checked, so the border and the checked tick highlith the checked state
          tick: 5, // size of the tick should be 2-3
          position: "left"// (default), "top","right"
        },
  color:  {
    tick: "white", // tick/bullet color
    bkg: "dodger blue",  // bkg of the checkbox
    lHov: 10, // checkbox background luminosity change on hover
    bkgChecked: "green", // bkg of the checkbox in checked state
    lHovCheched: 10, // checkbox background luminosity change on hover when the box is checked
  }};

const CheckRow = (props) => <FormCheck box={box} {...props}/>;
