import {calcNumClasses,SvgIcon,isObj,Calc} from '../../../modules';


export default function ButtonIcon({icon,rightIcon}) {
  if (!icon) {
    return null;
  } else {
    const order = rightIcon ? "flex-3 " : "flex-1 ";
    return   <SvgIcon icon={icon} sizeClass={order} colorClass={"icon-btn-color "} />;
  }
}
