import React, {useEffect, useState, useRef,forwardRef} from 'react'; import './dropArrow.css';
import {SvgIcon} from '../../../../modules';

  // For only Icons drop button we dont't show the drop arrow because it doesn't look good, so when !text  we don't render the arrow
  const DropArrow = forwardRef(({noShow,click,...props},ref) => {
      if (((!props.text || !props.hasDropMenu) && !props.expArrow) || props.noArrow) {
        return null;
      } else {
        return <DropArrowInner {...props} ref={ref}/>
      }
  });


// We need to set no show to avoid rendering the svg before the size class is compued or the svg will appear for a micro second huge
 const DropArrowInner = forwardRef(({clicked,noShow,colorsClass,expArrow,click,arrowAnimation},ref) => {
  const clickTimer = useRef(null);
  const [delayedClick, setDelayedClick] = useState(false);
  useEffect(()=>{
    if (clicked) {
      if (clickTimer.current) clearTimeout(clickTimer.current);
      setDelayedClick(true);
    } else {
      if (clickTimer.current) clearTimeout(clickTimer.current);
      clickTimer.current = setTimeout(()=>setDelayedClick(false),100);
    }
  },[clicked]);
  const container = (colorsClass??"")+ "drop-down-arrow flex-3 " + (clicked ? (" drop-down-arrow-up "+(arrowAnimation?" drop-arrow-animation ":"")): (delayedClick ? "drop-down-arrow-up" : "")) + (expArrow ? " exp-arrow " : " btn-arrow ");
  const outer = {className:container};
  if (arrowAnimation) outer.style = {"--arrAnimTime": arrowAnimation !== true ? arrowAnimation : "2s"};

  return (
    <div {...outer} ref={ref} >
        <SvgIcon icon="drop_down"  sizeClass={true} {...{noShow,click}} colorClass="icon-btn-color"  />
    </div>
  )
});

export default DropArrow;
