import {colToCss,Cal,isObj,addPc} from 'modules';
import {calcArrowColors,calcBoxShadow,calcHrColors} from './helpers/colorHelpers'; import {calcIconColors} from './helpers/iconColors'; import {calcMultDropHeaderColors} from './helpers/multiHeaderColors';
//{b:{icon,header,bkg,text},h:{icon,bkg,lBkg,lTxt,text}}
export function calcDropDownColors(colors,out) {
  if (isObj(colors)) {
    const {hr} = colors;
    out.dropContainer += Cal.a(colors?.b?.a ?? 1);
    calcBkg(colors,out);
    calcTextRowColors(colors,out);
    out.dropContainer += calcBoxShadow(colors); // Vars: xs4,xs5,xs6,xs7,a1, rgb1
    calcIconColors(colors,out);
    calcMultDropHeaderColors(colors,out);
    out.expArrowContainer += calcArrowColors(colors?.expArrow);
    if (hr) calcHrColors(hr,out);
  }
}

function calcTextRowColors(colors,out) {
  const {b,h} = colors;
  if (h?.lTxt) {
    const lTxt = Array.isArray(h.lTxt)?h.lTxt[0]:h.lTxt;
    out.rowContainer += colToCss("hsl2",b?.text ?? "black") + " drop-row-lTxt "; // Vars sm,sign -> not duplicated because colled conditionally
    out.colorsStyle["--lTxt"] = addPc(lTxt);
  } else  {
    out.dropContainer += colToCss("hex",b?.text ?? "black");
    if (h?.text) out.rowContainer += colToCss("hex1",h?.text) + " drop-row-txt-hov ";;
  }
}

function calcBkg(colors,out) {
  const {b,h} = colors;
  out.dropContainer += colToCss("hsl",b?.bkg ?? "white");
  if (h?.lBkg) {
    out.dropContainer += " drop-row-lBkg "; // Vars sm,sign ->not duplicated in colors.h?.lTxt because it will be ignored when lBkg is defined
    out.colorsStyle["--lBkg"] = addPc(h.lBkg);
  } else if (h?.bkg) {
    out.dropContainer += colToCss("hsl1",h.bkg) + " drop-row-bkg-hov ";
  }
}
