import {isObj,isNumber,Cal,isString,addStyles,addStylesProp,calcCss,addPx,addVw,calcFont,hasValue} from 'modules';
import {calcMultiDropHeader} from './helpers/multiHeader'; import {calcDropRow} from './helpers/dropRow';

export function calcDropSizeCss(size,cssClass,out) {
    const out1 = {cl:"", style:{d:"",xt:"",t:"",mp:"",ml:"",xm:""}};
    const out2 = {cl:"", style:{d:"",xt:"",t:"",mp:"",ml:"",xm:""}};
    if (size) {
      const {multiDrop} = size;
      const expander = size.multiDrop?.expander;
      addStyles(size.brdRad,"brdRad",out1);
      if (isObj(size.row)) calcDropRow(size.row,out1); // Drop row
      if (multiDrop) {
        addStyles(multiDrop?.hrWidth,"md-hrWidth",out2); // Hr separator width
        calcMultiDropHeader(multiDrop?.header, out2);
        addStyles(multiDrop.pd,"multiDropPd",out2); // Padding of the multidrop
        if (expander) {
          addStyles(expander.pd,"expPd",out2);
          addStyles(expander.tip,"expTip",out2);  // Tip of the expander
          addStyles(expander.rad,"expRad",out2);
        }
      }
      out.dropContainer = out1.cl + ` ${cssClass}-drop `;
      out.multiDropContainer = out2.cl;
      out.style += calcCss(out1.style,cssClass+"-drop.a-btn-drop-menu");
      out.style += calcCss(out2.style,cssClass+"-drop .multi-drop-container");
  }

}
