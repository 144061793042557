import React, {useState,useRef,useEffect} from 'react';  import {Div,Di,useMediaQuery,Shared,calcWindowSizes,Color,D,inProduction} from './modules';import './App.css';
import Login from './Login'; import {calcTopBar} from './TopBar/calcTopBar';  import Router from './Router';


function App({serverData,bot}) {
  /******* Media Queries ****/
  const [media,setMedia] = useState({});
  const [adminLogged, setAdmin] = useState(false);
  const [userLogged, setUser] = useState("Luca");
  const [currentPage, setPage] = useState(["home"]);
  const [showLoginWindow, setLoginWindow] = useState(false);
  const [animateOpacity, setOpacityAnimation] = useState(true);
  const [topBarFetch, setTopBarFetch] = useState(null);
  const [topBar, setTopBar] = useState({});
  const showLogin = ()=> alert("in")
  const handleLogOut = () => alert("out");


  const shared  = {currentPage,media,bot,adminLogged,userLogged,topBar,handler,serverData};
  useEffect(()=>setTopBar(calcTopBar(topBarFetch, showLogin,handleLogOut,handler,media,bot,adminLogged,userLogged)),[topBarFetch,adminLogged,userLogged]);
  useEffect(()=> {setMedia(calcWindowSizes());window.addEventListener("resize",()=>setMedia(calcWindowSizes()));

  },[]);
  const opacityTimer = useRef(null);
  const opacity = animateOpacity ? "animate-opacity-01" : "";

  function handler(i) {
    setPage(i);
    setOpacityAnimation(true);
    if (opacityTimer.current) clearTimeout(opacityTimer.current);
    opacityTimer.current = setTimeout(()=>setOpacityAnimation(false),400);
  }





  return (
    <Shared.Provider value={shared}>
      <div className={"app-container "+opacity} style={{"--bodyWidth":media.width+"px","--bodyHeight":media.height}}>
        <Router currentPage={currentPage} />
        <Login />

      </div>
    </Shared.Provider>

  );



}





export default App;



/*
const [error, setError] = useState(false); const [focus, setFocus]= useState(false);
  const state = {error, setError, focus, setFocus};

<div className="w3-container w3-section "  >
<Div mr={100}>
    <Button text={null}
    colors={buttonColors}
    size={buttonSize}
    isActive={false}
    animate={false}
    rightIcon={true}
    size={buttonSize}
    click={()=>true}
    dropMenu={false}
    colorsContainer={null}
    icon="home"
    dropSize={null}
    dropColors={null}

    />

      </Div>
    <div>
    <Div mr={200}pd={24} colors={{a:1, bkg:"dodger blue",var:["h",10], text:"white",stop1:[0,20], stop2:[100,70], direction:[135,45]}}
    width={400} height={400}
    brd={{s:0, c:"red",rad:10}}
    shadow={{s:2,bm:4,a:1, hover:{trans:-20}}} oClass=" pointer p-rel  w3-hide">
      <Div pd={16} position={["btw","c1",20]} colors={{bkg:"red",text:"black"}} width={300} height={100} oClass="p-abs">
      Prova
      </Div>
    </Div>
    </div>
    <Div mr={{l:"10%",t:32}}>

    </Div>
    <DivSelect value={selecValue} handleChange={setSelect} range={[1,10]} name="provsa"
    size={{width:[300,400], font:[{i:{l:[5,"vw",13,20]},l:12},{i:25,l:13}],height:[40,60],
    pd:{c:12,l:2,i:2},
    lh:1,weight:{i:400,l:700}}}
    brd={{s:2,color:{b:"red",f:"black",e:"blue",h:"orange"},rad:0,bar:{p:"l",s:[8,12],rad:[20,50]}, shadow:{s:[2,5],bm:4,a:0.8,c:["red","black","blue"]}}}
    color={{bkg:{b:"dodger blue",f:"red",e:"yellow"},lBkg:{b:10,e:-5},var:[["r",120],0],direction:45,input:{b:"black",e:"red",f:"white"},label:{b:"white",bkg:"red"}}}
    label="Prova bella" labelPosition="left"
      dropColors={{b:{text:"black",bkg:"dodger blue"},h:{lBkg:10},active:{bkg:"orange",text:"white"}}}
      dropSize={{}}
    />
    <Div mr={{l:"10%",t:32}}>
        <FormInput name="prova" state={state} handleChange={setInput} value={input}
        size={{width:[300,400], font:[{i:{l:[5,"vw",13,20]},l:12},{i:25,l:13}],height:[40,60],
        pd:{c:12,l:2,i:2},
        lh:1,weight:{i:400,l:700}}}
        brd={{s:2,color:{b:"red",f:"black",e:"blue",h:"orange"},rad:0,bar:{p:"l",s:[8,12],rad:[20,50]}, shadow:{s:[2,5],bm:4,a:0.8,c:["red","black","blue"]}}}
        color={{bkg:{b:"dodger blue",f:"red",e:"yellow"},lBkg:{b:10,e:-5},var:[["r",120],0],direction:45,input:{b:"black",e:"red",f:"white"},label:{b:"white",bkg:"red"}}}
        label="Prova bella" labelPosition="left"
         />
    </Div>



</div>







  */
