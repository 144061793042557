import calcMargin from './functions/margin.js';import calcWidth from './functions/width.js';import calcHeight from './functions/height.js';
import calcPadding from './functions/padding.js'; import calcColors from './functions/colors.js';import calcBoxShadow from './functions/shadow.js';
import calcBrd from './functions/brd.js';import calcFontSize from './functions/fontSize.js';
import calcPosition from './functions/position'; import {noMrB, noMrR} from './functions/noMr.js'; import {calcWeight} from './functions/weight.js';
import {cssInts,calcNumClasses,calcFloatClasses,colToCss} from '../ClassesCalculators/calc'; import calcBtnShadow from './functions/btnShadow.js';


class Calc {
    static boxShadow = (shadow, focus,error,divBkg) => calcBoxShadow(shadow,focus,error,divBkg); //  Vars: xs4,xs5,xs6,xs7,a1,rgb1
    static btnShadow = (shadow) => calcBtnShadow(shadow); //  Vars: xs4,xs5,xs6,xs7,a1,hsl2
    static brd = (brd) => calcBrd(brd);
    static colors = (colors) => calcColors(colors);
    static flexCols = (cols,length) => (calcNumClasses(cols,20,1,"sm") + Calc.noMrR(cols) + " nCols " + Calc.noMrB(cols,length)); // Vars: sm
    static fontSize = (size,isVar) => calcFontSize(size,isVar);   // Vars: b,c,d
    static height = (divStyle) => calcHeight(divStyle);
    static lh = (lh) => lh ? calcFloatClasses(lh,20,10,"sm1") + " lHeight " : "";
    static margin = (margin) => calcMargin(margin);
    static maxWidth = (maxWidth) => calcWidth({maxWidth});
    static mrRightVar = (margin) =>  (cssInts(margin ?? 0,"m", "r") + " a-mrR-var ");
    static mrBottomVar = (margin) =>  (cssInts(margin,"n", "b") + " a-mrB-var ");
    static noMrR = (cols) => noMrR(cols); // No vars used
    static noMrB = (cols, length) => noMrB(cols, length);  // No vars used
    static opacity = (a) => Array.isArray(a) ?  (` a-${(a[0] ? a[0].toString().replace(".",""):"1")} ` + (a[1] ? "a-h-" + a[1].toString().replace(".","") : "") ) :  (` a-${(a>=0 ? a.toString().replace(".",""):"1")} `);
    static padding = (pd) => calcPadding(pd);
    static position = (pos) => calcPosition(pos);
    static weight = (weight) => calcWeight(weight);
    static width = (width) => calcWidth({width}); // Var f
    static widths = (divStyle) => calcWidth(divStyle); // divstyle contains width, minWidth and maxWidth Vars e (mawidth),f (width), f (minWidth) -> minwidth will be set only if width is not set because they share the same var
    static varHeight = (height,fVar) => height ? (cssInts(height,fVar?"f":"b","height") + (fVar ?" a-var-height-f " :" a-var-height ")) : ""; // fVar is a bol, set it to true to use f as var instead of the default which is b
}

export default Calc;

// size: int || {l,xm,m,s,xs: val } val = int (px) or [float ,"vw",min,max] float represent the vw value, min and max are ints and limit the viewwidth value in a range in px to avoid getting to small or too big

// other definitions in caldiv prototype
