import React, {useEffect} from 'react';import {validateError} from "../forms";import {isObj,def} from "../../modules";

/* <form action="/CI/log/in" method="post" onSubmit={this.handleSubmit}>
<FormInput name="email" type=text_Default value={this.state.email} label="E-Mail" centerLabel=false_default labelColor=blue_dedault
handleChange={this.handleChange} resetChange={this.resetChange} color="w3-light-gray" validate="email" alertHandler={this.alertHandler}
maxWidth=notComp grid=notComp ref={this.emailRef}/>
*/
function TextAreaInner(props) {
    const {error, setError, focus, setFocus, clicked, setClick} = props.state;
    const {validate,name, value, inputContainer} = props;
    const myRef = React.createRef();

    // If proppped focus is true activate focus on the input using attached ref
    useEffect(()=>{if (focus && myRef.current) myRef.current.focus();},[focus]);

    const handleBlur = (e) => {
        setClick(false); setFocus(false);
        if (props.onBlur) props.blur(e);
        if (validate && e.target.value && ((validate == "password") || (validate == "email"))) {
            const error = validateError(validate, e.target.value);
            if (error) {
                setError(true);
                //this.props.alertHandler(error, this.props.name);
                if (props.resetChange) props.resetChange(e);
            }
        }

    }

    const handleFocus = (e) => {
       setFocus(true);
       if (props.onFocus) props.onFocus(e);
    }

    const type = props.type ? props.type : "text";
    const rows = (props.rowsOnFocus && focus) ? props.rowsOnFocus: (props.rows ? props.rows : 6);

    return (
          <textarea type={type} name={name} value={value} onChange={props.handleChange}  onBlur={handleBlur} onFocus={handleFocus}
          className={"text-area-inner form-input-inner "+inputContainer} rows={rows} ref={myRef} />
    );

}

// Same props as form imput plus rows and rowsOnFocus, to define the number of rows the text area should spread. Default is 4 (same value on focus);

export default TextAreaInner;
