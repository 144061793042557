import {useState,useRef,useEffect,useCallback,useContext,useMemo,createContext,forwardRef} from 'react';
import {Button} from "../";import {DropDown} from './DropDown/DropDown'; import {calcDropContainers} from './calc/dropContainers.js';

export function DropButton({dropSize,dropColors,dropMenu,buttonContainer,...more}) {
    const [dropDownClicked, setDropDownClick] = useState(false);
    const sizeGeneratedClass = useMemo(()=>"a"+Math.round(Math.random() * 100000),[]);
    const dropContainers = useMemo(()=>calcDropContainers(dropSize,dropColors,sizeGeneratedClass),[dropSize,dropColors,sizeGeneratedClass]);
    const {rows,multiCols={}} = dropMenu ?? {};
    const length = rows?.length;
    const hasDropMenu = length > 0;
    if (!hasDropMenu) {
      return null;
    } else {
      const zIndex = dropDownClicked ? " z-50 " : " z-5 ";
      const closeDropDown = ()=>setDropDownClick(false);
      const multiDrop = length>1;
      const context = {dropMenu:{...dropMenu,length,multiDrop},dropContainers,setDropDownClick,dropDownClicked,closeDropDown,hasDropMenu};
      if (multiDrop && !multiCols?.cols) context.dropMenu.multiCols = {cols:1,...multiCols};
      const buttonProps = {...more,context,sizeGeneratedClass,hasDropMenu,zIndex,buttonContainer:(buttonContainer??"")+(dropContainers?.buttonContainer??""),styleSheet:dropContainers?.style};
      return <Button {...buttonProps}  click={()=>{setDropDownClick(!dropDownClicked)}} render={hasDropMenu ?DropDown : null} {...{dropDownClicked, setDropDownClick}} />
    }
}


//<DropDown {...{text,iconProps,dropArrowRef}} {...shared}  {...props.dropMenu} dropSize={props.dropSize} dropColors={props.dropColors}  setClick={setDropDownClick}   clicked={dropDownClicked}/>
