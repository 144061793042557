import {calcNumClasses,colToCss,Calc,addPc} from 'modules';

function calcArrowColors(colors) {
  if (!colors) {
    return "";
  } else {
    return colToCss("hex",colors?.[0]??colors) + colToCss("hex1",colors?.[1]??colors) + colToCss("hex2",colors?.[2]??colors?.[1]??colors) + " arrow-custom-colors ";
  }
}

function calcBoxShadow(colors) {
  let out = "";
  if (colors.shadow !== false) {
    let shadowObj;
    if (!colors.shadow) {
      shadowObj = {s:3,c:colors?.b?.bkg ?? "black",a:0.6,bm:4};
    } else {
      shadowObj = colors.shadow;
      if (!shadowObj.c) shadowObj.c = colors.b?.bkg?.[0] ?? colors.b?.bkg ?? "black";
    }
    out += Calc.boxShadow(shadowObj); //  Vars: xs4,xs5,xs6,xs7,a1
  }
  return out;
}




function calcHrColors(hr,out) {
  if (hr) {
    if (hr?.bkg) {
      out.hrSeparator += colToCss("hex",hr.bkg) + " hex-hr-drop ";
    } else {
      out.colorsStyle["--hr-lBkg"] = addPc(hr?.lBkg ?? 20);
    }
  }
}

export {calcArrowColors,calcBoxShadow,calcHrColors};
