import Calc from './Calc.js';


function calcMarginCont(props) {
  // b,c,d-> height -> free e,f-> width
  //g,m,n,t -> margin  g,m ->position  ***  g,m are used by both margin and position but margin is skipped when position is set because it's already built in position
  // Vars: xs,xs1, hex,hex1  -> brd
  // Vars: xs8,xs9,xs10,xs11,sm,sm1 bRad
  // Vars: hex2,hex3,xs2,xs3,xs12,xs13 brdBar
  // Vars: xs4,xs5,xs6,xs7 box shadow

  const {brd,shadow,oClass,position,mr,margin,fontSize} = props;
  let out = oClass ?? "";
  out += position ? Calc.position(position) : Calc.margin(mr ?? margin); // Margin is already set in calcPosition so wfor positioned elements mr is not considered
  out += Calc.height(props);
  out += Calc.widths(props);
  if (brd) out += Calc.brd(brd);
  if (shadow) out += Calc.boxShadow(shadow,false,false,props.colors?.bkg);
  if (props.tooltip) out += " tooltip-parent ";  // when there is a tooltip
  return out;
}

function calcPaddingCont(props) {
  // fontSize: b,c,d    e,f->free
  // Vars:  g,m,n,t (Padding)
  // colors hsl,hsl1,hex,hex1, sm   xs,sm1 (lineHe fWeight)
  let out = (props.iClass ?? "") + " w100 h100 "+ Calc.fontSize(props.fontSize);;
  if (props.colors) out += Calc.colors(props.colors);
  if (props.pd) out += Calc.padding(props.pd);
  return out;
}


export {calcMarginCont,calcPaddingCont};
