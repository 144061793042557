import {useState} from 'react';
import {D,Slider,FormInput} from 'modules'; import ActionButtons from './ActionButtons';

function ListRow({id,name,colors,dispatchColor,deleteList,dispatchColors}) {
  const [editName,setName] = useState(null);
  const dispatchLists = (result) => {
      if (result?.[0] !== "error") {
        dispatchColors({fetched:result});
        setName(null);
      } else {
        alert("invalid Value");
      }
  }
  const saveList = () => { // For both editing a list and saving a list (the server will decide the right operation based on the structer of the lists variable)
    const data = {method: 'POST', headers: {'Content-Type': 'application/json', },body: JSON.stringify({name:editName,id:id})};
    fetch("/colors/saveList", data).then((res) => res.json()).then(dispatchLists, (error) =>  alert("error"));
  }
  return (
    <D a={{pd:{C:14,S:8},mr:{S:5},colors:{bkg:"white smoke"}}} >
      <D a={{fSize:13,we:500}} cl="flex-cen-btw">
          <Title {...{name,editName,setName}}/>
          <ActionButtons {...{deleteList,editName,id,setName,name,saveList}}/>
      </D>
      <D a={{mr:{T:6}}}>
          <Slider rows={colors} mr={1} pd="5%" arrows={{size:{font:12,heightEm:2,brdRad:50},left:["btw","l","50%"],right:["btw","r","50%"]}} rowFunction={[ColorBtn,{dispatchColor}]} cols={3}/>
      </D>
    </D>
  );
}

const ColorBtn = ({name,id,hex,rgb,hsl,hsv,contrast,dispatchColor}) =>
    <D a={{fSize:12,pd:3,he:40}} cl="flex-cen-cen pointer" style={{backgroundColor:hex,color:contrast}} click={()=>dispatchColor({component:"color",value:{hex,rgb,hsl,hsv,contrast,name,id}})}>
        {name}
    </D>
    ;


function Title ({editName,name,setName}) {
  if (editName) {
    return <FormInput value={editName} handleChange={setName} size={{font:13,height:30,pd:{c:12,i:0},width:100}} color={{bkg:{b:"dodger blue",f:"green",e:"red"},input:{}}}/>
  } else {
    return  <D>{name}</D>;
  }
}
export default ListRow;
