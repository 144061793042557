import {useMemo,useContext,useEffect} from 'react';
import {BtnInner} from './BtnInner';
import {calcBtnCssSize} from './calc/calcBtnCssSize'; import {space,isObj,Cal,Shared} from 'modules';

export function Btn({size,className="",styleSheet="",style={},sizeGeneratedClass,click,...props}) {
  const {media,bot,adminLogged,userLogged} = useContext(Shared);
  const {addedClasses,addedSheet} = useMemo(()=>calcBtnSize(sizeGeneratedClass,size,styleSheet),[sizeGeneratedClass,size,styleSheet]);
  const posWdMr = useMemo(()=>Cal.allObj({pos:props.position,wd:props.width,mr:{L:props.mrL}},media),[props.position,props.width,props.mrL]);
  const classNameNew = className+" "+addedClasses + (posWdMr.cl ?? "");
  const newStyle = isObj(posWdMr.style) ? {...style,...posWdMr.style} : style;

  const click_ = !props.copy ? click : ()=> navigator.clipboard.writeText(addedClasses + " xxxxxxxxxxxxxx "+addedSheet).then(()=>alert("Copied"));
  return <BtnInner {...{...props,click:click_,className:classNameNew,style:newStyle,styleSheet:styleSheet+" "+addedSheet}} />
}


function calcBtnSize(generatedClass,size,styleSheet) {
  const out = {addedClasses:"",addedSheet:""};
  let sizeGeneratedClass = generatedClass;
  if (size) {
    if (!sizeGeneratedClass) sizeGeneratedClass = "a"+Math.round(Math.random() * 10000000);
    calcBtnCssSize(size,sizeGeneratedClass,out);
  }
  if (out.addedClasses || out.addedSheet || styleSheet) out.addedClasses += space(sizeGeneratedClass);
  return out;
}
