import {adjustHeight, adjustWidth, adjBrdRad, adjBrdSize,adjustCont, bEf, cD, int50, int100,sm,xs} from './adjFuncs.js';
import {isObj, isString} from '../../helpers.js';


// given a number capped to 99 return an object with the first and second digit  {b1: b1 , b0:b0}  nunber = b1*10 + 1*b0
export function int99ToBm(value) {
  if (value <10) {
    return {b1: 0, b0:value}
  } else {
    let num = (Math.min(value,99)).toString();
    return {b1: num.charAt(0), b0:num.charAt(1)};
  }
}


// Transform a number from 0 to 5000 to b * m where b has 2 digits and m one digit, number greater than 100 are rounded by 10 (110,120..) number greater than 1000 are rounded by 20 and so on
export const IntToBm = (int) => {
  let b,m;
  if (int <= 100) {
    b = int; m = 1;
  } else if (int <= 1000) {
    b = Math.round(int / 10); m = 10;
  } else if (int <= 2000) {
    b = Math.round(int / 20); m = 20;
  } else if (int <= 3000) {
    b = Math.round(int / 30); m = 30;
  } else if (int <= 4000) {
    b = Math.round(int / 40); m = 40;
  } else if (int <= 5000) {
    b = Math.round(int / 50); m = 50;
  } else {
    b = 100; m = 50;
  }
   return {b:b,m:m};
}

// if instead of a number the input is a string with the unit return an object with the valuea nd the unit {unit:"px",val:int}
//if the unit is vw or vh the flot is multiplied by 100 and stored as an int, then the class that is going to use it need the divid the int by 100 to get the float back
export function parseIntUnitStr(i) {
  let out = {val:null,unit:"px"};
  if (isString(i)) {
    if (i.indexOf("px") >= 0) {
      out.val = parseInt(i,10);
    } else if (i.indexOf("%") >= 0) {
      out.unit = "pc";
      out.val = parseInt(i,10);
    } else if (i.indexOf("vw") >= 0) {
      out.unit = "vw";
      out.val = parseFloat(i) * 10;
    } else if (i.indexOf("vh") >= 0) {
      out.unit = "vh";
      out.val = parseFloat(i) * 10;
    } else {
      out.val = parseInt(i,10);
    }
  } else {
    out.val = Math.round(i);
  }
  return out;
}

/* Old functions perthaps not needed anymore*/

export function calcCssClasses(value, name) {
  let cont = " ";
  const op = {
    height: {prefiss:" a-height-", adjFunc:adjustHeight},
    container:{prefiss:" a-container-", adjFunc:adjustCont},
    width: {prefiss:" a-width-", adjFunc: adjustWidth},
    pWidth: {prefiss:" a-pWidth-", adjFunc: int100},
    fontSize:{prefiss: " a-font-", adjFunc: (i)=>(i>50 ? 50 : (i<10 ? 10 : i))},
    maxHeight: {prefiss: " a-max-height-", adjFunc: (i)=>(i>800 ? 800 : (i<100 ? 100 : (i%50 ? (i-(i%50)+(i%50 >25 ?50:0)) : i)))},
    selectCols: {prefiss: " div-sel-col-", adjFunc: (i)=> (1<1 ? 1 : (i>9 ? 9 : i))},
    b: {prefiss:" b-", adjFunc:int50},e: {prefiss:" e-", adjFunc:int50}, f: {prefiss:" f-", adjFunc:int50}, g: {prefiss:" g-", adjFunc:int50},
    c: {prefiss:" c-", adjFunc:cD}, d: {prefiss:" d-", adjFunc:cD},
    int: {prefiss:" int-", adjFunc:int100},
    sm: {prefiss:" sm-", adjFunc:sm}, sm1: {prefiss:" sm1-", adjFunc:sm}, sm2: {prefiss:" sm2-", adjFunc:sm}, sm3: {prefiss:" sm3-", adjFunc:sm},
    xs: {prefiss:" xs-", adjFunc:xs}, xs1: {prefiss:" xs1-", adjFunc:xs}, xs2: {prefiss:" xs2-", adjFunc:xs}, xs3: {prefiss:" xs3-", adjFunc:xs},
  };
  const pref = op[name].prefiss;
  const adj = op[name].adjFunc;

  if (isObj(value)) {
    cont += (pref + adj(value.l)+" ");
    if (value.m) cont += (pref + adj(value.m)+"-t ");
    if (value.s) cont += (pref + adj(value.s)+"-s ");
  } else if (value || (value === 0)){
    cont += (pref + adj(value)+" ");
  }
  return cont+" ";
}





export function calcCssClass(value, name, media) {
  const op = {brdRad: {prefiss:" a-brdRad-", adjFunc:adjBrdRad}, brdSize:{prefiss:" a-brdSize-", adjFunc:adjBrdSize}};
  let container = " ";
  if (!value && value !== 0) return container;
  if (isObj(value)) {
    container += ((media.isMobile && value.s) ? op[name].prefiss + op[name].adjFunc(value.s) : ((media.isTablet && value.m) ? op[name].prefiss + op[name].adjFunc(value.m) : op[name].prefiss + op[name].adjFunc(value.l)));
  } else {
    container += op[name].prefiss + op[name].adjFunc(value);
  }
  return container + " ";
}

/*
CalcFont creates css classes for the font size with differencieting 5 different screens with 5 media queries: l, xm (1450 max), m (1200 max), s (734 max) xs /350 max)
calcFont(fSize)
if fSize is an int => all screens ahve the same size given by int, the unit will be px
if fSize is an object each screeb will have his size, you can skip some screen, only l size is compulsory {l:val,xm:val, m:val,s:val,xs;val}
  val = int (px will be te unit) or an array [int,"vw",min (int (px)),max (int (px))] (size is in view width)
  each screen can use different units. l can be int (px) while s can be vw abd be represented by an Array
  use an Array with length 4 when min and max value is specified [int,"vw",min (int (px)),max (int (px))] or with a length of 2 when no min max is used: [int,"vw"]
Example:
fSize: {l:19,xm:[3,"vw",15,21],m:15,s:[2.6,"vw",12,20],xs:[2.0,"vw",11,18]} => large screen 19px, large tablet font size is 3vw but not smaller than 15px and not larger than  21px .....
*/
