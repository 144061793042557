import {FlexCols,DivSelect,Btn} from 'modules';

function Menues({setActivePicker,activePicker,pickersList,editSettings,settings,showSettings,setSettingsMenu,activeGroup,setActiveGroup}) {
  const sd = {
    size: {font:{i:14,l:12},pd:{c:12}},
    color:{bkg:{b:"dodger blue",f:"green",e:"red,a:1"},
            lBkg:10,
            input: {b:"white"}
          },
    className:"a-flex-col"
  };
  const groupList = ["Bkg","Text","Brd","Icon","Arrow"];
  if (showSettings) {
    return (
      <FlexCols  length={5} cols={3} pd={1} mr="3%">
          <DivSelect options={[["lTxt On",true],["Txt Off",false]]} value={settings.lTxt} {...sd} handleChange={(value)=>editSettings({row:"lTxt",value})}/>
          <DivSelect options={[["lBkg On",true],["lBkg Off",false]]} value={settings.lBkg} {...sd} handleChange={(value)=>editSettings({row:"lBkg",value})}/>
          <DivSelect options={[["lICon On",true],["Icon Off",false]]} value={settings.lIcon} {...sd} handleChange={(value)=>editSettings({row:"lIcon",value})}/>
          <DivSelect options={[["lArrow On",true],["Arrow Off",false]]} value={settings.lArr} {...sd} handleChange={(value)=>editSettings({row:"lArr",value})}/>
          <Btn text="close" click={()=>setSettingsMenu(false)} className="a-flex-col"/>
      </FlexCols>
    );
  } else {
    return (
      <FlexCols  length={3} cols={3} pd={10} mr="3%">
          <Btn text="Settings" click={()=>setSettingsMenu(true)}  className="a-flex-col"/>
          <DivSelect options={groupList} value={activeGroup} {...sd} handleChange={setActiveGroup}/>
          <DivSelect options={pickersList} value={activePicker} {...sd} handleChange={setActivePicker}/>
      </FlexCols>
    );
  }
}

export default Menues;
