import {colToCss,Cal,isObj,mediaVal} from '../../../modules';
import {calcStyle} from '../helpers';
import {calcGradientDirection, calcComponent, calcColorStops, calcGradientClass} from './colorsHelper.js';

function calcColors(color,out,media) {

  const {a,text,textHov,lh,weight} = color;
  const bkg = mediaVal(color.bkg,media)
  const hasTwoColors = Array.isArray(bkg);
  const clVar = mediaVal(color.var,media);
  const isGradient = hasTwoColors || clVar;
  //out.cl += Cal.opacity(a);
  if (isGradient) {
    calcGradientBkg(color,hasTwoColors,out,media,bkg,clVar);
  } else if (bkg) {
    calcBkg(color,out,bkg);
  }
  if (a) out.cl += Cal.a(a,media);
  if (text) out.cl += colToCss("font",text);
  if (lh) out.cl +=  ` lh-${mediaVal(lh,media).toString().replace(".","")} `;
  if (weight && [100,200,300,400,500,600,700].includes(weight)) out.cl += ` w-${weight} `;
}

function calcBkg(color,out,bkg) {
  const lHov= mediaVal(color.lHov);
  const bkgHov = mediaVal(color.bkgHov);
  out.cl += colToCss("hsl",bkg);
  if (lHov) {
    let lHover = Number.isInteger(lHov) ? lHov : 10;
    if (lHover > 20)  lHover = 20;
    out.cl += lHover > 0 ? " bkg-LH " : " bkg-lH ";
    out.cl += ` lHov-${Math.abs(lHover)} `;
  } else {
    out.cl += " bkg ";
    if (bkgHov) out.cl += colToCss("hsl1",bkgHov) + " bkg-hov ";
  }

}

function calcGradientBkg(color,hasTwoColors,out,media,bkg,clVar) {
  const blurVar = Array.isArray(clVar) && Array.isArray(clVar[0]) ? clVar[0] : clVar ; // To set an hover value we use an array [blur, hover], instead of just blur
  out.cl += calcGradientClass(color,hasTwoColors,blurVar,clVar);
  if (hasTwoColors) {
    out.cl += colToCss("hsl",color.bkg[0]) + colToCss("hsl1",bkg[1]);
  } else {
    out.cl += (["r","g","b"].includes(blurVar[0])) ? colToCss("rgb1",bkg) : colToCss("hsl",bkg);
    calcComponent(color,blurVar,out,clVar); // Vars: sm, sign,xs9, sign1,
  }
  calcColorStops(color,out,media);
  calcGradientDirection(color,out,media);
}

export default calcColors;
