import {Btn,D,Color} from 'modules';

function Menu({setView,saveBtn,deleteBtn,addBtn,button}) {
  const showListView = () => {setView("list");if (button?.edited || !button.id) saveBtn();}; // If we swith from the edit view to the buttons list we save the changes to the button being edited if we made some edits.
  const {name} = button;
  return (
    <D a={{pd:10}} cl="flex-cen-start">
      <Btn text="List" click={showListView} size={{height:"2.2em",font:13,}} width="auto"/>
      <Btn text="Add New" click={addBtn} size={{height:"2.2em",font:13,}} width="auto"  mrL={10}/>
      <Btn text={"Save "+name} click={saveBtn} size={{height:"2.2em",font:13,}} width="auto"  mrL={10}/>
      <Btn text={"Delete "+name} click={deleteBtn} size={{height:"2.2em",font:13,}} width="auto"  mrL={10}/>
    </D>
  );
}

export default Menu;
