import {Btn,D,Color} from 'modules';

function SelectMenu({add,setView,editList,toggleEdit,deleteSelected}) {
  const shared = {size:{height:"2.2em",font:13,},width:"auto"};
  return (
    <D a={{pd:10}} cl="flex-cen-start">
      <Btn text="Add" click={add}  {...shared}/>
      <Btn text={editList ? "Cancel" : "Edit"} click={toggleEdit} {...shared}/>
      <Btn noShow={!editList || !editList.length} text="Delete Selected" click={deleteSelected} {...shared}/>
      <Btn noShow={editList} text="close" click={()=>setView("edit")} {...shared}/>
    </D>
  );
}

export default SelectMenu;

/*
<Btn text="Add New" click={addBtn} size={{height:"2.2em",font:13,}} width="auto"  mrL={10}/>
<Btn text={"Save "+name} click={saveBtn} size={{height:"2.2em",font:13,}} width="auto"  mrL={10}/>
<Btn text={"Delete "+name} click={deleteBtn} size={{height:"2.2em",font:13,}} width="auto"  mrL={10}/>
*/
