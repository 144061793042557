

function H3({h3,h3Cl}) {
  if (!h3) {
    return null;
  } else {
    return (
      <h3 className={"f1s-cl " + h3Cl} >{h3}</h3>
    );
  }
}

export default H3;
