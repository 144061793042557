import {useMemo, useState,useReducer,forwardRef} from 'react'; import './range.css';

import {Color} from 'modules';

const RangeInput = forwardRef(({type,color,dispatchColor,p,handleTouch,maxSteapValueObj},ref) => {
  const [tempValue,setTempValue]=useState(null); // we need this for touch click since handle change only set colors for touch move or mouse events
  const style = color[type+"Gradient"];

  const handleChange = (e) => {
    if (p && p !== "sliding") { // This vase only for toouch screen in case of a click without moving, we hold the value until touh ends
      setTempValue(e.target.value);
    } else { // on mouse events or on touch events if p has been set to sliding
      dispatchColor({component:type,value:e.target.value});
    }
  }

  const touchEnd = (e) => {
    if (!["scrolling","sliding"].includes(p)) dispatchColor({component:type,value:tempValue}); // Touch click without moving case: handlechange saved the value in temp value and didn't dispatchColor
    handleTouch({e,type:"touchEnd"});setTempValue(null);
  }
  const handlers = {onChange:handleChange,onTouchStart:(e)=>handleTouch({e,type:"touchStart"}),onTouchMove:(e)=>handleTouch({e,type:"touchMove"}),onTouchEnd:touchEnd,onMouseDown:(e)=>handleTouch({e,type:"mouseDown"}),onMouseUp:(e)=>handleTouch({e,type:"mouseUp"})};

  return   <input name={type}  className={"w100 pointer range contrast-"+color.contrast+(p && p!== "scrolling" ? " rg-ph-sliding":"")} type="range" {...maxSteapValueObj} {...handlers} style={style}  ref={ref}/>
});

export default RangeInput
