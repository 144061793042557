import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';import {isString,inProduction} from './modules';
//import reportWebVitals from './reportWebVitals';
let data = null;
if (inProduction) { // don't put this check into a function bewcause for some reason it will return always true even in development
  disableReactDevTools();
}

if (isString(window.SERVER_DATA) && window.SERVER_DATA[0] !== "<") {
  data = JSON.parse(window.SERVER_DATA);
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

    <App  serverData={data}/>
,

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();


//  <React.StrictMode>


function disableReactDevTools() {
  // Check if the React Developer Tools global hook exists
  if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ !== "object") {
    return;
  }

  for (const prop in window.__REACT_DEVTOOLS_GLOBAL_HOOK__) {
    if (prop === "renderers") {
      // initialise this with an empty `Map`,
      // else it will throw an error in console

      window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = new Map()
    } else {
      // Replace all of its properties with a no-op function or a null value
      // depending on their types

      window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] =
        typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] === "function"
          ? () => {}
          : null;
    }
  }
}
