import {useReducer,useMemo,useState,useEffect,useContext} from 'react'; import Menu from './Menu'; import ComponentAndCssNames from './ComponentAndCssNames';
import {Btn,Style,isObj,FlexCols,D,inProduction,Color,Shared} from 'modules';  import Pick from './Pick/Pick'; import TestButtons from './TestButtons'; import BtnList from './BtnList';

export function BtnNew(props) {
  const [index,setIndex] = useState(0);
  const [view,setView] = useState("list");
  const [buttons,dispatchButtons] = useReducer(btnReducer,[]);
  const button = buttons?.[index];

  const {media} = useContext(Shared);

  //useEffect(() =>{if (button) setBkg(new Color({hsl:{h:button.bkg.b[0],s:button.bkg.b[1],l:button.bkg.b[2]}}))},[]);
  // dispatchColor is called by Pick to update the color of the button poinhted by index
  const dispatchColor = (obj) =>  dispatchButtons({...obj,index});
  // init is called only in production to fecth the buttons array from the server
  const init = () => {
    if (inProduction) {
      const data = {method: 'POST', headers: {'Content-Type': 'application/json', },body: null,};
      fetch("/buttons/get", data).then((res) => res.json()).then((result)=>dispatchButtons({fetched:result}), (error) =>  alert("fecth btn error"));
    }
  };
  const deleteBtn = (i=index) => {
    if (inProduction) {
      const id = buttons[i].id;
      if (id) { // the button has been alreadys aved to the server to we have to request to the server to delete it
        const data = {method: 'POST', headers: {'Content-Type': 'application/json', },body: JSON.stringify([id]),};
        fetch("/buttons/delete", data).then((res) => res.json()).then((result)=>dispatchButtons({fetched:result}), (error) =>  alert("fecth btn error"));
      } else { // the button has not been saved to the server yet, so we have just to removed it locally splicing the buttons array
        buttons.splice(i,1);
        dispatchButtons({updated:true});
      }
    } else { // in developement
      buttons.splice(i,1);
      dispatchButtons({updated:true});
    }
    setView("list");
  }
    const addBtn = () => {
    buttons.push({tempId: Math.random(),componentName:"",cssClass:"",name:"New",bkg:{b:[0,0,0],lH:66,lC:90},text:{lT:100,lTHov:95,lTCl:56},icon:{},arrow:{},brd:{}});
    setIndex(buttons.length-1);
    dispatchButtons({});
    setView("edit");
  };
  const deleteButtons = (ids) => {
    if (ids.length) { // the button has been alreadys aved to the server to we have to request to the server to delete it
      const data = {method: 'POST', headers: {'Content-Type': 'application/json', },body: JSON.stringify(ids),};
      fetch("/buttons/delete", data).then((res) => res.json()).then((result)=>dispatchButtons({fetched:result}), (error) =>  alert("fecth btn error"));
    }
  }
  const saveBtn = (i=index) => {
    if (inProduction) {
        const data = {method: 'POST', headers: {'Content-Type': 'application/json', },body: JSON.stringify(buttons[i]),};
        fetch("/buttons/save", data).then((res) => res.json()).then((result)=>dispatchButtons({fetched:result}), (error) =>  alert("fecth btn error"));
    }
  }
  useEffect(init,[]);
  const handleList = (index) => {if (index>=0) setIndex(index);setView("edit")};

  if (view === "edit") {
    return (
        <D>
          <div className="p-sticky" style={{top:"10px",backgroundColor:"white",padding:"10px",width:!media.isMobile?"60%":"100%"}}>
            <Menu {...{addBtn,setView,saveBtn,saveBtn,deleteBtn,button}}/>
            <TestButtons {...{button,props}} />
            <ComponentAndCssNames {...{dispatchColor,button}}/>
          </div>
          <Pick {...{button,dispatchColor,index}}/>
        </D>
    );
  } else if (view === "list"){
    return <BtnList {...{buttons,handleList,index,addBtn,deleteButtons}}/>;
  } else {
    return null;
  }

}




const btnReducer = (buttons,obj) => {
  const {row,col,value,index,fetched} = obj;
  if (fetched) {
    return fetched;
  } else if (buttons) {
    // if col is not set we don't update anything but we still return a new object to force the state to update, because if a child called the reducer it wants the state to be updated
    if (col || row) {
      if (col) {
        buttons[index][row][col] = value;
      } else {
        buttons[index][row] = value;
      }
      buttons[index].edited = true;
      buttons[index] = {...buttons[index]};
    }
    return JSON.parse(JSON.stringify(buttons));
  }
};




/*
const colors = {
  bkg:{b:[h,s,l],lH,bkgH,lC,a:[ha,sa,la]}.
  text:{cl,clH,lT,lTHov,lTCl,clC,clA},
  icon:{icCl,icClH},
  arrow:{arCl,arClH},
  brd:{btdCl,brdClH},
};

*/
