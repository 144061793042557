import {colToCss,mediaVal,isObj} from '../../../modules';import {calcStyle} from '../helpers';
//import {isObj} from '../../../helpers.js';

function calcBrd(obj,out,media) {
  let brd,  brdHov = null;
  if (Array.isArray(obj)) { // If we set different borders on blur and hover we used an array of objects[blur , hover]
    brd = obj[0];
    brdHov =obj[1];
  } else { // It's not an array of objects but a simgle object => we only set blur values
    brd = obj;
  }
  if (!isObj(brd)) {
    return;
  } else {
    if (brd.s && brd.c) {
      out.cl += colToCss("hex",mediaVal(brd.c));
      calcStyle(brd.s,out,"brd",media);
    }
    // on hover we don't need to set both size and color if they are declared on blur, if we want for example to change only the size on hover, we don't have to set the hover color,it will be used the blur color, but that value needs to be set.
    // If no blur border is defined and w want an hover color we must define both size and color on hover!
    if ((brdHov?.s && brdHov?.c) || (brdHov?.s && brd?.c) || (brd?.s && brdHov?.c)) {
      out.cl += colToCss("hex1",mediaVal(brdHov.c ?? brd.c));
      calcStyle(brdHov.s ?? brd.s,out,"brdH",media);
    }
    const barPosition = brd?.bar && brd?.bar?.p && ["l","r","t","b"].includes(brd.bar.p) ? brd.bar.p : "l";
    calcBrdRadius(brd,brdHov, barPosition,media,out);
    calcBrdBar(brd, brdHov, barPosition,media,out);
  }

}


function calcBrdRadius(brd,brdHov, barPosition,media,out) {
  // Max Possible Border Radius is 50% of min(width,height), so if with < height and rad=width/2 the op and bottom border will be spherical, instead if height>width and rad = height/2 the left and right side will be spherical
  // to have a sphere the width must be equal to height and radius 50%
  if ((!brd && !brdHov) || (!brd?.rad && !brdHov?.rad && !brd.bar?.rad && !brdHov?.bar?.rad)) return;
  const brdRad = brd?.rad ?? 0;
  if (brd?.bar && brd.bar?.rad) {
    calcStyle(brd.bar.rad,out,` bRad${barPosition.toUpperCase()}bar `,media,"barRad");
    calcStyle(brd?.rad ?? 0,out,"",media,"bRad");
  } else if (brd?.rad) {
    calcStyle(brd?.rad ?? 0,out,"bRad",media);
  }
  if (brdHov?.bar && brdHov.bar?.rad) {
    calcStyle(brdHov.bar.rad,out,` bRad${barPosition.toUpperCase()}barH `,media,"barRadH");
    calcStyle(brdHov.rad ?? brd?.rad ?? 0,out,"",media,"bRadH");
  } else if (brdHov?.rad) {
    calcStyle(brdHov.rad,out,"bRadH",media);
  }
}

function calcBrdBar(brd, brdHov, barPosition,media,out) {
    // only one between color and size is compulsory, the border value will replace the missing value but it must be defined
    if (brd.bar && ((brd.bar?.s && brd.bar?.c) || (brd.bar?.s && brd?.c) || (brd?.s && brd.bar?.c)))  {
      out.cl += colToCss("hex2",brd.bar?.c ?? brd.c);
      calcStyle(brd.bar?.s ?? brd.s,out,barPosition+"Bar",media);
    }
    if (brdHov?.bar && ((brdHov.bar?.s && brdHov.bar?.c) || (brdHov.bar?.s && (brd.bar?.c ?? brdHov.c ?? brd.c)) || ((brd.bar?.s ?? brdHov.s ?? brd.s) && brdHov.bar?.c)))  {
      // We ccheck first if bar position has been defined in brd.bar.p otherwise we use brdHov.bar.p because allowing diffenret position in blur and hover state means we are adding a new bar instead of modifying thew other one, so we should disaable the other one making css more complicate also because we could decide to have both bars on hover. So for now better to allow just one bar and blur position overrides hover position
      const barHovPos = barPosition ?? (["l","r","t","b"].includes(brdHov.bar.p) ? brdHov.bar.p : "l");
      out.cl += colToCss("hex3",brdHov.bar?.c ?? (brd.bar?.c ?? brdHov.c ?? brd.c));
      calcStyle(brdHov.bar?.s ?? (brd.bar?.s ?? brdHov.s ?? brd.s),out,barHovPos+"BarH",media);
    }
}

export default calcBrd;
