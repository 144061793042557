import {calcNumClasses} from '../../../modules';
import {isObj} from '../../../helpers.js';

export const noMrR = (cols) => {
  const colsObj = isObj(cols) ? {l:cols.l,m:cols.m ?? cols.l,s:cols.s ?? cols.m ?? cols.l} : {l:cols,m:cols,s:cols};
  return calcNumClasses(colsObj,20,1,"no-mrR");
}
export const noMrB = (cols, length) => {
    const colsObj = isObj(cols) ? {l:cols.l,m:cols.m ?? cols.l,s:cols.s ?? cols.m ?? cols.l} : {l:cols,m:cols,s:cols};
    const resS = length % colsObj.s;
    const nS = resS ? resS : colsObj.s;
    const resM = length % colsObj.m;
    const nM = resM ? resM : colsObj.m;
    const resL = length % colsObj.l;
    const nL = resL ? resL : colsObj.l;
    return ` no-last-mrB-${nS}-s no-last-mrB-${nM}-t no-last-mrB-${nL} `;
}
