import {useRef,forwardRef} from 'react'; import './labels.css';
import {hasValue,isObj,def} from  'modules';


/*  <div className=""></div>  */

function Required(props) {
    return null;
}

function OuterLabel({position,text,clicked,covering,id}) {
  if ((position !== "out") || !text) {
    return null;
  } else {
    return <label for={id} className="form-label"><div className="w100 truncate">{covering}</div></label>;
  }
}

function CoveringLabel({covering,clicked,setClick,id,position,setFocus,value}) {
    const handleClick = (e)=> {e.stopPropagation();setClick(true); setFocus(true);}
    if ((position === "out") || clicked || !covering || value) {
      return null;
    } else {
      return (
        <label for={id} className="form-label covering truncate" onClick={handleClick}> {covering} </label>
      );
    }
}

const Label = forwardRef(({position,text,covering,clicked,value,style,id},ref) => {
  if ((position === "out")  || !text || (!hasValue(value) && !clicked && covering)) {
    return null;
  } else {
    return <label for={id} className="form-label flex-cen-cen" ref={ref} style={style}><div className="w100 truncate">{text}</div></label>;
  }
});



export {Label, CoveringLabel, Required, OuterLabel};
