import {useContext,useMemo} from 'react'; import {Shared} from './modules';
// import Link from './Link';

function Link({click,href,style,className,noPointer,btnType,...props}) {
    const {bot} = useContext(Shared);
    const [h,s,click_] = useMemo(init,[click,href,className,style]);

    function clickHandler(e) {
      if (!props.propagate) e.stopPropagation();
      (Array.isArray(click_)) ? click_[0](click_[1]) : click_(e);
    }

    function init(){
      const h = {}, s = {};
      const click_ =  ((click && !bot) || (click && bot && !href)) ? click : null;
      h.href = !click || (click && bot) ? href : null;
      s.className = ((href || click && !noPointer) ? "pointer " : "") + (className ?? "");
      if( props.noFollow) h.rel = props.noFollow;
      if (props.newWindow) h.target = props.newWindow;
      if (style) s.style = style;
      return [h,s,click_];
    }

    if (h.href) {
        return <a {...h} {...s} >{props.children}</a>;
    }  else if (click_) {
        return <div onClick={clickHandler} {...s} >{props.children}</div>;
    } else if ((className || style) && !props.strict ){
        return <div {...s}>{props.children}</div>;
    } else {
      return props.children;
    }
}


export default Link;

/*
<Link className="string" href= click= >
    Children
</Link>
click = handler or [handler, handler-parameter]; handler-parameter is passed to a shared handler to uniquely identify the calling component instead of using a dedicated handler for that component
*/
