 import {D} from 'modules';import './header.css';

const RangeValue = ({type,value,max,dispatchColor,width,noValue,lRef}) =>{
  const size = 34;
  const adj =  -size / max * value; // the thumb has a size so when while moving to the range limit the actual position will be in the center of the thumb in the range center, instead on the left range limit 0 will be positioned on the left hedge of the thum, so we calculate a linear equation knowing that the adj will be 0 in the center and +- 0.5 thumbSize on the limit
  const levelPos = {left: ((width  * (value)/ max)) + adj +"px",bottom:"7px"};
  return (
    <D a={{he:30}} cl="p-rel z-5">
        <D noShow={noValue} a={{wd:size,he:17,brd:{rad:8.5},fSize:12,colors:{bkg:"red",text:"white"}}} cl="p-abs z-10 range-level flex-cen-cen w-700 no-select" style={levelPos}>{lRef ? value-lRef: value}</D>
    </D>
  );
};

export default RangeValue;
