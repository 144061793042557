import {cssInts} from 'modules';

export const fixOverFlow = (clicked,dropDownRef,xAdj,setXadj,leftPositioned)=> {
    if (clicked) {
      const dropDownRect = dropDownRef.current ? dropDownRef.current.getBoundingClientRect() : null;
      if (!dropDownRect) return;
      const dropDownLeftPos  = dropDownRect.x;
      const dropDownRightPos  = dropDownRect.right;
      //console.log("right: "+dropDownRect.right);
      // No css transition when direction is set to right or left, because the orizzontal animation from center to left/right is unwanted, we only want to show the vertical, but after every click we reset the direction to center in case the button position changes and the drop down is not anymore overflowing. Furthermore to calculate the tip position we would have to wait to transition on translate to end and so set a timeout. For these two reasons we added css transform trasnsition only
      // when direction is center or full width
      const diff = dropDownRightPos - window.innerWidth;
      //console.log("diff: "+diff);

       if (dropDownLeftPos < 0 && !xAdj) {
        setXadj(dropDownLeftPos * -1); // we round every number to decimal ending, ex 307 -> 310 css vars are rounded and add a 10 px margin
      } else if (!leftPositioned &&  diff > 0) {
        setXadj(-1 * diff);
      }
    } else { // when the button is closed we reset xAdj in case the button position in the document changes when reopened
      setXadj(0);
    }
  }


  export const setTipPosition = (dropDownRef,dropArrowRef,innerRef,setTipPos,noArrow)=> {
      if (!dropArrowRef || !dropDownRef.current )  {
        return;
      } else {
        // **** Attention *** We are using first child to select the tip instead of forwarding further down the ref, if the tip position change we have to edit this
        const tipSize = innerRef.current?.firstChild?.getBoundingClientRect().width ;
        const dropDownRect = dropDownRef.current.getBoundingClientRect();
        const dropDownLeftPos  = dropDownRect.x;
        const dropDownRightPos  = dropDownRect.right;
        // If the drop arrow is disabled the tip will be positioned in the center of the button
        const arrowReact = noArrow ? null : dropArrowRef.current.getBoundingClientRect();
        const [dropDownArrowPos,arrowSize] = noArrow ? [(dropDownRightPos-dropDownLeftPos)/2,0] : [arrowReact.x,arrowReact.width];
        if (dropDownArrowPos > dropDownRightPos-tipSize || dropDownArrowPos < dropDownLeftPos+tipSize) {
          setTipPos((dropDownRightPos-dropDownLeftPos-tipSize)/2+"px");
        } else {
          const tipPos = dropDownArrowPos + arrowSize / 2 - dropDownLeftPos - tipSize / 2;
          setTipPos(tipPos+"px");
        }
        console.log(dropDownLeftPos)
    }
  }
