import {useState} from 'react';import {Button}from 'modules';
import InputContainer from '../InputContainer/InputContainer';import ColorsLists from './ColorsLists/ColorsLists';import SaveColor from './SaveColor';



function PickersSection({a}) {
  const [saveView, setSaveView] = useState(false);
  const {color} = a;
  const saveText = color.name ? "Edit Colot" : "Save color";
  if (saveView) {
    return <SaveColor {...{setSaveView,...a}}/>
  } else {
    return (
      <div className="w100 ">
        <InputContainer type="hue" {...a}/>
        <InputContainer type="grey" {...a}/>
        <ColorsLists {...a}/>
        <Button style={{marginTop:"16px"}} text={saveText} click={()=>setSaveView(true)}  size={{font:{d:"19px"},height:40}}  colors={{b:{text:"white",bkg:"dodger blue"},c:{bkg:"blue"},h:{lBkg:10}}}/>
      </div>
    );
  }
}


export default PickersSection;
