import {colToCss,mediaVal,addStyle,Cal,calcStyle,calcNumClasses,isNumber} from '../../modules';

function calcHeader(obj={},media,show,gradient) {
  //console.log("£calc header")
  let out = {style:{}, cl:"", h1Cl:"",h3Cl:""};
  const {wd,maxWd,mr,bkg,a,position,textAlign,brd,font} = obj;
  if (show) {
      addStyle([[obj?.pdS,"pdS"],[obj?.pdC,"pdC"],[obj?.gap,"gap"]],out.style,media);
      Cal.fSize(font?.s1 ??20,out,media,0);
      if (font?.em3) {
        calcStyle(font.em3.toString(),out,"em3",media); // We need to string toa vopid adding a px suffix to numbers
      } else if (font?.s3) {
        Cal.fSize(font?.s3,out,media,1);
      }
      if (font?.lh) {
        out.cl += Cal.lh(font.lh);
      } else {
        if (font?.lh1) out.h1Cl += Cal.lh(font.lh1);
        if (font?.lh3) out.h3Cl += Cal.lh(font.lh3);
      }
      if (font?.w) {
        out.cl += Cal.we(font.w);
      } else {
        if (font?.w1) out.h1Cl += Cal.we(font.w1);
        if (font?.w3) out.h3Cl += Cal.we(font.w3);
      }
      if (font?.c) {
        out.cl += colToCss("font",font.c);
      } else {
        if (font?.c1) out.h1Cl += colToCss("font",font.c1);
        if (font?.c3) out.h3Cl += colToCss("font",font.c3);
      }
      if (font?.cHov) {
        out.cl += colToCss("hex",font.cHov,"a-color-h");
      } else {
        if (obj.font?.c1Hov) out.h1Cl += colToCss("hex",font.c1Hov,"a-color-h");
        if (obj.font?.c3Hov) out.h3Cl += colToCss("hex1",font.c3Hov,"a-color1-h");
      }
      Cal.all({wd,maxWd,mr,brd},out,media);
      if (bkg || a) Cal.colors({bkg:bkg ?? "black",a:a ?? 0.3},out,media);
      const [pos0,pos1] = [mediaVal(position?.[0],media),mediaVal(position?.[1],media)];
      out.align = ["cen","start","end"].includes(pos0) ? pos0 : "cen";
      const justify = ["cen","start","end"].includes(pos1) ? pos1 : "cen";
      out.flexPosition = ` flex-${out.align}-${justify} `;
      let textAlign_ = mediaVal(textAlign,media);
      if (!["cen","start","end"].includes(textAlign_)) textAlign_ = "start";
      out.textAlignCl = ` flex-${textAlign_}-cen `;
      out.gradient = calcGradient(out.align,gradient,media);
  }
  return out;
}

function calcGradient(align,gradient,media) {
  if (!gradient?.stop)  {
    return null;
  } else {
      const suffix = (gradient.bar && align === "end") ? "bar-end" : align;
      const gradientStop = mediaVal(gradient.stop,media);
      const gradOpacity = mediaVal(gradient.a ?? 1,media);
      let cl = `hero-grad-${suffix} `+Cal.a(gradOpacity,media) + colToCss("rgb1", gradient?.color??"black");
      // If bar is not just true but contains the bar opacity we store it in the var a1 to differenciate from the a var used for the end part of the gradient which is bigger and may need an higher opacity
      if (gradient.bar && align === "end") cl += Cal.a(isNumber(gradient.bar) ? gradient.bar:0.2,media,1);
      return {cl,style:{"--gradStop":gradientStop}};
  }
}



export {calcHeader};
