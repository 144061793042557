import React, {useState,useRef,useEffect,useCallback,useContext} from 'react';
import {D,TopBar,Pickers,BtnNew,Shared} from 'modules';

function BtnTest(props) {
  const {media} = useContext(Shared);
  const color = {
    bkg:{b:[180,100,50],lH:45,lC:80},
    text:{cl:"black",clH:"red",clC:"white"},
    icon:{icCl:"red",icClH:"green"}

    };
  return (
    <D  a={{}} style={{"--top-bar-height":"65px"}}>
          <TopBar  bkg="blue" a={1} position="" />
          <D a={{pd:media.isMobile?10:100,colors:{bkg:"azure"}}}>
              <BtnNew text="tests" icon="home" className="" pickers={true} click={()=>true}  />

          </D>

    </D>
  );
}

export default BtnTest;

/*
const colors = {
  bkg:{b:[h,s,l],lH,bkgH,lC,a:[ha,sa,la]}.
  text:{cl,clH,lT,lTHov,lTCl,clC,clA},
  icon:{icCl,icClH},
  arrow:{arCl,arClH},
  brd:{btdCl,brdClH},
};

*/
