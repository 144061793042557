import {Button,Div,calcButtonColors,calcDropDownColors} from '../../modules';

export const buttonColors = {
  b:{text:"white",bkg:"blue"},
  h:{lBkg:10},
  a:{bkg:"orange",text:"blue",icon:"red",brd:"red",var:["h",12],direction:90},c:{lClick:620},


};
export const dropDownColors = {b:{bkg:"dodger blue",text:"red",lExp:-6.9},
                          h:{lBkg:10},hr:{lBkg:10},expArrow:["green","orange","red"],header:{text:["yellow","red","black"]},expandedBkg:"red"};


export const colors = {btnColors:calcButtonColors({colors:buttonColors}),dropColors:dropDownColors};
