import {Pickers,Color} from 'modules';

function TextPickers({activePicker,dispatchColor,button,settings,lRef}) {
  const a = {position:"relative"};
  if (!settings.lTxt || (activePicker === "Active")) {
    a.smallHexMenu = true;
    [a.color,a.setColor] = textSettlers(button,activePicker,dispatchColor);
  } else {
    a.editComponent = {type:"l",lRef,header:"Luminosity"};
    [a.color,a.setColor] = lTextSettlers(button,activePicker,dispatchColor);
  }
  return  <Pickers {...a}/>
}

  function textSettlers(button,activePicker,dispatchColor) {
    let color,setColor;
    if (activePicker === "Blur") {
      color = new Color({hex:button.text.cl});
      setColor = (color) => dispatchColor({row:"text",col:"cl",value:color.hex});
    } else if (activePicker === "Hover") {
      color = new Color({hex:button.text.clH ?? button.text.cl});
      setColor = (color) => dispatchColor({row:"text",col:"clH",value:color.hex});
    } else if (activePicker === "Click") {
      color = new Color({hex:button.text.clC ?? button.text.cl});
      setColor = (color) => dispatchColor({row:"text",col:"clC",value:color.hex});
    } else if (activePicker === "Active") {
      color = button.text.clA ? new Color({hex:button.text.clA}) : new Color({hex:"#ffffff"});
      setColor = (color) => dispatchColor({row:"text",col:"clA",value:color.hex});
    }
    return [color,setColor];
}

function lTextSettlers(button,activePicker,dispatchColor) {
  let color,setColor;
  if (activePicker === "Blur") {
    color = new Color({hsl:{h:button.bkg.b[0],s:button.bkg.b[1],l:button.text["lT"] ?? button.bkg.b[2]}});
    setColor = (color) => dispatchColor({row:"text",col:"lT",value:color.hsl.l});
  } else if (activePicker === "Hover") {
    color = new Color({hsl:{h:button.bkg.b[0],s:button.bkg.b[1],l:button.text.lTHov ?? button.bkg.b[2]}});
    setColor = (color) => dispatchColor({row:"text",col:"lTHov",value:color.hsl.l});
  } else if (activePicker === "Click") {
    color = new Color({hsl:{h:button.bkg.b[0],s:button.bkg.b[1],l:button.text.lTCl ?? button.bkg.b[2]}});
    setColor = (color) => dispatchColor({row:"text",col:"lTCl",value:color.hsl.l});
  }
  return [color,setColor];
}

export default TextPickers;
