import {calcNumClasses,colToCss,isObj} from '../../../modules';

export const calcBoxShadow = (shadow,out) => {

  //  Vars: xs4,xs5,xs6,xs7,a1,rgb1
  if (shadow === true) {
    out.shared = " a-shadow-fb ";
  } else if (isObj(shadow)){
    const size = shadow.s;
    if (!size) return;
    if (Array.isArray(size)) { // xs
      out.blur += calcNumClasses(size[0],10,1,"xs4");
      out.error += calcNumClasses(size?.[2]??size[0],10,1,"xs4");
      out.focus += calcNumClasses(size[1],10,1,"xs4");
    } else {
      out.shared += calcNumClasses(size,10,1,"xs4");
    }
    let blur = size;
    if (shadow.bm) {
      blur = shadow.bm;
      out.shared += " shadow-blur-multiply ";
    } else if (shadow.ba) {
      blur = shadow.ba;
      out.shared += " shadow-blur-add ";
    }
    if (Array.isArray(blur)) { // xs
      out.blur += calcNumClasses(blur[0],10,1,"xs5");
      out.error += calcNumClasses(blur?.[2]??blur[0],10,1,"xs5");
      out.focus += calcNumClasses(blur[1],10,1,"xs5");
    } else {
      out.shared += calcNumClasses(blur,10,1,"xs5");
    }
    if (Array.isArray(shadow.c)) { // xs
      out.blur +=  colToCss("rgb1",shadow.c[0]);
      out.focus +=  colToCss("rgb1",shadow.c?.[1] ?? "");
      out.error +=  colToCss("rgb1",shadow.c?.[2] ?? "");
    } else {
      out.shared +=  colToCss("rgb1",shadow.c);
    }
    out.shared += (" a1-"+(shadow.a ?? 0.5).toString().replace(".","")+" ");

    const hover = shadow.hover;
    if (hover) {
      if (hover.only) {
        out.blur += " a-shadow-hover-rgb ";
      } else {
        out.shared += " a-shadow-rgb ";
        if (hover.add) {
          out.blur += (" a-shadow-hover-inc " + calcNumClasses(hover.add,10,1,"xs6"));
          out.focus += " a-shadow-rgb ";
          out.error += " a-shadow-rgb ";
        }
      }
      if (hover.trans) {
        out.blur += calcNumClasses(Math.abs(hover.trans),10,2,"xs7") + (hover.trans > 0 ? " press-down " : " press-up ");
      }
    } else {
      out.shared += " a-shadow-rgb ";
    }
  }
}
