import {calcNumClasses,isObj,Cal,colToCss} from 'modules';
import {calcBkg} from './ColorHelpers/bkg'; import {calcGradientBkg} from './ColorHelpers/gradients';import {calcIconColor} from './ColorHelpers/iconColors'; import {calcTextColor} from './ColorHelpers/textColor';
import {calcShadow} from './ColorHelpers/shadow';

export function calcColors(props) {
  // Vars: xs2,xs3,xs4,xs5,xs6,xs9, xs10,x11,a1,   sm,sm1,sm2,sm3,sign,sign1,sign3
  // hsl,hsl1,hsl2,rgb1
    let out = {
      b:"",shared:"",a:"",c:"",icon:{},
      style:{a:{},b:{},c:{},shared:{}}
    };
    const {colors,color} = props;
    if (isObj(colors)) {
      out.shared += Cal.a(props.a ?? props.opacity ?? 1); // classes a and a-h no vars
      const hasTwoColors = {b:Array.isArray(colors.b.bkg),a:Array.isArray(colors?.a?.bkg),c:Array.isArray(colors?.c.bkg)};
      const isGradient = {
        b: ((hasTwoColors.b || colors.b?.var)?true:false),
        a:  ((hasTwoColors.a || colors.a?.var)?true:false),
        c: (hasTwoColors.c || colors.c?.var || colors.c?.stop1 || colors.c?.stop2 || colors.c?.direction)?true:false,
      };
      calcTextColor(colors,out);   // Vars  hex,hex1,hex2,hex3,hsl2
      //out += calcShadow(props.shadow,isGradient ? "hsl2" : "rgb");// Vars: xs4,xs5,xs6,a1,hsl2,rgb1  -> we use hsl2 for gradient bkg because it'0s the only var not used, insteadc we use rgb1 for the other casesx
      calcIconColor(colors,isGradient,out);
      calcGradientBkg(colors,isGradient,hasTwoColors,out);
       // Vars: hsl, hsl1, sm,sign
      calcBkg(colors,isGradient,out);
    } else if (props.btnColors) {
      out = {...props.btnColors};
    } else if (color) {

    }
    const arrowColors = calcArrowColors(colors?.arrow);
    if (arrowColors) out.arrow = arrowColors;
    return out;
}



function calcArrowColors(colors) {
  if (!colors) {
    return "";
  } else {
    return colToCss("hex",colors?.[0]??colors) + colToCss("hex1",colors?.[1]??colors) + colToCss("hex2",colors?.[2]??colors?.[1]??colors) + " arrow-custom-colors ";
  }
}
/*    ****About computing the colors classes in advanced and use for several Buttons****
It may be difficult because in that case we would need much more cars to store the colors in any state
*/

// color = {b,a,c,h: colorObj} || colorObj -> to define blur,active,clicked,hover use the object {b,a,c,h: colorObj}, if only blur color is defined colorObj sufices
// colorObj = {bkg,txt}
