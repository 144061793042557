import {isObj,colToCss,calcNumClasses} from '../../../modules';

const calcGradientClass = (color,hasTwoColors,blurVar) =>
      " a-btn-grad-" + (color.radial ? "rad" :"lin") + (blurVar && !hasTwoColors ? ("-"+blurVar[0]+"h ") :" ");

function calcComponent(color,blurVar) {
  // Vars: sm, sign,xs9, sign1,
  // for saturation and luminosity we allow as variation 0-20, for r,g,b,h 0-160 su fort he latter we use a divident 8 in order to use a var with maximum value of 20
  let out = " ";
  const hoverVar = Array.isArray(color.var) && Array.isArray(color.var[0]) ? color.var[1] : null ;  // If it's not an array it means there is no hover value
  const varDividend = (["l","s"].includes(blurVar[0])) ? [1.5,2] : [8,10];
  out += calcNumClasses(Math.abs(blurVar[1] ?? 0),20,varDividend[0],"sm") + (blurVar[1] < 0 ? " minus-sign " : " plus-sign ");
  if ((hoverVar || hoverVar === 0) && !isNaN(hoverVar)) { // hoverVar is not an array, it contains only tchange what you set the number set in the second element of the blurVar, you cannot change the types
    out += calcNumClasses(Math.abs(hoverVar),10,varDividend[1],"xs9") + (hoverVar < 0 ? " minus1-sign " : " plus1-sign ");
    out += ["l","s"].includes(blurVar[0]) ? " a-ls-gradient " : " a-rgbh-gradient ";
  }
  // If var has a third element and value === true it means the gradient has 3 colors intead of  two so we had a proper css class
  if (blurVar.length === 3 && !isNaN(blurVar[2])) out += ` grad-${blurVar[2]}-col `;
  return out;
}

function calcColorStops(color) {
  // Vars: sm2,sm3,xs2,xs3
  let out = "";
  const stop1 = Array.isArray(color.stop1) ? color.stop1[0]: color.stop1;
  const stop2 = Array.isArray(color.stop2) ? color.stop2[0]: color.stop2;
  const stop1Hov = Array.isArray(color.stop1) ? color.stop1[1]: false;
  const stop2Hov = Array.isArray(color.stop2) ? color.stop2[1]: false;
  out += calcNumClasses(stop1 ?? 0,20,5,"sm2") + calcNumClasses(stop2 ?? 100,20,5,"sm3");
  if (stop1Hov && !isNaN(stop1Hov)) out += calcNumClasses(stop1Hov ?? 0,10,10,"xs2") + " a-btn-stop1-hover ";
  if (stop2Hov && !isNaN(stop2Hov)) out += calcNumClasses(stop2Hov ?? 0,10,10,"xs3") + " a-btn-stop2-hover ";
  return out;
}

function calcGradientDirection(color) {
  // Vars: xs10,11
  let out = "";
  const direction = Array.isArray(color.direction) ? color.direction[0] : color.direction;
  const directionHov = Array.isArray(color.direction) ? color.direction[1] : null;
  if (!color.radial) {  // Calculate the direction if the gradient is linear
    out += calcNumClasses(direction ?? 0,8,45,"xs10");
    if (directionHov) out += calcNumClasses(directionHov,8,45,"xs11") + " grad-hover-direction ";
  }
  return out;
}

export {calcGradientDirection, calcComponent, calcColorStops, calcGradientClass};
