import {calcBtnCssSize} from './BtnContainerHelpers/calcBtnCssSize'; import {space} from 'modules';
import {calcColors} from './colors';

export function calcBtnContainer(props,sizeGeneratedClass) {
  let out = {}
  calcBtnCssSize(props.size,sizeGeneratedClass,out);
  if (out.button || out.styleSheet || props.styleSheet) out.button += space(sizeGeneratedClass);
  out.button += ` a-btn noselect flex-cen-cen  ${calcBehaviour(props)} ${props.className ?? ""} `;
  out.button += props.position  ? "" : " p-rel ";
  return out;
}

function calcBehaviour(props) {
  let out = "";

  out += (props.forceHover ? " force-hover " : "");                          // simulate hover color via prop to test the color on not hoverable devices
  out += (props.noBlurBkg ? " no-blur-bkg ": "");                        // if topbar has the same background has the button you can disable the button bkg via props in case alpha is set otherwaise the y will sum up
  if (props.icon && !props.text && !props.size?.width)  out += " icon-only ";  // If it's an icon only button and with is not set, set the width equal to the height and the pdC to zero since the icon is centered and the width will account for the container padding
  if (props.hasDropMenu) out += " a-btn-drop ";
  if (props.tooltip) out += " tooltip-parent ";  // when there is a tooltip

  return out;
}
