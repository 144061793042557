import {useMemo,useState} from 'react';
import {Btn,Style,FlexCols,D,BtnDodger} from 'modules';
import {colors} from './btnColor';

function BtnList({buttons,index,handleList,addBtn,deleteButtons}) {
  const [editList,setEditLIst] = useState(null);
  if (!buttons) return null;
  const length = buttons.length;
  const toggleEdit = () => setEditLIst(editList ? null: []);
  const handleBtn = (i,id) => !editList ? handleList(i) : toggleBtn(id);
  const toggleBtn = (id) =>  {if (!id) return; const index = editList.indexOf(id); (index) >= 0 ? editList.splice(index,1) : editList.push(id); setEditLIst([...editList])};
  const deleteSelected = ()=> {toggleEdit();deleteButtons(editList);}
  return (
    <D>
      <D a={{pd:10}} cl="flex-cen-end">
          <BtnDodger   noShow={editList} text="Add new" click={addBtn} size={{height:"2.2em",font:13,}} width="auto"/>
          <Btn text={editList ? "Cancel" : "Edit"} click={toggleEdit} size={{height:"2.2em",font:13,}} width="auto"/>
          <Btn noShow={!editList} text="Delete Selected" click={deleteSelected} size={{height:"2.2em",font:13,}} width="auto"/>
          <Btn noShow={editList} text="close" click={handleList} size={{height:"2.2em",font:13,}} width="auto"/>
      </D>
      <NoButtons length={length} />
      <FlexCols length={length} cols={8} width="100%" mrR={10}>
        {buttons.map((row,i) => <Row {...{row,index,i,handleBtn,editList}} key={row.id ?? row.tempId}/>)}
      </FlexCols>
    </D>
  );
}



const Row = ({row,index,i,handleBtn,editList}) => {
  const [sheet,cl] = useMemo(()=>colors(row),[row]);
  const isSelected = editList ? editList.includes(row.id) : false;
  const c =  cl + " a-flex-col ";
  return (
    <>
      <Style sheet={sheet}/>
      <Btn className={c} rightIcon={true} icon={isSelected?"check":null} text={( row.id ?? "New") + (row.edited ? " ?" : "")} size={{height:"2.5em",font:19,icon:"1em",brd:2,gap:"1em"}} click={()=>handleBtn(i,row.id)}/>
    </>
  );

}

const NoButtons = ({length}) => {
  if (length > 0) {
    return null;
  } else {
    return <D a={{fSize:20}}>No Buttons added yet!</D>;
  }
}

export default BtnList;
