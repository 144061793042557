import {colToCss,addPc} from 'modules';

export function calcMultDropHeaderColors(colors,out) {
  const header = colors?.header;
  if (header?.text) {
    out.multiDropContainer += " btn-head-custom-text ";
    if (Array.isArray(header.text)) {
      out.multiDropContainer += colToCss("hex",header.text[0]) + colToCss("hex1",header.text[1]) + " btn-head-expanded-txt "
      if (header.text?.[2]) out.multiDropContainer += colToCss("rgb1",header.text[2]) + " btn-head-hover-txt ";
    } else {
        out.multiDropContainer += colToCss("hex",header.text)
    }
  }
  if (header?.icon) {
    out.multiDropContainer += " btn-head-custom-icon ";
    if (Array.isArray(header.icon)) {
      out.multiDropContainer += colToCss("hex2",header.icon[0]) + colToCss("hex3",header.icon[1]) + " btn-head-expanded-icon ";
    } else {
        out.multiDropContainer += colToCss("hex2",header.icon);
    }
  }
  if (colors.b?.expBkg) {
    out.multiDropContainer += colToCss("hsl", colors.b.expBkg) + " exp-bkg ";
  } else if (colors.b?.lExp) {
    out.colorsStyle["--lBkgExp"] = addPc(colors.b.lExp);
    out.multiDropContainer += " exp-bkg lBkg-exp ";
  }
}
