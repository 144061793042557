import React, {useRef, useEffect, useState,forwardRef} from 'react';
import {calcCssClass, calcCssClasses,useMediaQuery,isObj,def, defObj,FlexCols,Calc,cssInts} from  '../../../modules';
import {colors,calcDropSize, calcDropContainer,calcRowVars,SelectOption, SelectIcon} from '../';


export const DropDown = forwardRef((props,ref) => {
      const {media, tipPos,tipSize} = props;
      const [containers, setContainers] = useState({dropContainer:"",rowVars:"",maxHeight:""});
      const [xAdjustment, setXadjustent] = useState("");

      useEffect(()=>{
        const out = {};
        out.dropContainer = calcDropContainer(props);
        out.rowVars = calcRowVars(props);
        out.maxHeight = Calc.height({maxHeight: dropSize?.maxHeight ?? "60vh"}); // var C
        setContainers(out);
      },[]);

      useEffect(()=> setXadjustent(!props?.xAdj ? "" : cssInts(Math.abs(props.xAdj),"e") + (props.xAdj > 0 ? " plus1-sign " : " minus1-sign " ) + " drop-overflow "),[props.xAdj]);

      const myRef = useRef(null);
      const touchMove = (e) =>  {
          const delta = 80;
          const rect = myRef.current.getBoundingClientRect();
          const x = e.changedTouches[0].clientX;
          const y = e.changedTouches[0].clientY;
          if ((x > (rect.right + delta)) || (x < (rect.left - delta)) || (y > (rect.bottom + delta)) ||  (y < (rect.top - delta))) {
            props.close();
          }
      };

      const {dropSize} = props;
      const flexColsProps = {cols:dropSize?.cols ?? 1,length:props.options.length, pd:dropSize?.pd, intra:dropSize?.row?.intra ?? {mr:0}};
      const rowProps = {noBorder:dropSize?.row?.noBorder,random:props.random,value:props.value,checkColor:props.dropColors?.active?.check,handleClick:props.handleOptionsClick,defaultIcon:props.defaultIcon};
      // XAdj is the x traslation added by useeffect when the drop down is overflowing the

      if (!props.open) {
        return null;
      } else {
        return (
            <div className={"div-select-options "+xAdjustment+containers.dropContainer+props.isOpen} onTouchMove={touchMove} ref={myRef}>
              <DropDownTip {...props} />
              <div className={containers.rowVars} ref={ref}>
                  <FlexCols className={" no-scr-bar overflow-y-auto a-wrap no-over-scroll "+containers.maxHeight} {...flexColsProps} >
                      {props.options.map(option => <SelectOption  option={option} key={option[1]} {...rowProps} />)}
                  </FlexCols>
              </div>
          </div>
          );
      }


}
);


//<FlexCols intra={mr,mrB,mrR} pd cols length  className />

const DropDownTip = (props) => <div className="div-select-drop-tip" style={{left:props.tipPos}} onClick={props.close}></div>;
