import {D,SvgIcon} from 'modules';

const Header = ({max,type,dispatchColor,noValue,lRef,header}) => {
  const title = header ?? type[0].toUpperCase()+type.slice(1);
  return (
    <D a={{pd:{T:2},he:30,fSize:14}} cl="flex-start-cen">
      <div className="wd-10">{lRef ? 0-lRef: 0}</div>
      <div className="wd-90 flex-cen-cen">
        <SvgIcon icon="remove" noShow={noValue} style={{marginRight:24}} size={18} colors={{blur:"red",hover:"dodgerblue"}} click={(e)=>dispatchColor({component:type,decrease:true})}/>
        <D>{title}</D>
        <SvgIcon icon="add" noShow={noValue} size={12} style={{marginLeft:24}} colors={{blur:"red",hover:"dodger blue"}} click={(e)=>dispatchColor({component:type,increase:true})}/>
      </div>
      <div  className="wd-10">{lRef ? max-lRef: max}</div>
    </D>
  );
};

export default Header;


/*

<span className="pointer" onClick={(e)=>dispatchColor({component:type,decrease:true})}> --- </span>
<span className="pointer" onClick={(e)=>dispatchColor({component:type,increase:true})}> +++ </span>

*/
