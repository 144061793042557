import {isObj,colToCss,addPc} from 'modules';


export function calcBkg(colors,isGradient,out) {
  const bkgCalc = (color) => (color.bkg === "filled white") ? " a-btn-filled-white " : (color.bkg ? colToCss("hsl",color.bkg) : " a-bkg-transparent ");
  if (!isGradient.b) {
    out.b += bkgCalc(colors.b);
    if (colors.h.bkg) {
      out.b += colToCss("hsl1",colors.h.bkg) + " a-btn-bkg-hover ";
    } else if (colors.h.lBkg) { // sm sign
      out.style.b["--lBkg"] = addPc(colors.h.lBkg);
      out.b += " a-btn-lBkg ";
    }
  }
  if (!isGradient.a && colors.a) out.a += bkgCalc(colors.a);
  if (!isGradient.c && (colors.c.bkg || colors.c.lClick)) {
    out.c += bkgCalc({bkg:colors.c.bkg ?? colors.b.bkg});
    if (colors.c?.lClick) {
      out.c += " a-btn-lClick ";
      out.style.c["--lClick"] = addPc(colors.c.lClick);
    }
  }
}
