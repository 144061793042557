import {Button,Div,calcButtonSize,calcDropSize} from '../../modules';

export const size = {font:{d:14,mp:["5.5vw",18,23]},pdC:"1.2em", height:{d:"3em",mp:"2em"},weight:400,brdRad:30, icon:{d:"1.4em",mp:"1em"},gap:"0.4em",mrL:0};
export const dropSize = {arrow:{size:"1em",mrL:"0.3em"},tip:20,yTrans:{start:100},
    row:{fSize:15,pdC:14,height:"3em",icon:"1.1em",gap:"0.5em",brd:1,weight:{d:400},brdRad:0,lh:"14px"},
    multiDrop:{pd:16,hrWidth:2,expander:{pd:0,tip:20,rad:10},header:{font:"1.1em",pdC:10,icon:"1.2em",gap:"0.5em",height:"2.2em",weight:200,expander:{mrB:0,arrow:{size:"1em",mrL:"1em"}},lh:1.2}},
  };

  export const sizes = {size,dropSize,mrL:0};


//  row: {fSize,lh,weight,pdC,pdS,height,iconEm,expArrow,brd:intVal,brdRad:intVal (% of the height 0-50)},  // to enable button icons you need to set a default icons in case is missing in some buttons. without default icon icon prop on buttons will be ignored
//  multiDrop:{pd,mrR,mrB,mr,hrWidth,expPd,expTip,header:{ em,pdC,weight,heightEm,iconEm,expMrB:intVal}} // Ignore if the dro
