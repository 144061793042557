import {useReducer,useState,useEffect,useMemo,useContext} from 'react'; import {adaptColor} from './adaptColor'; import Router from './Router/Router';
import {Pickers,Color,D,Shared} from 'modules'; import Menues from './Menues'; import updatePickersList from './updateList';import {settingsReducer,settingsInizializer} from './settings';

const Pick = (props) => (!props.button) ? null : <PickInner {...props} />;

function PickInner({button,dispatchColor,bkg,index}) {
  const {media} = useContext(Shared);
  const [settings,disptatchSettings] = useReducer(settingsReducer,{});
  const [showSettings,setSettingsMenu] = useState(false);
  /* Every time we swith to a new button we re initialzie the settings according to the new button colors configuration*/
  useEffect(()=>{
    disptatchSettings({init:settingsInizializer(button)});
    setActiveGroup("Bkg");setActivePicker("Blur"); // Every time the index changes we need to reset the active pickers to "Bkg" "Blur" because it makes no sense to keep the old state but it's better to start from bkg
  },[index]);
  // editSettings is an handler called by the Menu component, it calls disptatchSettings to change the settings and adaptColor function to update the button colors to comply with the new settings
  const editSettings = (i) => {disptatchSettings(i);adaptColor(i,dispatchColor,button);};
  const [activeGroup,setActiveGroup] = useState("Bkg");
  const [activePicker,setActivePicker] = useState("Blur"); // setActivePicker called only by Menues
  const pickersList = ["Blur","Hover","Click","Active"]; // setPickersList is called by updatePickersList in useEffect
  // At every settings change we have to recompute the pickersList
  //useEffect(()=>updatePickersList(settings,activePicker,setActivePicker,setPickersList,activeGroup),[settings,activeGroup]);


  const s = {setActivePicker,activePicker,pickersList,editSettings,settings};
  let a = {he:800, colors:{bkg:"azure"}};
  if (!media.isMobile) a["pos"] = ["in","c2",[0,70],"fixed"];
  a.wd = media.isMobile ? "100" : 400;
  return (
    <D a={a} >
      <Menues {...{setActivePicker,activePicker,pickersList,editSettings,settings,showSettings,setSettingsMenu,activeGroup,setActiveGroup}}/>
      <Router  {...{activePicker,activeGroup,dispatchColor,button,settings,showSettings}}/>
    </D>
  );
}

export default Pick;



/*
const colors = {
  bkg:{b:[h,s,l],lH,bkgH,lC,a:[ha,sa,la]}.
  text:{cl,clH,lT,lTHov,lTCl,clC,clA},
  icon:{icCl,icClH},
  arrow:{arCl,arClH},
  brd:{btdCl,brdClH},
};

*/
