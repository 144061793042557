import React, {useState, useEffect,useRef,useMemo} from 'react'; import {Calc,calcNumClasses,colToCss,Link,fSizeRow,mediaVal} from '../modules';
import svg from './svg';import './svg.css';import {isObj,isString} from '../helpers';
import {calcColorClass} from './calc';
// <div className=""></div>

// colors:{blur,hover,clicked} or colorClass
const SvgIcon = (props) => (props.noShow || !props.icon) ? null : <SvgIconInner {...props}/>

function SvgIconInner(props) {
    const [clicked,setClick] = useState(false);
    const {size,sizeClass,icon} = props;
    const [iconContainer,colorsClass,style] = useMemo(init,[props]);
    const clickTimer = useRef(null);

    function init() {
        let iconContainer = (["drop_up_tip","drop_down_tip"].includes(icon) ? "drop-tip-icon ":"") + "an-icon flex-cen-cen noselect "+(props.className??" ");
        const style = {...props?.style};
        if (sizeClass) {
          if (sizeClass !== true) iconContainer += ` ${sizeClass} `; // When size class is true it means is added to an ancestor, we define there --iconSize var, so we don't need to add the class in the Svg component
        } else if (size) {
          style["--iconSize"] = fSizeRow(mediaVal(size));
        } else {
          iconContainer += " an-icon-100 ";
        }
        return [iconContainer,calcColorClass(props),style];
    };

    const handleClick = () => {
      if (props.colors?.clicked) {
        if (clickTimer.current) clearTimeout(clickTimer.current);
        setClick(true);
        clickTimer.current = setTimeout(()=>setClick(false),400);
      }
      const handler = props.click ?? props.handleClick;
      if (handler) handler();

    }

    // colorClass is a class completely defined elsewere including css, colorsClass instead is calculated with calcColorClass internally or externally, in this case is passed a colorsClass. In this class colors is plural and the css is set internally.
    const colorsClassX = isObj(colorsClass) ? (clicked ? colorsClass.clicked : colorsClass.blur) : props.colorClass;
    return (
      <Link href={props.href}  click={handleClick} className={iconContainer} style={style} propagate={props.propagate??true}>
                      {svg(icon, props.color, colorsClassX)}
      </Link>
    );

}

export default SvgIcon;
