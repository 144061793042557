import {isObj,isNumber,Cal,isString,addStyles,addStylesProp,calcCss,addPx,addVw,calcFont} from 'modules';




export function calcBtnCssSize(size,cssClass,output) {
  if (!isObj(size)) return null;
  const {weight,lh,height,brd,brdRad,icon,gap,mrL,pdC,font} = size;
  let out = {cl:"", style:{d:"",xt:"",t:"",mp:"",ml:"",xm:""}};
  //Height
  if (height) {
    addStyles(height,"height",out);
  }
  //Brd Size
  if (brd) calcBrd(brd,out);
  if (pdC) addStyles(pdC,"pdC",out);
  if (gap !== null) addStyles(gap,"textMrl",out);
  if (icon) addStyles(icon,"iconSize",out);
  if (brdRad) { // if the value is a multiple of 5 and there is not emdia object we use the classes values, for other values we create a style
    if (!isObj(brdRad) && brdRad <=50 && isNumber(brdRad) && ((brdRad % 5) === 0)) {
      out.cl += ` btn-rad-${brdRad} `;
    } else {
      addStyles(brdRad,"btnRad",out);
    }
  }
  if (font) calcFont(font,out);


  if (weight) {
    if (!isObj(weight)) {
      out.cl += Cal.we(weight);
    } else {
      addStylesProp(weight,"font-weight",out)
    }
  }
  if (lh) {
    if (!isObj(lh)) {
      out.cl += Cal.lh(lh);
    } else {
      addStylesProp(lh,"line-height",out)
    }
  }
  output.addedClasses += out.cl;
  output.addedSheet += calcCss(out.style,cssClass+".a-btn");
}


function calcBrd(brd,out) {
  let brdB,brdH;
  if (!Array.isArray(brd)) {
    brdB = brd;
  } else {
    brdB = brd[0];
    brdH = brd[1];
  }
  // For borders up to 6 and no media value we use classes, otherwise we create a style
  if ((!isObj(brdB)) && (brdB < 6)) {
    out.cl += ` btn-brd-${brdB} `;
  } else {
    addStyles(brdB,"btnBrd",out);
  }
  if (brdH && (!isObj(brdH)) && (brdH < 6)) {
    out.cl += ` btn-brdH-${brdH} `;
  } else {
    addStyles(brdH,"btnBrdH",out);
  }
}
