import {isObj,isNumber,isString,addStyles,style, calcCss,addPx,addVw,calcFont} from 'modules';

// addStyles(val,stylePref,out,className,notAVar,noPx)
// addStylesProp = (val,pref,out,noPx)
// calcCss(style,className)
/*
const colors = {
  bkg:{b:[h,s,l],lH,bkgH,lC,a:[ha,sa,la]}.
  text:{cl,clH,clC,lT,lTHov,lTCl,clA},  if you set cl instead of lT you have to use also clH,clC, you cannot use lTHov,lTCl. Only active color is independent
  icon:{icCl,icClH}, // icon and arrow on click follow text click color
  arrow:{arCl,arClH}, // icon and arrow on click follow text click color
  brd:{btdCl,brdClH},
};
*/
const stylePc = (val,stylePref,out) => style(val,stylePref,out,null,false,"%");


export function colors({bkg,text,icon,arrow,brd},inputClass) {

  let out = {cl:"",style:{d:""}};
  if (bkg) calcBkg(bkg,out);
  if (text) calcText(text,out);
  if (icon) calcIcon(icon,out);
  if (arrow) calcArrow(arrow,out);
  if (brd) calcBrd(brd,out);
  const cssClass =  isString(inputClass) && inputClass !== "" ? inputClass : ("b"+Math.round(Math.random() * 100000));
  return [calcCss(out.style,cssClass+".btn-color"),cssClass+" "+out.cl];
}

function calcBkg({b,lH,bkgH,lC,a},out) {
  if (b) {
    style(b[0],"h",out);stylePc(b[1],"s",out);stylePc(b[2],"l",out);
    if (lH) {
      stylePc(lH,"lH",out);
    } else if (bkgH) {
      addStyles(bkgH,"bkgH",out,"btn-bkg-hov");
    }
    if (lC) stylePc(lC,"lC",out);
  }
  if (a) {
    style(a,"bkgA",out);
  }
}

function calcText({cl,clH,clC,lT,lTHov,lTCl,clA},out) {
  if (lT) {
    stylePc(lT,"lT",out);
    out.cl += " btn-pattern ";
    if (lTHov) stylePc(lTHov,"lTHov",out);
    if (lTCl) stylePc(lTCl,"lTCl",out);
  } else if (cl) {
    addStyles(cl,"cl",out);
    if (clH) addStyles(clH,"clH",out);
    if (clC) addStyles(clC,"clC",out);
  }
  if (clA) style(clA,"clA",out);
}



function calcIcon({icCl,icClH,lIc,lIcH,icClA},out) {
  if (lIc) {
    out.cl += " icon-pattern ";
    stylePc(lIc,"lIc",out);
    if (lIcH) stylePc(lIcH,"lIcH",out);
  } else if (icCl) {
    addStyles(icCl,"icCl",out);
    addStyles(icClH,"icClH",out); // We don't need to check if the value exists since addStyles return
  }
  style(icClA,"icClA",out); // active color
}

function calcArrow({arCl,arClH,lArr,lArrH,arClA},out) {
  if (lArr) {
    out.cl += " arrow-pattern ";
    stylePc(lArr,"lArr",out);
    if (lArrH) stylePc(lArrH,"lArrH",out);
  } else if (arCl) {
    addStyles(arCl,"arCl",out);
    addStyles(arClH,"arClH",out);
  }
    style(arClA,"arClA",out); // active color
}

function calcBrd({cl,clH,click,active},out) {
  addStyles(cl,"brdCl",out);
  addStyles(clH,"brdClH",out);
  addStyles(click,"brdClCl",out);
  addStyles(active,"brdClA",out);
}
