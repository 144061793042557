import React, {useEffect} from 'react'; import DatePicker from "react-datepicker"; import "react-datepicker/dist/react-datepicker.css";
import {validateError} from "../forms"; import {isObj} from "../../modules";

function DateInner(props) {
  const {error, setError, focus, setFocus, clicked, setClick} = props.state;
  const {validate,name, value, inputContainer} = props;
  const myRef = React.createRef();

  const handleBlur = (e) => {
      setClick(false); setFocus(false);
      if (props.onBlur) props.blur(e);
  }

  const handleFocus = (e) => {
     setFocus(true);
     if (props.onFocus) props.onFocus(e);
  }


  let other = {};
  if (props.selectsStart) other.selectsStart = true;
  if (props.selectsEnd) other.selectsEnd = true;
  if (props.startDate) other.startDate = props.startDate;
  if (props.endDate) other.endDate = props.endDate;
  // value = selected date if there is not start end
  // value = selectsStart if selectsStart == true;
  // value = selectsEnd  if selectsEnd == true;

  return (
    <DatePicker name={name} selected={value} onChange={props.handleChange}  onBlur={handleBlur} onFocus={handleFocus}
    className={"form-input-inner "+inputContainer}  {...other}/>
  );


}

export default DateInner;


/*
Additional props only for Formdate:
selectsStart = bol, selectsEnd = bol  to be used when there are two form date, one for start and one for end date, one should have selectsStart true and the other selectsEnd true
startDate = js date, endDate = js date should be propped to both the form date
value is the date when there is a single form date or startDate on one and endDate to the other

Example
<Formdate value={date} />  Single datepicker
<Formdate value={startDate} startDate={startDate} selectsStart /><Formdate value={endDate} endDate={endDate} selectsEnd /> When there is a start and end date
*/
