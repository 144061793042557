import {calcCssClass, isObj,def,defObj,Calc,cssInts,calcFloatClasses,calcNumClasses,colToCss} from  '../../../modules';


function calcDropSize(i={}) {
  let out = " ";
  out += Calc.height({maxHeight: i.maxHeight ?? {l:600,m:500,s:400}}); // var f
  return out;
}

function calcDropContainer(props) {
    const {width,font,row,brdRad,lh,cols,arrow,yRatio,gap} = props.dropSize ?? {};
    // Vars: b,c,d,e,f,g , sign,sign1
    // sm, sm1, sm2,sm3
    //xs,xs1,xs4,xs5,xs6,xs7,
    // a,a1, rgb1,hsl

    let out = (cols === 1 || !cols) ? " single-drop " : " multi-drop ";
    if (props.value) out += " value-selected ";
    if (arrow === false) out += " no-dropDown-arrow ";
    out += calcNumClasses(props.tipSize,20,3/2,"sm3");
    out += Calc.width(width ?? "110%"); // Var f
    out += Calc.fontSize(font ?? 13); // b,c,d
    out += Calc.lh(lh ?? 1); // sm1
    out += calcNumClasses(brdRad ?? 8,20,1,"sm2"); // sm2
    
    out += calcDropColors(props.dropColors);// xs,xs4,xs5,xs6,xs7,a1,sm
    out += tipColor(props,cols);
    if (yRatio) out += calcFloatClasses(yRatio,10,10,"xs1") + " custom-yRatio ";
    if (gap)  out += calcNumClasses(gap,10,1.5,"xs2") + " custom-gap ";
    return out;
}

const tipColor = (props,cols) => {
  // When cols = 1 if the first drop button is active and has an active background we set the tip color to the active background otherwise it would be poor to have the tip of a different color sicne the tip is an extensions of the first button. For multi droop it makes nbo sense because the firsy row contains several buttons
  if (cols === 1 && (props.value === props.options[0][1]) && props.dropColors?.active?.bkg && !props.dropSize?.maxHeight) {
    return " div-select-active-tip ";
  } else {
    return "";
  }
}

function calcRowVars(props) {
  // Vars: b,c,d,e,f
  let out = " w100 div-select-inner ";
  const row = props.dropSize?.row ?? {};
  const iconEm = row?.iconEm ?? 1.5;
  // Vars d,f
  out += Array.isArray(iconEm) ? calcFloatClasses(iconEm[0] ?? 1.1,100,10,"d") + calcFloatClasses(iconEm[1] ?? 0.5,100,10,"f") + " select-icon-mr-em " : calcFloatClasses(iconEm ?? 1.1,100,10,"d");
  if (row.checkEm) out += calcFloatClasses(row.checkEm,100,10,"c") + " select-check-em ";
  out += row.height ? Calc.varHeight(row.height) : " div-select-row-no-height ";  // var b -> will add a variable --height to represent the drop row if row.height is set otherwise --height will be inherithed from the button, so the drop row will have the same height as the button
  out += cssInts(row.pdC ?? 24,"e","l"); //e
  out += calcIconColors(props.dropColors);
  return out;
}

function calcDropColors(colors = {}) {
  // xs,xs4,xs5,xs6,xs7,a1,sm
  const {weight,shadow,a,b,h,active} = colors;
  let out = " ";
  out += Calc.weight(weight ?? 500)  //  xs
  out += colToCss("hsl",b?.bkg ?? "dodger blue");
  if (h?.lBkg) {
    out += lHoverCalc(h?.lBkg) +" div-select-lBkg "; // Vars sm,sign ->not duplicated in colors.h?.lTxt because it will be ignored when lBkg is defined
  } else if (h?.bkg) {
    out += colToCss("hsl1",h?.bkg) + " div-select-bkg-hov ";
  }
  if (!h?.lBkg && h?.lTxt) {

    out += colToCss("hsl2",b?.text ?? "white") + lHoverCalc(Array.isArray(h?.lTxt) ? h?.lTxt[0] : h?.lTxt) + " div-select-lTxt "; // Vars sm,sign -> not duplicated because colled conditionally
  } else  {
    out += colToCss("hex",b?.text ?? "white");
    if (h?.text) out += colToCss("hex1",h?.text) + " div-select-txt-hov ";
  }
  if (active?.text) out += colToCss("hex2",active?.text) + " div-select-active-text ";
  if (active?.bkg) out += colToCss("hex3",active?.bkg) + " div-select-active-bkg ";
  out += calcBoxShadow(shadow,b?.bkg ?? "dodger blue"); // Vars: xs4,xs5,xs6,xs7,a1, rgb1
  out += Calc.opacity(a ?? 1); // a
  return out;
}

export function calcIconColors(colors = {}) {
  let out = "";
  const {b,h,active} = colors;
  if (!h?.icon && h?.lTxt && b?.icon) {
    out += colToCss("hsl2",b?.icon) + " drop-l-icon ";
    if (Array.isArray(h?.lTxt)) out += lHoverCalc(h.lTxt[1]) + " custom-lTxt ";
  } else if (b?.icon) {
    out += colToCss("hex2",b?.icon) + " drop-b-icon ";
  }
  if (h?.icon) out += colToCss("hex3",h.icon ) + " drop-h-icon ";
  if (active?.icon) out += colToCss("hex",active.icon ) + " div-select-active-icon ";
  return out;
}

const lHoverCalc = (lHover) =>  {
  // Vars sm,sign
    return  (calcNumClasses(Math.abs(lHover ?? 20),20,1,"sm") + (lHover < 0 ? " minus-sign " : " plus-sign "));
}

function calcBoxShadow(shadow,bkg) {
  let out = "";
  if (shadow !== false) {
    let shadowObj;
    if (!shadow) {
      shadowObj = {s:3,c:bkg ?? "black",a:0.6,bm:4};
    } else {
      shadowObj = shadow;
      if (!shadowObj.c) shadowObj.c = bkg ?? "black";
    }
    out += Calc.boxShadow(shadowObj); //  Vars: xs4,xs5,xs6,xs7,a1
  }
  return out;
}





export {calcDropSize, calcDropContainer,calcRowVars};
